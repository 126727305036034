<template>
	<v-listbox class="border-none" :options="predictions" @input="onSelect">
		<template #empty>
			<p v-if="!input" class="p-15">Start typing to search for an address.</p>
			<p v-else-if="!hasPredictions" class="p-15">No addresses found.</p>
		</template>
	</v-listbox>
</template>

<script lang="ts" setup>
	import { Loader } from '@googlemaps/js-api-loader'
	import type { SelectOption } from '../select/select.vue'

	const props = defineProps<{
		input?: string
		countries?: string | string[]
	}>()

	const emit = defineEmits<{
		autocomplete: [google.maps.places.PlaceResult | null]
		select: [string]
	}>()

	const runtimeConfig = useRuntimeConfig()

	const input = toRef(props, 'input')
	const predictions = ref<SelectOption[]>([])
	const hasPredictions = computed(() => predictions.value.length > 0)

	let autocomplete: google.maps.places.AutocompleteService | null = null
	let places: google.maps.places.PlacesService | null = null

	watch(input, onInput, { immediate: true })

	onMounted(async () => {
		const loader = new Loader({
			apiKey: runtimeConfig.public.googleMapsClientKey,
			version: 'weekly',
		})

		const { AutocompleteService, PlacesService } = await loader.importLibrary('places') // prettier-ignore

		autocomplete = new AutocompleteService()
		places = new PlacesService(document.createElement('div'))
	})

	function onInput(input?: string) {
		if (!autocomplete || !input) {
			predictions.value = []
			return
		}

		autocomplete?.getPlacePredictions(
			{
				input,
				types: ['address'],
				componentRestrictions: {
					country: props.countries ?? null,
				},
			},
			(items) => {
				if (!items?.length) {
					predictions.value = []
					return
				}

				predictions.value = items.map((item) => ({
					label: item.description,
					value: item.place_id,
				}))
			},
		)
	}

	function onSelect(placeId: string) {
		emit('select', placeId)

		places?.getDetails(
			{
				placeId,
				fields: ['address_components', 'formatted_address'],
			},
			(place) => emit('autocomplete', place),
		)
	}

	defineExpose({
		predictions,
		hasPredictions,
	})
</script>

<style lang="scss"></style>
