import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_zbacBAXhj0 from "/opt/atlassian/pipelines/agent/build/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/atlassian/pipelines/agent/build/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/opt/atlassian/pipelines/agent/build/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/opt/atlassian/pipelines/agent/build/.nuxt/unocss.mjs";
import plugin_ksq7gplbDw from "/opt/atlassian/pipelines/agent/build/node_modules/@twicpics/components/nuxt3/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_WLsn00x1qh from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import directives_UCglB6puIs from "/opt/atlassian/pipelines/agent/build/app/plugins/directives.ts";
import floating_ui_4teGzcYl04 from "/opt/atlassian/pipelines/agent/build/app/plugins/floating-ui.ts";
import gsap_PMvMtdZWXk from "/opt/atlassian/pipelines/agent/build/app/plugins/gsap.ts";
import mitt_pcu1PObIWo from "/opt/atlassian/pipelines/agent/build/app/plugins/mitt.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  unocss_MzCDxu9LMj,
  plugin_ksq7gplbDw,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_WLsn00x1qh,
  directives_UCglB6puIs,
  floating_ui_4teGzcYl04,
  gsap_PMvMtdZWXk,
  mitt_pcu1PObIWo
]