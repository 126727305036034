import { get, kebabCase, set } from 'lodash-es'
import type { ProvidedProps } from '../formie.vue'
import type { FormieField } from '~~/server/api/plugins/forms/[slug]'

export function useFieldAttrs(field: FormieField) {
	return {
		name: getName(field),
		attrs: getAttrs(field),
	}
}

export function useFieldModel(stateKey: string) {
	const providedProps = inject<ProvidedProps>('formie')

	if (!providedProps) {
		throw new Error('FormieAgree: Missing formie context.')
	}

	const { state } = providedProps

	return computed({
		get: () => get(state.value, stateKey),
		set: (value) => set(state.value, stateKey, value),
	})
}

export function useFieldValidation(stateKey: string) {
	const providedProps = inject<ProvidedProps>('formie')

	if (!providedProps) {
		throw new Error('FormieAgree: Missing formie context.')
	}

	const { v$ } = providedProps

	return computed({
		get: () => get(v$.value, stateKey),
		set: (value) => set(v$.value, stateKey, value),
	})
}

function getName(field: FormieField) {
	return computed(() => kebabCase(field.handle))
}

function getAttrs(field: FormieField) {
	return computed(() => {
		const attrs: Record<string, any> = {}

		if (field.inputAttributes) {
			Object.values(field.inputAttributes).forEach(({ label, value }) => {
				if (!label || !value) return
				attrs[label] = value
			})
		}

		return attrs
	})
}
