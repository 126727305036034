<template>
	<a v-html="label" :href="`tel:${value}`"></a>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		value?: string
	}>()

	const tel = computed(() =>
		(props.value ?? '').replace(/\s+/g, '-').replace(/\D-/g, ''),
	)

	const label = computed(() => tel.value.split('-').join('&nbsp;'))
</script>

<style lang="scss"></style>
