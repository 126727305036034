<template>
	<div v-if="component && isEnabled" v-show="isVisible" v-bind="containerAttrs">
		<component :is="component" :field="field" :state-key="stateKey" />
	</div>
</template>

<script lang="ts" setup>
	import type { FormieField } from '~~/server/api/plugins/forms/[slug]'
	import { FORMIE_INPUTS } from './inputs'

	const props = defineProps<{
		field: FormieField
		stateKey?: string
	}>()

	const component = computed(() => {
		const name = props.field.type
		return name && name in FORMIE_INPUTS ? FORMIE_INPUTS[name] : null
	})

	const containerAttrs = computed(() => {
		const attrs: Record<string, any> = {
			class: props.field.cssClasses,
		}

		if (props.field.containerAttributes) {
			Object.values(props.field.containerAttributes).forEach(
				({ label, value }) => {
					if (!label || !value) return
					attrs[label] = value
				},
			)
		}

		return attrs
	})

	const isEnabled = computed(() => {
		return props.field.visibility !== 'disabled'
	})

	const isVisible = computed(() => {
		return props.field.visibility !== 'hidden' && props.field.type !== 'Hidden'
	})

	const stateKey = computed(() => props.stateKey ?? props.field.handle)
</script>
