<template>
	<div
		ref="fieldEl"
		class="field"
		:class="{
			'field--dirty': vuelidate?.$dirty,
			'field--error': vuelidate?.$error,
			'field--valid': vuelidate?.$dirty && !vuelidate?.$error,
		}">
		<div class="field__content">
			<slot />
		</div>

		<template v-if="hasMessages">
			<ul class="field__messages">
				<template v-if="instructions">
					<li class="field__instructions">
						{{ instructions }}
					</li>
				</template>

				<template v-for="(error, i) in errors" :key="i">
					<li class="field__error">
						{{ error.$message }}
					</li>
				</template>
			</ul>
		</template>
	</div>
</template>

<script lang="ts" setup>
	import type { BaseValidation } from '@vuelidate/core'

	const props = defineProps<{
		instructions?: string
		vuelidate?: BaseValidation
	}>()

	const fieldEl = ref<HTMLElement>()

	const errors = computed(() => {
		return props.vuelidate?.$errors ?? []
	})

	const hasMessages = computed(() => {
		return !!(errors.value.length || props.instructions)
	})
</script>

<style lang="scss">
	@layer components {
		.field {
			display: grid;
			gap: 5px;

			&--error {
				--input-border-color: #{$danger};
				--input-box-shadow-color: #{rgba($danger, 0.1)};
				--input-message-color: #{$danger};
			}

			&--focused {
				--input-border-color: #{$brand-light};
				--input-box-shadow-color: #{rgba($brand-light, 0.1)};
				--input-message-color: #{$danger};
			}

			&--valid {
				--input-border-color: #{$success};
				--input-box-shadow-color: #{rgba($success, 0.1)};
				--input-message-color: #{$success};
			}

			&__content {
				position: relative;
			}

			&__error {
				color: var(--input-error-color, #{$danger});
			}

			&__messages {
				@include reset-list();
				padding-left: var(--input-pl, var(--input-px, 10px));
				padding-right: var(--input-pr, var(--input-px, 10px));
				font-size: $text-2xs;
			}
		}
	}
</style>
