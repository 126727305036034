<template>
	<input v-bind="attrs" :name="name" type="hidden" :value="value" />
</template>

<script lang="ts" setup>
	import type { HiddenTextField } from '~~/server/api/plugins/forms/[slug]'
	import { useFieldAttrs } from '../composables/helpers'

	const props = defineProps<{
		field: HiddenTextField
	}>()

	const { attrs, name } = useFieldAttrs(props.field)

	const value = computed(() => {
		const defaultValue = props.field.defaultValue

		switch (defaultValue) {
			case '%URL%':
				return import.meta.browser ? window.location.pathname : defaultValue

			default:
				return defaultValue
		}
	})
</script>
