import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'

function floatingBoundary(el?: Element) {
	return {
		fn({ elements }: any) {
			if (!el) return {}

			Object.assign(elements.floating.style, {
				'max-width': `${el.clientWidth}px`,
			})

			return {}
		},
		name: 'boundary',
	}
}

export default defineNuxtPlugin((nuxtApp) => {
	return {
		provide: {
			floatingAutoUpdate: autoUpdate,
			floatingBoundary,
			floatingFlip: flip,
			floatingOffset: offset,
			floatingShift: shift,
			useFloating,
		},
	}
})
