import { has } from 'lodash-es'

export function useRouterUtils() {
	const route = useRoute()
	const router = useRouter()
	const localePath = useLocalePath()

	/**
	 * @description Navigates to a new page using the localized path
	 * @param path the path to navigate to
	 * @param localize whether to localize the path or not
	 */
	function goTo(path: string, localize = true) {
		const url = localize ? localePath(path) : path
		navigateTo(url)
	}

	/**
	 * @description Navigates back to the previous page using the localized path
	 * @param path
	 * @param localize
	 */
	function goBack(path: string, localize = true) {
		const basePath = localize ? localePath(path) : path
		const prevPath = window?.history?.state?.back as string | undefined

		if (!prevPath || !prevPath.startsWith(basePath)) {
			goTo(basePath, localize)
		} else {
			router.back()
		}
	}

	/**
	 * @description Gets the URL query parameters
	 */
	function getUrlParams() {
		return route.query
	}

	/**
	 * @description Gets a specific URL query parameter
	 * @param param the query parameter to get
	 */
	function getUrlParam(param: string) {
		return route.query[param]
	}

	/**
	 * @description Sets the URL query parameters
	 * @param params the query parameters to set
	 * @param overwrite whether to overwrite the existing query parameters or not
	 */
	function setUrlParams(
		params: Record<string, string | number | null | undefined>,
		overwrite = false,
	) {
		const query = overwrite ? {} : { ...route.query }

		for (const [key, value] of Object.entries(params)) {
			if (value) {
				query[key] = value.toString()
				continue
			}

			if (key in query) {
				delete query[key]
			}
		}

		router.replace({ query })
	}

	/**
	 * @description Checks if the URL has a specific query parameter
	 * @param param the query parameter to check
	 */
	function hasUrlParam(param: string) {
		return has(route.query, param)
	}

	/**
	 * @description Replaces the current URL with a new one without triggering a page reload
	 *
	 * @param path the path to replace the current URL with
	 * @param localize whether to localize the path or not
	 */
	function replaceUrl(path: string, localize = true) {
		if (!import.meta.browser) return

		path = localize ? localePath(path) : path
		window.history.replaceState({}, document.title, path)
	}

	return {
		goBack,
		goTo,
		getUrlParam,
		getUrlParams,
		setUrlParams,
		hasUrlParam,
		replaceUrl,
	}
}
