<template>
	<p class="listbox__label">
		{{ value }}
	</p>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		value: string
	}>()

	const slots = useSlots()
</script>

<style lang="scss">
	@layer components {
		.listbox__label {
			@include var-pad('listbox-group', 10px, 5px);
			display: flex;
			align-items: center;
			min-height: 36px;
			color: $brand;
			font-weight: 700;
			user-select: none;
		}
	}
</style>
