<template>
	<div ref="el" class="address-wrapper">
		<div class="address">
			<v-input
				v-model="internalValue"
				ref="inputEl"
				:attributes="attributes"
				autocomplete="off"
				:disabled="disabled"
				:label="label"
				:label-hidden="labelHidden"
				:label-position="labelPosition ?? 'float'"
				:name="name"
				:placeholder="placeholder"
				:show-clear="showClear"
				@clear="onClear"
				@input="onInput" />

			<div class="address__overlay">
				<v-button
					v-if="showClear && internalValue"
					class="address__clear"
					color="primary"
					:disabled="disabled"
					fill="clear"
					icon="tepari:cross"
					shape="round"
					size="xs"
					type="button"
					@click="onClear" />

				<icon v-else class="address__icon" name="tepari:location" />
			</div>
		</div>

		<v-dropdown
			ref="dropdownEl"
			:boundary="el"
			:offset="{
				mainAxis: -1,
			}"
			strategy="absolute">
			<v-address-predictions
				ref="predictionsEl"
				:input="internalValue"
				@autocomplete="onAutoComplete"
				@select="onSelect" />
		</v-dropdown>
	</div>
</template>

<script lang="ts" setup>
	import type AddressPredictions from './address-predictions.vue'
	import type Dropdown from '@/components/common/dropdown/dropdown.vue'
	import type Input from '@/components/common/inputs/input/input.vue'

	const props = defineProps<{
		attributes?: Record<string, any>
		disabled?: boolean
		label: string
		labelHidden?: boolean
		labelPosition?: 'top' | 'right' | 'bottom' | 'left' | 'float'
		name?: string
		placeholder?: string
		readonly?: boolean
		required?: boolean
		showClear?: boolean
		modelValue?: string
	}>()

	const emit = defineEmits<{
		'autocomplete': [google.maps.places.PlaceResult | null]
		'clear': []
		'input': [Event]
		'update:modelValue': [string | undefined]
	}>()

	const el = ref<HTMLElement>()
	const inputEl = ref<InstanceType<typeof Input>>()
	const dropdownEl = ref<InstanceType<typeof Dropdown>>()
	const predictionsEl = ref<InstanceType<typeof AddressPredictions>>()

	const internalValue = ref(props.modelValue ?? '')
	const modelValue = toRef(props, 'modelValue')

	watch(modelValue, (value) => (internalValue.value = value ?? ''), {
		immediate: true,
	})

	function onInput(event: Event) {
		emit('input', event)
		emit('update:modelValue', internalValue.value)

		if (predictionsEl.value?.hasPredictions) {
			dropdownEl.value?.show(event)
		}
	}

	function onClear() {
		internalValue.value = ''

		emit('clear')
		emit('update:modelValue', internalValue.value)

		dropdownEl.value?.hide()
	}

	function onSelect(placeId: string) {
		dropdownEl.value?.hide()
	}

	function onAutoComplete(result: any) {
		internalValue.value = result.formatted_address

		emit('autocomplete', result)
		emit('update:modelValue', internalValue.value)
	}

	defineExpose({
		clear: onClear,
		value: internalValue,
	})
</script>

<style lang="scss">
	@layer components {
		.address-wrapper {
			position: relative;

			.dropdown {
				width: 100%;
			}
		}

		.address {
			.input {
				padding-right: 36px;
			}

			&__overlay {
				display: grid;
				position: absolute;
				top: 0;
				right: 0;
				place-items: center;
				min-width: 36px;
				height: 100%;
				pointer-events: none;

				> * {
					grid-row: 1;
					grid-column: 1;
				}
			}

			&__clear {
				pointer-events: auto;
			}

			&__icon {
				width: 16px;
				color: $brand;
			}
		}
	}
</style>
