<template>
	<v-field :instructions="field.instructions" :vuelidate="vuelidate">
		<v-radio-group
			v-model="model"
			:attributes="attrs"
			:label="field.label"
			:name="name"
			:options="options" />
	</v-field>
</template>

<script lang="ts" setup>
	import type { RadioOption } from '@/components/common/inputs/radio/radio-group.vue'
	import type { RadioField } from '~~/server/api/plugins/forms/[slug]'
	import {
		useFieldAttrs,
		useFieldModel,
		useFieldValidation,
	} from '../composables/helpers'

	const props = defineProps<{
		field: RadioField
		stateKey: string
	}>()

	const { attrs, name } = useFieldAttrs(props.field)

	const vuelidate = useFieldValidation(props.stateKey)

	const model = useFieldModel(props.stateKey)

	const options = computed(() => {
		if (!props.field.options) return []

		return props.field.options
			.filter((option) => !option.isOptgroup)
			.map<RadioOption<any>>((option) => ({
				label: option.label,
				value: option.value,
			}))
	})
</script>

<style lang="scss"></style>
