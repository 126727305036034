<template>
	<v-input-label
		class="radio-wrapper"
		:is-hidden="labelHidden"
		:position="labelPosition ?? 'right'"
		:value="label">
		<div class="radio">
			<input
				v-bind="attributes"
				:checked="checked"
				:name="name"
				type="radio"
				:value="value"
				@change="emit('change', $event, value)" />
			<div class="radio__indicator"></div>
		</div>
	</v-input-label>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		attributes?: Record<string, any>
		disabled?: boolean
		checked?: boolean
		label: string
		labelHidden?: boolean
		labelPosition?: 'top' | 'right' | 'bottom' | 'left'
		name: string
		value?: unknown
	}>()

	const emit = defineEmits<{
		change: [Event, unknown]
	}>()
</script>

<style lang="scss">
	@layer components {
		.radio-wrapper {
			--label-size: minmax(0, 1fr);
			--label-input-size: auto;
		}

		.radio {
			position: relative;

			input[type='radio'] {
				@include sr-only();

				&:focus + .radio__indicator {
					--input-border-color: #{$brand-light};
					--input-box-shadow-color: #{rgba($brand-light, 0.1)};
				}

				&:checked + .radio__indicator:after {
					transform: scale(1);
					opacity: 1;
				}
			}

			&__indicator {
				transition:
					border-color 200ms,
					box-shadow 200ms;
				cursor: pointer;
				border: var(--input-border-width, #{$input-border-width}) solid
					var(--input-border-color, #{$input-border-color});
				border-radius: 50%;
				border-radius: 50%;
				background-color: var(--input-bg-color, #{$white});
				width: 20px;
				height: 20px;

				&:after {
					display: block;
					position: absolute;
					top: 20%;
					left: 20%;
					transform: scale(0.5);
					opacity: 0;
					transition:
						opacity 200ms,
						transform 200ms;
					border-radius: 50%;
					background-color: $brand;
					width: 60%;
					height: 60%;
					content: '';
				}
			}
		}
	}
</style>
