<template>
	<div
		class="formie-page"
		:class="{
			'formie-page--before-active': pageIndex < activeIndex,
			'formie-page--active': pageIndex === activeIndex,
			'formie-page--after-active': pageIndex > activeIndex,
		}">
		<template v-for="row in rows" :key="row.id">
			<v-formie-hidden
				v-for="field in row.hidden"
				:key="field.id"
				:field="field" />
		</template>

		<div class="formie-page__content">
			<p v-if="showPageTitle" class="h3 text-center">
				{{ page.name }}
			</p>

			<template v-for="row in rows" :key="row.id">
				<v-formie-row v-if="row.fields.length" :fields="row.fields" />
			</template>
		</div>

		<v-formie-controls
			v-if="!hideControls"
			class="formie-page__controls"
			:is-first-page
			:is-last-page
			:loading="loading"
			:skewer="skewerControls"
			:submit-label="page.settings?.submitButtonLabel"
			@next="emit('next')"
			@prev="emit('prev')"
			@submit="emit('submit')" />
	</div>
</template>

<script lang="ts" setup>
	import type { FormiePage } from '~~/server/api/plugins/forms/[slug]'

	const props = defineProps<{
		activeIndex: number
		loading?: boolean
		page: FormiePage
		pageIndex: number
		skewerControls?: boolean
		hideControls?: boolean
		showPageTitle?: boolean
		totalPages: number
	}>()

	const emit = defineEmits<{
		next: []
		prev: []
		submit: []
	}>()

	const isFirstPage = computed(() => props.pageIndex === 0)

	const isLastPage = computed(() => props.pageIndex === props.totalPages - 1)

	const rows = computed(() => {
		return props.page.rows?.map(({ id, name, fields }) => {
			const value: any = {
				id,
				name,
				fields: [],
				hidden: [],
			}

			fields?.forEach((field) => {
				if (field.typeName === 'Field_Hidden') {
					value.hidden.push(field)
				} else {
					value.fields.push(field)
				}
			})

			return value
		})
	})
</script>

<style lang="scss">
	.formie-page {
		isolation: isolate;

		&__content {
			display: grid;
			gap: var(--form-gutter, 24px);
		}

		&__controls {
			margin-top: var(--form-gutter, 24px);
		}
	}
</style>
