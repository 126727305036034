import { default as indexuEr8wsYUuQMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/index.vue?macro=true";
import { default as livestock_45dosing_45guns0oRpXe73vMMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-dosing-guns.vue?macro=true";
import { default as livestock_45eid_45tag_45readers93bimjEtCBMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-eid-tag-readers.vue?macro=true";
import { default as livestock_45platforms_45and_45load_45barszESyzaLcGRMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-platforms-and-load-bars.vue?macro=true";
import { default as macrostock415CPqaCS1Meta } from "/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/macrostock.vue?macro=true";
import { default as cattle_45loading_45rampswxlIcYOZYFMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-loading-ramps.vue?macro=true";
import { default as cattle_45yard_45automationOoV0I5Gs0FMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-yard-automation.vue?macro=true";
import { default as permanent_45cattle_45yardsirS79M4WsRMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/permanent-cattle-yards.vue?macro=true";
import { default as semi_45permanent_45cattle_45yardsOsH8FaYxWoMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/semi-permanent-cattle-yards.vue?macro=true";
import { default as about6Heqof0wIvMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/about.vue?macro=true";
import { default as addresses8iDThtQnsyMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/account/addresses.vue?macro=true";
import { default as indexx2YHyPfJfYMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/account/index.vue?macro=true";
import { default as _91orderId_93Y1YqpiVT6eMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/account/orders/[orderId].vue?macro=true";
import { default as indexESm8IMes0AMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/account/orders/index.vue?macro=true";
import { default as _91_46_46_46slugs_93yf4lSbfus6Meta } from "/opt/atlassian/pipelines/agent/build/app/pages/blog/[...slugs].vue?macro=true";
import { default as indexvagmsGvFKCMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slugs_93K7kfaaksOaMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/careers/[...slugs].vue?macro=true";
import { default as index46VIi8WaiCMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/careers/index.vue?macro=true";
import { default as indexA3ULzK2LrUMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/index.vue?macro=true";
import { default as lenta_45cattle_45crusha8N95kR8d6Meta } from "/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-cattle-crush.vue?macro=true";
import { default as lenta_45hoof_45handlerfjvJxkvdc0Meta } from "/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-hoof-handler.vue?macro=true";
import { default as taurus_45cattle_45crushW9qPBK4oSEMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/taurus-cattle-crush.vue?macro=true";
import { default as titan_45cattle_45crushg8mB46qZpUMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/titan-cattle-crush.vue?macro=true";
import { default as vario_45cattle_45crushZV8pDMH1AhMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/vario-cattle-crush.vue?macro=true";
import { default as _91_46_46_46slugs_930vlZFdZ9uDMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/[...slugs].vue?macro=true";
import { default as index7Hb3pIQrJEMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/index.vue?macro=true";
import { default as contactJ1BjMkVGkxMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/contact.vue?macro=true";
import { default as _91_46_46_46slugs_93k1fRU6kdmsMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/documentation/[...slugs].vue?macro=true";
import { default as indexb4qKad8OMAMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/documentation/index.vue?macro=true";
import { default as eventsOgoM5Fy0cOMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/events.vue?macro=true";
import { default as integrated_45solutionsY96BofRbtrMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/integrated-solutions.vue?macro=true";
import { default as media17HrNNQGu0Meta } from "/opt/atlassian/pipelines/agent/build/app/pages/media.vue?macro=true";
import { default as _91_46_46_46slugs_938y6jZkR4njMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/pages/[...slugs].vue?macro=true";
import { default as _91_46_46_46slugs_93KqSQ5sZ9dgMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/promotions/[...slugs].vue?macro=true";
import { default as indexbJ42gFzCb3Meta } from "/opt/atlassian/pipelines/agent/build/app/pages/promotions/index.vue?macro=true";
import { default as searchf7KWdX30JAMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/search.vue?macro=true";
import { default as indexn4IQBpqK4BMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/index.vue?macro=true";
import { default as racewell_45jr_45sheep_45handler2YkmFklK1WMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-jr-sheep-handler.vue?macro=true";
import { default as racewell_45sheep_45drafteryQrJRZx3mNMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-drafter.vue?macro=true";
import { default as racewell_45sheep_45handlerirJPBfvgYfMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-handler.vue?macro=true";
import { default as vetmarker_45lamb_45chutef2fm12qf8XMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/vetmarker-lamb-chute.vue?macro=true";
import { default as complete2CQwDlcu8vMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/complete.vue?macro=true";
import { default as indexkLmhzEb4dmMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/index.vue?macro=true";
import { default as paymentyUwuI5Y1GiMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/payment.vue?macro=true";
import { default as shippingMfziToqzeHMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/shipping.vue?macro=true";
import { default as indexTlZourcGyHMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue?macro=true";
import { default as _91_46_46_46slugs_93kSr8OhjeNEMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/shop/products/[...slugs].vue?macro=true";
import { default as indexPnZ5RqgcXfMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/tools/index.vue?macro=true";
import { default as racewell_45return_45on_45investment_45calculatorVBGNV0Y5MEMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/tools/racewell-return-on-investment-calculator.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/index.vue")
  },
  {
    name: "livestock-dosing-guns___au",
    path: "/au/livestock-dosing-guns",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-dosing-guns.vue")
  },
  {
    name: "livestock-dosing-guns___nz",
    path: "/nz/livestock-dosing-guns",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-dosing-guns.vue")
  },
  {
    name: "livestock-dosing-guns___uk",
    path: "/uk/livestock-dosing-guns",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-dosing-guns.vue")
  },
  {
    name: "livestock-dosing-guns___us",
    path: "/us/livestock-dosing-guns",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-dosing-guns.vue")
  },
  {
    name: "livestock-eid-tag-readers___au",
    path: "/au/livestock-eid-tag-readers",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-eid-tag-readers.vue")
  },
  {
    name: "livestock-eid-tag-readers___nz",
    path: "/nz/livestock-eid-tag-readers",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-eid-tag-readers.vue")
  },
  {
    name: "livestock-eid-tag-readers___uk",
    path: "/uk/livestock-eid-tag-readers",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-eid-tag-readers.vue")
  },
  {
    name: "livestock-eid-tag-readers___us",
    path: "/us/livestock-eid-tag-readers",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-eid-tag-readers.vue")
  },
  {
    name: "livestock-platforms-and-load-bars___au",
    path: "/au/livestock-platforms-and-load-bars",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-platforms-and-load-bars.vue")
  },
  {
    name: "livestock-platforms-and-load-bars___nz",
    path: "/nz/livestock-platforms-and-load-bars",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-platforms-and-load-bars.vue")
  },
  {
    name: "livestock-platforms-and-load-bars___uk",
    path: "/uk/livestock-platforms-and-load-bars",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-platforms-and-load-bars.vue")
  },
  {
    name: "livestock-platforms-and-load-bars___us",
    path: "/us/livestock-platforms-and-load-bars",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/livestock-platforms-and-load-bars.vue")
  },
  {
    name: "macrostock___au",
    path: "/au/macrostock",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/macrostock.vue")
  },
  {
    name: "macrostock___nz",
    path: "/nz/macrostock",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/macrostock.vue")
  },
  {
    name: "macrostock___uk",
    path: "/uk/macrostock",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/macrostock.vue")
  },
  {
    name: "macrostock___us",
    path: "/us/macrostock",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(animal-management)/macrostock.vue")
  },
  {
    name: "cattle-loading-ramps___au",
    path: "/au/cattle-loading-ramps",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-loading-ramps.vue")
  },
  {
    name: "cattle-loading-ramps___nz",
    path: "/nz/cattle-loading-ramps",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-loading-ramps.vue")
  },
  {
    name: "cattle-loading-ramps___uk",
    path: "/uk/cattle-loading-ramps",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-loading-ramps.vue")
  },
  {
    name: "cattle-loading-ramps___us",
    path: "/us/cattle-loading-ramps",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-loading-ramps.vue")
  },
  {
    name: "cattle-yard-automation___au",
    path: "/au/cattle-yard-automation",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-yard-automation.vue")
  },
  {
    name: "cattle-yard-automation___nz",
    path: "/nz/cattle-yard-automation",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-yard-automation.vue")
  },
  {
    name: "cattle-yard-automation___uk",
    path: "/uk/cattle-yard-automation",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-yard-automation.vue")
  },
  {
    name: "cattle-yard-automation___us",
    path: "/us/cattle-yard-automation",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/cattle-yard-automation.vue")
  },
  {
    name: "permanent-cattle-yards___au",
    path: "/au/permanent-cattle-yards",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/permanent-cattle-yards.vue")
  },
  {
    name: "permanent-cattle-yards___nz",
    path: "/nz/permanent-cattle-yards",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/permanent-cattle-yards.vue")
  },
  {
    name: "permanent-cattle-yards___uk",
    path: "/uk/permanent-cattle-yards",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/permanent-cattle-yards.vue")
  },
  {
    name: "permanent-cattle-yards___us",
    path: "/us/permanent-cattle-yards",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/permanent-cattle-yards.vue")
  },
  {
    name: "semi-permanent-cattle-yards___au",
    path: "/au/semi-permanent-cattle-yards",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/semi-permanent-cattle-yards.vue")
  },
  {
    name: "semi-permanent-cattle-yards___nz",
    path: "/nz/semi-permanent-cattle-yards",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/semi-permanent-cattle-yards.vue")
  },
  {
    name: "semi-permanent-cattle-yards___uk",
    path: "/uk/semi-permanent-cattle-yards",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/semi-permanent-cattle-yards.vue")
  },
  {
    name: "semi-permanent-cattle-yards___us",
    path: "/us/semi-permanent-cattle-yards",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/(cattle-yards)/semi-permanent-cattle-yards.vue")
  },
  {
    name: "about___au",
    path: "/au/about",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/about.vue")
  },
  {
    name: "about___nz",
    path: "/nz/about",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/about.vue")
  },
  {
    name: "about___uk",
    path: "/uk/about",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/about.vue")
  },
  {
    name: "about___us",
    path: "/us/about",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/about.vue")
  },
  {
    name: "account-addresses___au",
    path: "/au/account/addresses",
    meta: addresses8iDThtQnsyMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/addresses.vue")
  },
  {
    name: "account-addresses___nz",
    path: "/nz/account/addresses",
    meta: addresses8iDThtQnsyMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/addresses.vue")
  },
  {
    name: "account-addresses___uk",
    path: "/uk/account/addresses",
    meta: addresses8iDThtQnsyMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/addresses.vue")
  },
  {
    name: "account-addresses___us",
    path: "/us/account/addresses",
    meta: addresses8iDThtQnsyMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/addresses.vue")
  },
  {
    name: "account___au",
    path: "/au/account",
    meta: indexx2YHyPfJfYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/index.vue")
  },
  {
    name: "account___nz",
    path: "/nz/account",
    meta: indexx2YHyPfJfYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/index.vue")
  },
  {
    name: "account___uk",
    path: "/uk/account",
    meta: indexx2YHyPfJfYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/index.vue")
  },
  {
    name: "account___us",
    path: "/us/account",
    meta: indexx2YHyPfJfYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/index.vue")
  },
  {
    name: "account-orders-orderId___au",
    path: "/au/account/orders/:orderId()",
    meta: _91orderId_93Y1YqpiVT6eMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/orders/[orderId].vue")
  },
  {
    name: "account-orders-orderId___nz",
    path: "/nz/account/orders/:orderId()",
    meta: _91orderId_93Y1YqpiVT6eMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/orders/[orderId].vue")
  },
  {
    name: "account-orders-orderId___uk",
    path: "/uk/account/orders/:orderId()",
    meta: _91orderId_93Y1YqpiVT6eMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/orders/[orderId].vue")
  },
  {
    name: "account-orders-orderId___us",
    path: "/us/account/orders/:orderId()",
    meta: _91orderId_93Y1YqpiVT6eMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/orders/[orderId].vue")
  },
  {
    name: "account-orders___au",
    path: "/au/account/orders",
    meta: indexESm8IMes0AMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___nz",
    path: "/nz/account/orders",
    meta: indexESm8IMes0AMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___uk",
    path: "/uk/account/orders",
    meta: indexESm8IMes0AMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___us",
    path: "/us/account/orders",
    meta: indexESm8IMes0AMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/account/orders/index.vue")
  },
  {
    name: "blog-slugs___au",
    path: "/au/blog/:slugs(.*)*",
    meta: _91_46_46_46slugs_93yf4lSbfus6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/blog/[...slugs].vue")
  },
  {
    name: "blog-slugs___nz",
    path: "/nz/blog/:slugs(.*)*",
    meta: _91_46_46_46slugs_93yf4lSbfus6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/blog/[...slugs].vue")
  },
  {
    name: "blog-slugs___uk",
    path: "/uk/blog/:slugs(.*)*",
    meta: _91_46_46_46slugs_93yf4lSbfus6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/blog/[...slugs].vue")
  },
  {
    name: "blog-slugs___us",
    path: "/us/blog/:slugs(.*)*",
    meta: _91_46_46_46slugs_93yf4lSbfus6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/blog/[...slugs].vue")
  },
  {
    name: "blog___au",
    path: "/au/blog",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/blog/index.vue")
  },
  {
    name: "blog___nz",
    path: "/nz/blog",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/blog/index.vue")
  },
  {
    name: "blog___uk",
    path: "/uk/blog",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/blog/index.vue")
  },
  {
    name: "blog___us",
    path: "/us/blog",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/blog/index.vue")
  },
  {
    name: "careers-slugs___au",
    path: "/au/careers/:slugs(.*)*",
    meta: _91_46_46_46slugs_93K7kfaaksOaMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/careers/[...slugs].vue")
  },
  {
    name: "careers-slugs___nz",
    path: "/nz/careers/:slugs(.*)*",
    meta: _91_46_46_46slugs_93K7kfaaksOaMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/careers/[...slugs].vue")
  },
  {
    name: "careers-slugs___uk",
    path: "/uk/careers/:slugs(.*)*",
    meta: _91_46_46_46slugs_93K7kfaaksOaMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/careers/[...slugs].vue")
  },
  {
    name: "careers-slugs___us",
    path: "/us/careers/:slugs(.*)*",
    meta: _91_46_46_46slugs_93K7kfaaksOaMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/careers/[...slugs].vue")
  },
  {
    name: "careers___au",
    path: "/au/careers",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/careers/index.vue")
  },
  {
    name: "careers___nz",
    path: "/nz/careers",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/careers/index.vue")
  },
  {
    name: "careers___uk",
    path: "/uk/careers",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/careers/index.vue")
  },
  {
    name: "careers___us",
    path: "/us/careers",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/careers/index.vue")
  },
  {
    name: "cattle-handling___au",
    path: "/au/cattle-handling",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/index.vue")
  },
  {
    name: "cattle-handling___nz",
    path: "/nz/cattle-handling",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/index.vue")
  },
  {
    name: "cattle-handling___uk",
    path: "/uk/cattle-handling",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/index.vue")
  },
  {
    name: "cattle-handling___us",
    path: "/us/cattle-handling",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/index.vue")
  },
  {
    name: "cattle-handling-lenta-cattle-crush___au",
    path: "/au/cattle-handling/lenta-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-cattle-crush.vue")
  },
  {
    name: "cattle-handling-lenta-cattle-crush___nz",
    path: "/nz/cattle-handling/lenta-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-cattle-crush.vue")
  },
  {
    name: "cattle-handling-lenta-cattle-crush___uk",
    path: "/uk/cattle-handling/lenta-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-cattle-crush.vue")
  },
  {
    name: "cattle-handling-lenta-cattle-crush___us",
    path: "/us/cattle-handling/lenta-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-cattle-crush.vue")
  },
  {
    name: "cattle-handling-lenta-hoof-handler___au",
    path: "/au/cattle-handling/lenta-hoof-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-hoof-handler.vue")
  },
  {
    name: "cattle-handling-lenta-hoof-handler___nz",
    path: "/nz/cattle-handling/lenta-hoof-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-hoof-handler.vue")
  },
  {
    name: "cattle-handling-lenta-hoof-handler___uk",
    path: "/uk/cattle-handling/lenta-hoof-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-hoof-handler.vue")
  },
  {
    name: "cattle-handling-lenta-hoof-handler___us",
    path: "/us/cattle-handling/lenta-hoof-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/lenta-hoof-handler.vue")
  },
  {
    name: "cattle-handling-taurus-cattle-crush___au",
    path: "/au/cattle-handling/taurus-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/taurus-cattle-crush.vue")
  },
  {
    name: "cattle-handling-taurus-cattle-crush___nz",
    path: "/nz/cattle-handling/taurus-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/taurus-cattle-crush.vue")
  },
  {
    name: "cattle-handling-taurus-cattle-crush___uk",
    path: "/uk/cattle-handling/taurus-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/taurus-cattle-crush.vue")
  },
  {
    name: "cattle-handling-taurus-cattle-crush___us",
    path: "/us/cattle-handling/taurus-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/taurus-cattle-crush.vue")
  },
  {
    name: "cattle-handling-titan-cattle-crush___au",
    path: "/au/cattle-handling/titan-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/titan-cattle-crush.vue")
  },
  {
    name: "cattle-handling-titan-cattle-crush___nz",
    path: "/nz/cattle-handling/titan-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/titan-cattle-crush.vue")
  },
  {
    name: "cattle-handling-titan-cattle-crush___uk",
    path: "/uk/cattle-handling/titan-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/titan-cattle-crush.vue")
  },
  {
    name: "cattle-handling-titan-cattle-crush___us",
    path: "/us/cattle-handling/titan-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/titan-cattle-crush.vue")
  },
  {
    name: "cattle-handling-vario-cattle-crush___au",
    path: "/au/cattle-handling/vario-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/vario-cattle-crush.vue")
  },
  {
    name: "cattle-handling-vario-cattle-crush___nz",
    path: "/nz/cattle-handling/vario-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/vario-cattle-crush.vue")
  },
  {
    name: "cattle-handling-vario-cattle-crush___uk",
    path: "/uk/cattle-handling/vario-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/vario-cattle-crush.vue")
  },
  {
    name: "cattle-handling-vario-cattle-crush___us",
    path: "/us/cattle-handling/vario-cattle-crush",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-handling/vario-cattle-crush.vue")
  },
  {
    name: "cattle-yard-plans-slugs___au",
    path: "/au/cattle-yard-plans/:slugs(.*)*",
    meta: _91_46_46_46slugs_930vlZFdZ9uDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/[...slugs].vue")
  },
  {
    name: "cattle-yard-plans-slugs___nz",
    path: "/nz/cattle-yard-plans/:slugs(.*)*",
    meta: _91_46_46_46slugs_930vlZFdZ9uDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/[...slugs].vue")
  },
  {
    name: "cattle-yard-plans-slugs___uk",
    path: "/uk/cattle-yard-plans/:slugs(.*)*",
    meta: _91_46_46_46slugs_930vlZFdZ9uDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/[...slugs].vue")
  },
  {
    name: "cattle-yard-plans-slugs___us",
    path: "/us/cattle-yard-plans/:slugs(.*)*",
    meta: _91_46_46_46slugs_930vlZFdZ9uDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/[...slugs].vue")
  },
  {
    name: "cattle-yard-plans___au",
    path: "/au/cattle-yard-plans",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/index.vue")
  },
  {
    name: "cattle-yard-plans___nz",
    path: "/nz/cattle-yard-plans",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/index.vue")
  },
  {
    name: "cattle-yard-plans___uk",
    path: "/uk/cattle-yard-plans",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/index.vue")
  },
  {
    name: "cattle-yard-plans___us",
    path: "/us/cattle-yard-plans",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/cattle-yard-plans/index.vue")
  },
  {
    name: "contact___au",
    path: "/au/contact",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/contact.vue")
  },
  {
    name: "contact___nz",
    path: "/nz/contact",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/contact.vue")
  },
  {
    name: "contact___uk",
    path: "/uk/contact",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/contact.vue")
  },
  {
    name: "contact___us",
    path: "/us/contact",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/contact.vue")
  },
  {
    name: "documentation-slugs___au",
    path: "/au/documentation/:slugs(.*)*",
    meta: _91_46_46_46slugs_93k1fRU6kdmsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/documentation/[...slugs].vue")
  },
  {
    name: "documentation-slugs___nz",
    path: "/nz/documentation/:slugs(.*)*",
    meta: _91_46_46_46slugs_93k1fRU6kdmsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/documentation/[...slugs].vue")
  },
  {
    name: "documentation-slugs___uk",
    path: "/uk/documentation/:slugs(.*)*",
    meta: _91_46_46_46slugs_93k1fRU6kdmsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/documentation/[...slugs].vue")
  },
  {
    name: "documentation-slugs___us",
    path: "/us/documentation/:slugs(.*)*",
    meta: _91_46_46_46slugs_93k1fRU6kdmsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/documentation/[...slugs].vue")
  },
  {
    name: "documentation___au",
    path: "/au/documentation",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/documentation/index.vue")
  },
  {
    name: "documentation___nz",
    path: "/nz/documentation",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/documentation/index.vue")
  },
  {
    name: "documentation___uk",
    path: "/uk/documentation",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/documentation/index.vue")
  },
  {
    name: "documentation___us",
    path: "/us/documentation",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/documentation/index.vue")
  },
  {
    name: "events___au",
    path: "/au/events",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/events.vue")
  },
  {
    name: "events___nz",
    path: "/nz/events",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/events.vue")
  },
  {
    name: "events___uk",
    path: "/uk/events",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/events.vue")
  },
  {
    name: "events___us",
    path: "/us/events",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/events.vue")
  },
  {
    name: "index___au",
    path: "/au",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/index.vue")
  },
  {
    name: "index___nz",
    path: "/nz",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/index.vue")
  },
  {
    name: "index___uk",
    path: "/uk",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/index.vue")
  },
  {
    name: "index___us",
    path: "/us",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/index.vue")
  },
  {
    name: "integrated-solutions___au",
    path: "/au/integrated-solutions",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/integrated-solutions.vue")
  },
  {
    name: "integrated-solutions___nz",
    path: "/nz/integrated-solutions",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/integrated-solutions.vue")
  },
  {
    name: "integrated-solutions___uk",
    path: "/uk/integrated-solutions",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/integrated-solutions.vue")
  },
  {
    name: "integrated-solutions___us",
    path: "/us/integrated-solutions",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/integrated-solutions.vue")
  },
  {
    name: "media___au",
    path: "/au/media",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/media.vue")
  },
  {
    name: "media___nz",
    path: "/nz/media",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/media.vue")
  },
  {
    name: "media___uk",
    path: "/uk/media",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/media.vue")
  },
  {
    name: "media___us",
    path: "/us/media",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/media.vue")
  },
  {
    name: "pages-slugs___au",
    path: "/au/pages/:slugs(.*)*",
    meta: _91_46_46_46slugs_938y6jZkR4njMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/pages/[...slugs].vue")
  },
  {
    name: "pages-slugs___nz",
    path: "/nz/pages/:slugs(.*)*",
    meta: _91_46_46_46slugs_938y6jZkR4njMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/pages/[...slugs].vue")
  },
  {
    name: "pages-slugs___uk",
    path: "/uk/pages/:slugs(.*)*",
    meta: _91_46_46_46slugs_938y6jZkR4njMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/pages/[...slugs].vue")
  },
  {
    name: "pages-slugs___us",
    path: "/us/pages/:slugs(.*)*",
    meta: _91_46_46_46slugs_938y6jZkR4njMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/pages/[...slugs].vue")
  },
  {
    name: "promotions-slugs___au",
    path: "/au/promotions/:slugs(.*)*",
    meta: _91_46_46_46slugs_93KqSQ5sZ9dgMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/promotions/[...slugs].vue")
  },
  {
    name: "promotions-slugs___nz",
    path: "/nz/promotions/:slugs(.*)*",
    meta: _91_46_46_46slugs_93KqSQ5sZ9dgMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/promotions/[...slugs].vue")
  },
  {
    name: "promotions-slugs___uk",
    path: "/uk/promotions/:slugs(.*)*",
    meta: _91_46_46_46slugs_93KqSQ5sZ9dgMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/promotions/[...slugs].vue")
  },
  {
    name: "promotions-slugs___us",
    path: "/us/promotions/:slugs(.*)*",
    meta: _91_46_46_46slugs_93KqSQ5sZ9dgMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/promotions/[...slugs].vue")
  },
  {
    name: "promotions___au",
    path: "/au/promotions",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/promotions/index.vue")
  },
  {
    name: "promotions___nz",
    path: "/nz/promotions",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/promotions/index.vue")
  },
  {
    name: "promotions___uk",
    path: "/uk/promotions",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/promotions/index.vue")
  },
  {
    name: "promotions___us",
    path: "/us/promotions",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/promotions/index.vue")
  },
  {
    name: "search___au",
    path: "/au/search",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/search.vue")
  },
  {
    name: "search___nz",
    path: "/nz/search",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/search.vue")
  },
  {
    name: "search___uk",
    path: "/uk/search",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/search.vue")
  },
  {
    name: "search___us",
    path: "/us/search",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/search.vue")
  },
  {
    name: "sheep-handling___au",
    path: "/au/sheep-handling",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/index.vue")
  },
  {
    name: "sheep-handling___nz",
    path: "/nz/sheep-handling",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/index.vue")
  },
  {
    name: "sheep-handling___uk",
    path: "/uk/sheep-handling",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/index.vue")
  },
  {
    name: "sheep-handling___us",
    path: "/us/sheep-handling",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/index.vue")
  },
  {
    name: "sheep-handling-racewell-jr-sheep-handler___au",
    path: "/au/sheep-handling/racewell-jr-sheep-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-jr-sheep-handler.vue")
  },
  {
    name: "sheep-handling-racewell-jr-sheep-handler___nz",
    path: "/nz/sheep-handling/racewell-jr-sheep-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-jr-sheep-handler.vue")
  },
  {
    name: "sheep-handling-racewell-jr-sheep-handler___uk",
    path: "/uk/sheep-handling/racewell-jr-sheep-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-jr-sheep-handler.vue")
  },
  {
    name: "sheep-handling-racewell-jr-sheep-handler___us",
    path: "/us/sheep-handling/racewell-jr-sheep-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-jr-sheep-handler.vue")
  },
  {
    name: "sheep-handling-racewell-sheep-drafter___au",
    path: "/au/sheep-handling/racewell-sheep-drafter",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-drafter.vue")
  },
  {
    name: "sheep-handling-racewell-sheep-drafter___nz",
    path: "/nz/sheep-handling/racewell-sheep-drafter",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-drafter.vue")
  },
  {
    name: "sheep-handling-racewell-sheep-drafter___uk",
    path: "/uk/sheep-handling/racewell-sheep-drafter",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-drafter.vue")
  },
  {
    name: "sheep-handling-racewell-sheep-drafter___us",
    path: "/us/sheep-handling/racewell-sheep-drafter",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-drafter.vue")
  },
  {
    name: "sheep-handling-racewell-sheep-handler___au",
    path: "/au/sheep-handling/racewell-sheep-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-handler.vue")
  },
  {
    name: "sheep-handling-racewell-sheep-handler___nz",
    path: "/nz/sheep-handling/racewell-sheep-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-handler.vue")
  },
  {
    name: "sheep-handling-racewell-sheep-handler___uk",
    path: "/uk/sheep-handling/racewell-sheep-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-handler.vue")
  },
  {
    name: "sheep-handling-racewell-sheep-handler___us",
    path: "/us/sheep-handling/racewell-sheep-handler",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/racewell-sheep-handler.vue")
  },
  {
    name: "sheep-handling-vetmarker-lamb-chute___au",
    path: "/au/sheep-handling/vetmarker-lamb-chute",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/vetmarker-lamb-chute.vue")
  },
  {
    name: "sheep-handling-vetmarker-lamb-chute___nz",
    path: "/nz/sheep-handling/vetmarker-lamb-chute",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/vetmarker-lamb-chute.vue")
  },
  {
    name: "sheep-handling-vetmarker-lamb-chute___uk",
    path: "/uk/sheep-handling/vetmarker-lamb-chute",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/vetmarker-lamb-chute.vue")
  },
  {
    name: "sheep-handling-vetmarker-lamb-chute___us",
    path: "/us/sheep-handling/vetmarker-lamb-chute",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/sheep-handling/vetmarker-lamb-chute.vue")
  },
  {
    name: "shop-checkout-complete___au",
    path: "/au/shop/checkout/complete",
    meta: complete2CQwDlcu8vMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/complete.vue")
  },
  {
    name: "shop-checkout-complete___nz",
    path: "/nz/shop/checkout/complete",
    meta: complete2CQwDlcu8vMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/complete.vue")
  },
  {
    name: "shop-checkout-complete___uk",
    path: "/uk/shop/checkout/complete",
    meta: complete2CQwDlcu8vMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/complete.vue")
  },
  {
    name: "shop-checkout-complete___us",
    path: "/us/shop/checkout/complete",
    meta: complete2CQwDlcu8vMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/complete.vue")
  },
  {
    name: "shop-checkout___au",
    path: "/au/shop/checkout",
    meta: indexkLmhzEb4dmMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/index.vue")
  },
  {
    name: "shop-checkout___nz",
    path: "/nz/shop/checkout",
    meta: indexkLmhzEb4dmMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/index.vue")
  },
  {
    name: "shop-checkout___uk",
    path: "/uk/shop/checkout",
    meta: indexkLmhzEb4dmMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/index.vue")
  },
  {
    name: "shop-checkout___us",
    path: "/us/shop/checkout",
    meta: indexkLmhzEb4dmMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/index.vue")
  },
  {
    name: "shop-checkout-payment___au",
    path: "/au/shop/checkout/payment",
    meta: paymentyUwuI5Y1GiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/payment.vue")
  },
  {
    name: "shop-checkout-payment___nz",
    path: "/nz/shop/checkout/payment",
    meta: paymentyUwuI5Y1GiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/payment.vue")
  },
  {
    name: "shop-checkout-payment___uk",
    path: "/uk/shop/checkout/payment",
    meta: paymentyUwuI5Y1GiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/payment.vue")
  },
  {
    name: "shop-checkout-payment___us",
    path: "/us/shop/checkout/payment",
    meta: paymentyUwuI5Y1GiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/payment.vue")
  },
  {
    name: "shop-checkout-shipping___au",
    path: "/au/shop/checkout/shipping",
    meta: shippingMfziToqzeHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/shipping.vue")
  },
  {
    name: "shop-checkout-shipping___nz",
    path: "/nz/shop/checkout/shipping",
    meta: shippingMfziToqzeHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/shipping.vue")
  },
  {
    name: "shop-checkout-shipping___uk",
    path: "/uk/shop/checkout/shipping",
    meta: shippingMfziToqzeHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/shipping.vue")
  },
  {
    name: "shop-checkout-shipping___us",
    path: "/us/shop/checkout/shipping",
    meta: shippingMfziToqzeHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/checkout/shipping.vue")
  },
  {
    name: "shop___au",
    path: "/au/shop",
    meta: indexTlZourcGyHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue")
  },
  {
    name: "shop___nz",
    path: "/nz/shop",
    meta: indexTlZourcGyHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue")
  },
  {
    name: "shop___uk",
    path: "/uk/shop",
    meta: indexTlZourcGyHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue")
  },
  {
    name: "shop___us",
    path: "/us/shop",
    meta: indexTlZourcGyHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue")
  },
  {
    name: "shop-products-slugs___au",
    path: "/au/shop/products/:slugs(.*)*",
    meta: _91_46_46_46slugs_93kSr8OhjeNEMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/products/[...slugs].vue")
  },
  {
    name: "shop-products-slugs___nz",
    path: "/nz/shop/products/:slugs(.*)*",
    meta: _91_46_46_46slugs_93kSr8OhjeNEMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/products/[...slugs].vue")
  },
  {
    name: "shop-products-slugs___uk",
    path: "/uk/shop/products/:slugs(.*)*",
    meta: _91_46_46_46slugs_93kSr8OhjeNEMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/products/[...slugs].vue")
  },
  {
    name: "shop-products-slugs___us",
    path: "/us/shop/products/:slugs(.*)*",
    meta: _91_46_46_46slugs_93kSr8OhjeNEMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/products/[...slugs].vue")
  },
  {
    name: "tools___au",
    path: "/au/tools",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/tools/index.vue")
  },
  {
    name: "tools___nz",
    path: "/nz/tools",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/tools/index.vue")
  },
  {
    name: "tools___uk",
    path: "/uk/tools",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/tools/index.vue")
  },
  {
    name: "tools___us",
    path: "/us/tools",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/tools/index.vue")
  },
  {
    name: "tools-racewell-return-on-investment-calculator___au",
    path: "/au/tools/racewell-return-on-investment-calculator",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/tools/racewell-return-on-investment-calculator.vue")
  },
  {
    name: "tools-racewell-return-on-investment-calculator___nz",
    path: "/nz/tools/racewell-return-on-investment-calculator",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/tools/racewell-return-on-investment-calculator.vue")
  },
  {
    name: "tools-racewell-return-on-investment-calculator___uk",
    path: "/uk/tools/racewell-return-on-investment-calculator",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/tools/racewell-return-on-investment-calculator.vue")
  },
  {
    name: "tools-racewell-return-on-investment-calculator___us",
    path: "/us/tools/racewell-return-on-investment-calculator",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/tools/racewell-return-on-investment-calculator.vue")
  },
  {
    name: "collections___au",
    path: "/au/shop/collections/:slugs*",
    meta: {"layout":"shop-layout"},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue")
  },
  {
    name: "collections___nz",
    path: "/nz/shop/collections/:slugs*",
    meta: {"layout":"shop-layout"},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue")
  },
  {
    name: "collections___uk",
    path: "/uk/shop/collections/:slugs*",
    meta: {"layout":"shop-layout"},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue")
  },
  {
    name: "collections___us",
    path: "/us/shop/collections/:slugs*",
    meta: {"layout":"shop-layout"},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/shop/index.vue")
  }
]