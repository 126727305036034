<template>
	<twic-img
		:alt
		:anchor
		:eager
		:fetchpriority
		:focus
		:intrinsic
		:mode
		:placeholder
		:position
		:ratio
		:refit
		:sizes
		:src />
</template>

<script lang="ts" setup>
	import { getTwicPicsUrl } from '~/utilities/get-twicpics-url'

	const props = defineProps<ImageOptions>()

	const intrinsic = computed(() =>
		props.width && props.height ? `${props.width}x${props.height}` : null,
	)

	const ratio = computed(() => {
		if (props.ratio) {
			return props.ratio
		}

		if (props.width && props.height) {
			return `${props.width}:${props.height}`
		}

		return null
	})

	const src = computed(() => getTwicPicsUrl(props.src))
</script>

<script lang="ts">
	export interface ImageOptions {
		/**
		 * The alt text of the image.
		 */
		alt?: string

		/**
		 * Positions the image in both contain and cover mode.
		 */
		anchor?:
			| 'top'
			| 'bottom'
			| 'left'
			| 'right'
			| 'top-left'
			| 'top-right'
			| 'bottom-left'
			| 'bottom-right'
			| 'center'

		/**
		 * Load the image as soon as the component is mounted.
		 * This effectively means disabling lazy loading for this image.
		 */
		eager?: boolean

		/**
		 * The priority of the image request.
		 */
		fetchpriority?: 'high' | 'low' | 'auto'

		/**
		 * Sets the focus point in cover mode. focus takes precedence over anchor when both are provided.
		 */
		focus?: '<auto|coordinates>'

		/**
		 * The original height of the image.
		 */
		height?: number | `${number}`

		/**
		 * Determines if the image fills the area and is cropped accordingly (cover)
		 * or if the image will sit inside the area with no cropping (contain).
		 */
		mode?: 'contain' | 'cover'

		/**
		 * Positions the image in contain mode.
		 * position takes precedence over anchor when both are provided.
		 * Syntax is the same as for CSS position properties background-position and object-position.
		 */
		position?: string

		/**
		 * The placeholder to use while the image is loading.
		 */
		placeholder?: 'preview' | 'maincolor' | 'meancolor' | 'none'

		/**
		 * The intrinsic size of the image.
		 */
		ratio?: 'none' | `${number}:${number}` | `${number}/${number}`

		/**
		 * Reframes the image to maximize the area occupied by the main object(s) while respecting ratio in cover mode.
		 * Can be true, false or a list of comma-separated length defining padding
		 */
		refit?: boolean | string

		/**
		 * Specifies the layout width of the image for each breakpoints using media query syntax.
		 * The value is a comma separated string with the format: "media-query width".
		 */
		sizes?: string

		/**
		 * The URL of the image.
		 */
		src: string

		/**
		 * The original width of the image.
		 */
		width?: number | `${number}`
	}
</script>

<style lang="scss"></style>
