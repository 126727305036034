<template>
	<nav class="header__nav">
		<div class="header__nav__inner">
			<slot />
		</div>
	</nav>
</template>

<script lang="ts" setup></script>

<style lang="scss">
	@layer components {
		.header__nav {
			display: block;

			&__inner {
				display: grid;
				grid-auto-flow: column;
				height: 100%;

				> :first-child {
					padding-left: 0 !important;
				}

				> :last-child {
					padding-right: 0 !important;
				}
			}
		}
	}
</style>
