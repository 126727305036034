<template>
	<component
		:is="to ? nuxtLink : 'button'"
		class="header__link"
		:class="{
			'header__link--active': active,
		}"
		v-bind="{
			'to': to,
			'type': !to ? 'button' : undefined,
			'exact-active-class': to ? 'header__link--active' : undefined,
			'active-class': to ? 'header__link--active' : undefined,
		}">
		<span class="header__link__inner">
			<slot>
				<component :is="nuxtIcon" v-if="icon" :name="icon" size="20" />
				<span v-if="label">{{ label }}</span>
			</slot>
		</span>
	</component>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		active?: boolean
		icon?: string
		label?: string
		to?: string
	}>()

	const nuxtIcon = resolveComponent('Icon')
	const nuxtLink = resolveComponent('NuxtLink')
</script>

<style lang="scss">
	@layer components {
		.header__link {
			display: block;
			outline-width: 2px;
			outline-offset: -2px;
			color: inherit;
			font-weight: 900;
			font-size: $text-sm;
			text-decoration: none;
			text-transform: capitalize;

			&--active {
				color: $brand;
			}

			&__inner {
				display: flex;
				align-items: center;
				gap: 5px;
				height: 100%;

				> * {
					flex-shrink: 0;
				}
			}
		}
	}
</style>
