<template>
	<div
		class="formie-controls align-items-center flex gap-20"
		:class="{
			'formie-controls--skewer': skewer,
		}">
		<div v-if="!isFirstPage" class="flex-auto">
			<v-button
				color="primary"
				:disabled="loading"
				fill="solid"
				shape="round"
				type="button"
				:value="prevLabel ?? 'Prev'"
				@click.prevent="emit('prev')" />
		</div>

		<div v-if="!isLastPage" class="flex-auto">
			<v-button
				color="primary"
				:disabled="loading"
				fill="solid"
				shape="round"
				type="button"
				:value="nextLabel ?? 'Next'"
				@click.prevent="emit('next')" />
		</div>

		<div v-if="isLastPage" class="flex-auto">
			<v-button
				color="success"
				fill="solid"
				:loading="loading"
				shape="round"
				type="submit"
				:value="submitLabel ?? 'Submit enquiry'"
				@click="emit('submit')" />
		</div>
	</div>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		submitLabel?: string
		nextLabel?: string
		prevLabel?: string
		isFirstPage: boolean
		isLastPage: boolean
		loading?: boolean
		skewer?: boolean
	}>()

	const emit = defineEmits<{
		next: []
		prev: []
		submit: []
	}>()
</script>

<style lang="scss">
	.formie-controls {
		position: relative;
		isolation: isolate;

		&--skewer {
			justify-content: center;

			&:before {
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				z-index: -1;
				background-color: var(--formie-controls-skewer-color, #{$gray-500});
				width: 100%;
				height: 1px;
				pointer-events: none;
				content: '';
			}
		}
	}
</style>
