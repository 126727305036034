<template>
	<div class="quantity">
		<v-button
			aria-label="Decrement quantity"
			class="quantity__btn"
			color="white"
			:disabled="disabled || !!(min && min === internalValue)"
			fill="solid"
			icon="tepari:minus"
			shape="round"
			type="button"
			@click="decrement" />

		<v-input
			v-model="internalValue"
			class="quantity__input"
			label="quantity"
			label-hidden
			readonly
			type="number" />

		<v-button
			aria-label="Increment quantity"
			class="quantity__btn"
			color="white"
			:disabled="disabled || !!(max && max === internalValue)"
			fill="solid"
			icon="tepari:plus"
			shape="round"
			type="button"
			@click="increment" />
	</div>
</template>

<script lang="ts" setup>
	import { isNullOrUndefined } from '@/utilities/is-null-or-undefined'

	const props = defineProps<{
		disabled?: boolean
		max?: number
		min?: number
		modelValue?: number
	}>()

	const emit = defineEmits<{
		'update:modelValue': [number]
	}>()

	const modelValue = toRef(props, 'modelValue')
	const internalValue = ref(props.modelValue ?? 1)

	const max = computed(() => props.max ?? null)
	const min = computed(() => props.min ?? 1)

	watch(
		modelValue,
		(value) => {
			if (isNullOrUndefined(value)) return
			internalValue.value = value
		},
		{ immediate: true },
	)

	function increment() {
		if (max.value && max.value === internalValue.value) return

		internalValue.value++
		emit('update:modelValue', internalValue.value)
	}

	function decrement() {
		if (min.value === internalValue.value) return

		internalValue.value--
		emit('update:modelValue', internalValue.value)
	}
</script>

<style lang="scss">
	.quantity {
		display: flex;
		align-items: center;
		border: 1px solid $gray-300;
		border-radius: 999px;
		height: var(--qty-height, 36px);

		&__input {
			flex: 1 1 auto;

			.input {
				border: none;
				background-color: transparent;
				font-weight: 700;
				font-size: $text-sm;
				text-align: center;
			}
		}

		&__btn {
			--btn-height: calc(var(--qty-height, 36px) - 6px);

			flex: 0 0 auto;
			margin: 2px;

			.icon {
				width: 14px;
			}
		}
	}
</style>
