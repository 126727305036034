<template>
	<div ref="el" class="listbox" v-bind="attributes">
		<input ref="focusTrapEl" class="listbox__focus-trap" type="text" />

		<header v-if="showFilter" class="listbox__header">
			<div class="align-items-center flex flex-wrap gap-10">
				<div v-if="multiselect" class="flex-auto">
					<v-checkbox
						label="Toggle all"
						label-hidden
						:model-value="internalValue?.length === optionValues?.length"
						@change="toggleAll" />
				</div>
				<div class="flex-fill">
					<v-input
						v-model="filterValue"
						:label="'Filter options'"
						:label-hidden="true" />
				</div>
			</div>
		</header>

		<ul
			v-if="options.length"
			class="listbox__body"
			role="listbox"
			@click="trapFocus()">
			<template v-for="(item, itemIndex) in filter(options)" :key="itemIndex">
				<template v-if="isOptionGroup(item)">
					<li role="presentation">
						<v-listbox-label :value="item.label" />
					</li>
					<li
						v-for="(childItem, childItemIndex) in filter(item.options)"
						v-ripple
						:key="childItemIndex"
						class="listbox__option"
						:class="{
							'listbox__option--selected': isSelected(childItem.value),
						}"
						role="option">
						<v-listbox-checkbox
							v-if="multiselect"
							:checked="isSelected(childItem.value)"
							:option="childItem"
							@input="onMultiInput">
							<template v-if="slots.option" #option="slotProps">
								<slot name="option" v-bind="slotProps" />
							</template>
						</v-listbox-checkbox>

						<v-listbox-btn v-else :option="childItem" @input="onInput">
							<template v-if="slots.option" #option="slotProps">
								<slot name="option" v-bind="slotProps" />
							</template>
						</v-listbox-btn>
					</li>
				</template>

				<template v-else-if="isOption(item)">
					<li
						v-ripple
						class="listbox__option"
						:class="{
							'listbox__option--selected': isSelected(item.value),
						}"
						role="option">
						<v-listbox-checkbox
							v-if="multiselect"
							:checked="isSelected(item.value)"
							:option="item"
							@input="onMultiInput">
							<template v-if="slots.option" #option="slotProps">
								<slot name="option" v-bind="slotProps" />
							</template>
						</v-listbox-checkbox>

						<v-listbox-btn v-else :option="item" @input="onInput">
							<template v-if="slots.option" #option="slotProps">
								<slot name="option" v-bind="slotProps" />
							</template>
						</v-listbox-btn>
					</li>
				</template>
			</template>
		</ul>
		<div v-else>
			<slot name="empty">
				<p class="p-15">No options are available.</p>
			</slot>
		</div>

		<footer v-if="showApply" class="listbox__footer">
			<v-button
				class="listbox__apply-btn"
				color="primary"
				shape="square"
				size="lg"
				type="button"
				value="Apply"
				@click="apply" />
		</footer>
	</div>
</template>

<script lang="ts" setup>
	import { isArray } from 'lodash-es'

	const props = defineProps<{
		attributes?: Record<string, any>
		modelValue?: any | any[]
		multiselect?: boolean
		options: Array<ListboxOption | ListboxOptionGroup>
		showApply?: boolean
		showFilter?: boolean
	}>()

	const emit = defineEmits<{
		'apply': [any | any[]]
		'input': [any | any[]]
		'update:modelValue': [any | any[]]
	}>()

	const slots = useSlots()
	const el = ref<HTMLElement>()
	const filterValue = ref<string>()
	const focusTrapEl = ref<HTMLInputElement>()
	const internalValue = ref(props.modelValue)
	const modelValue = toRef(props, 'modelValue')

	const optionValues = computed(() =>
		props.options.flatMap((item) => {
			if (isOptionGroup(item)) return item.options
			else return item
		}),
	)

	watch(modelValue, (value) => (internalValue.value = value), {
		immediate: true,
	})

	onMounted(() => {
		if (props.multiselect && !isArray(internalValue.value)) {
			internalValue.value = []
			emit('input', internalValue.value)
			emit('update:modelValue', internalValue.value)
		}
	})

	function apply() {
		emit('apply', internalValue.value)
		emit('input', internalValue.value)
		emit('update:modelValue', internalValue.value)
	}

	function filter<T>(options: T[]): T[] {
		return options.filter((option) => {
			if (!filterValue.value) return true

			const value = filterValue.value.toLowerCase()

			if (isOptionGroup(option)) {
				return option.options.some((o) => o.label.toLowerCase().includes(value))
			}

			if (isOption(option)) {
				return option.label.toLowerCase().includes(value)
			}

			return false
		})
	}

	function isSelected(value: any) {
		return isArray(internalValue.value)
			? internalValue.value.includes(value)
			: internalValue.value === value
	}

	function isOptionGroup(value: any): value is ListboxOptionGroup {
		return value && 'options' in value && isArray(value.options)
	}

	function isOption(value: any): value is ListboxOption {
		return value && !('options' in value)
	}

	function onInput(value: any) {
		internalValue.value = value

		if (!props.showApply) {
			apply()
		}
	}

	function onMultiInput(value: any) {
		if (!isArray(internalValue.value)) {
			internalValue.value = []
		}

		if (isSelected(value)) {
			internalValue.value = internalValue.value.filter((v: any) => v !== value)
		} else {
			internalValue.value = [...internalValue.value, value]
		}

		if (!props.showApply) {
			apply()
		}
	}

	function toggleAll() {
		if (!props.multiselect) return

		if (internalValue.value.length === optionValues.value.length) {
			internalValue.value = []
		} else {
			internalValue.value = optionValues.value.map((item) => item.value)
		}
	}

	function trapFocus() {
		focusTrapEl.value?.focus()
	}

	defineExpose({
		focus: trapFocus,
		value: internalValue,
	})
</script>

<script lang="ts">
	export interface ListboxOption<T = any> {
		label: string
		value: T
		[key: string]: any
	}

	export interface ListboxOptionGroup<T = any> {
		label: string
		options: ListboxOption<T>[]
	}
</script>

<style lang="scss">
	@layer components {
		.listbox {
			display: flex;
			flex-direction: column;
			transition: 200ms ease-in-out;
			transition-property: border-color;
			border: 1px solid $gray-300;
			border-radius: 5px;
			background-color: $white;
			overflow: hidden;
			user-select: none;

			&:has(.listbox__focus-trap:focus) {
				border-color: rgba($brand, 0.5);
			}

			&__focus-trap {
				@include sr-only();
			}

			&__header {
				@include var-pad('listbox-header', 10px, 10px);

				flex: 0 0 auto;
				border-bottom: 1px solid $gray-300;
				background-color: $gray-100;
				width: 100%;
			}

			&__body {
				@include reset-list();
				@include var-pad('listbox-body', 10px, 10px);

				flex: 1 1 auto;
				overflow: auto;
			}

			&__footer {
				@include var-pad('listbox-footer', 0px, 0px);

				display: flex;
				flex: 0 0 auto;
				gap: 1px;
				border-top: 1px solid $gray-300;
				background-color: $gray-100;
				width: 100%;

				> * {
					flex: 1 1 auto;
				}
			}

			&__option {
				position: relative;
				transition: 300ms ease;
				transition-property: background-color;
				cursor: pointer;
				border-radius: 5px;
				overflow: hidden;

				&:not(:last-child) {
					margin-bottom: 5px;
				}

				&:hover,
				&--focused {
					background-color: rgba($gray-100, 1);

					.badge {
						--badge-bg-color: #{$white} !important;
					}
				}

				&--selected {
					background-color: rgba($brand, 0.1) !important;

					.badge {
						--badge-bg-color: #{$white} !important;
					}
				}
			}
		}
	}
</style>
