<template>
	<component
		:is="is ?? 'div'"
		class="thumbnail"
		:class="{
			'thumbnail--overlay-hover': overlay === 'hover',
			'thumbnail--overlay-transparent': overlay === 'transparent',
			'thumbnail--overlay-visible': overlay === 'visible',
			'thumbnail--contain': fit === 'contain',
			'thumbnail--cover': !fit || fit === 'cover',
		}"
		:style="{
			'aspect-ratio': ratio?.replace(':', '/'),
		}">
		<slot />

		<div v-if="slots.overlay" class="thumbnail__overlay">
			<slot name="overlay" />
		</div>
	</component>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		fit?: 'contain' | 'cover'
		is?: any
		overlay?: 'hover' | 'transparent' | 'visible'
		ratio?: `${number}:${number}`
	}>()

	const slots = useSlots()
</script>

<style lang="scss">
	@layer components {
		.thumbnail {
			display: block;
			width: 100%;
			position: relative;
			overflow: hidden;
			isolation: isolate;

			&--contain {
				img,
				video {
					object-fit: contain !important;
				}
			}

			&--cover {
				img,
				video {
					object-fit: cover !important;
				}
			}

			&--overlay-hover {
				.thumbnail__overlay {
					opacity: 0;
					transition: 300ms ease-in-out;
					transition-property: opacity;
				}

				&:hover,
				&:focus-within {
					.thumbnail__overlay {
						opacity: 1;
					}
				}
			}

			&--overlay-transparent {
				.thumbnail__overlay {
					background-color: transparent;
					transition: 300ms ease-in-out;
					transition-property: background-color;
				}

				&:hover,
				&:focus-within {
					.thumbnail__overlay {
						background-color: var(
							--thumbnail-overlay-color,
							rgba($black, 0.25)
						);
					}
				}
			}

			&__overlay {
				position: absolute;
				inset: 0;
				z-index: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				background-color: var(--thumbnail-overlay-color, rgba($black, 0.25));
				color: var(--thumbnail-color, $white);

				&:is(:empty) {
					display: none !important;
				}
			}
		}
	}
</style>
