<template>
	<form novalidate @submit.prevent="onSubmit">
		<v-alert v-if="status" class="mb-40" :type="status.type">
			<p>{{ status.message }}</p>
			<ul v-if="status.listItems?.length">
				<li v-for="item in status.listItems" :key="item">{{ item }}</li>
			</ul>
		</v-alert>

		<div v-if="status?.type !== 'success'" class="flex flex-wrap gap-20">
			<v-field class="flex-full" :vuelidate="vuelidate.fullName">
				<v-input
					v-model="formData.fullName"
					:disabled="loading"
					label="Full Name"
					required />
			</v-field>

			<v-field class="flex-full" :vuelidate="vuelidate.email">
				<v-input
					v-model="formData.email"
					:disabled="loading"
					label="Email"
					required
					type="email" />
			</v-field>

			<v-field class="flex-full" :vuelidate="vuelidate.phone">
				<v-input v-model="formData.phone" :disabled="loading" label="Phone" />
			</v-field>

			<v-field class="flex-full" :vuelidate="vuelidate.password">
				<v-input
					v-model="formData.password"
					:disabled="loading"
					label="Password"
					required
					type="password" />
			</v-field>

			<v-field class="flex-full" :vuelidate="vuelidate.repeatPassword">
				<v-input
					v-model="formData.repeatPassword"
					:disabled="loading"
					label="Repeat Password"
					required
					type="password" />
			</v-field>

			<v-field
				class="align-items-center flex flex-full"
				:vuelidate="vuelidate.termsAndConditions">
				<v-checkbox
					v-model="formData.termsAndConditions"
					:disabled="loading"
					label="I agree to the Terms & Conditions." />

				<p class="pt-5 pl-30 text-xs">
					Our Terms & Conditions can be found
					<nuxt-link
						class="text-brand"
						:to="localePath('/pages/terms-and-conditions')">
						here.
					</nuxt-link>
				</p>
			</v-field>

			<v-button
				class="flex-full"
				:color="vuelidate.$anyDirty ? 'success' : 'medium'"
				fill="solid"
				:loading="loading"
				shape="round"
				size="lg"
				type="submit"
				value="Register" />
		</div>
	</form>
</template>

<script lang="ts" setup>
	import useVuelidate from '@vuelidate/core'
	import { email, helpers, required, sameAs } from '@vuelidate/validators'

	const { register } = useUserStore()

	const localePath = useLocalePath()

	const status = ref<Status | null>(null)

	const loading = ref(false)

	const formData = ref({
		fullName: '',
		email: '',
		phone: '',
		password: '',
		repeatPassword: '',
		termsAndConditions: false,
	})

	const vuelidate = useVuelidate<any>(
		{
			fullName: {
				$autoDirty: true,
				required,
			},
			email: {
				$autoDirty: true,
				required,
				email,
			},
			phone: {
				$autoDirty: true,
			},
			password: {
				$autoDirty: true,
				required,
			},
			repeatPassword: {
				$autoDirty: true,
				required,
				sameAs: helpers.withMessage(
					`This field must match "Password".`,
					sameAs(computed(() => formData.value.password)),
				),
			},
			termsAndConditions: {
				$autoDirty: true,
				required,
			},
		},
		formData,
	)

	async function onSubmit() {
		const isValid = await vuelidate.value.$validate()

		if (!isValid) {
			status.value = {
				type: 'error',
				message: 'Please correct the errors below and try again.',
			}
			return
		}

		loading.value = true

		const { error, success } = await register({
			fullName: formData.value.fullName,
			email: formData.value.email,
			phone: formData.value.phone,
			password: formData.value.password,
		})

		status.value = success
			? {
					type: 'success',
					message:
						'Registration successful, please check your email to activate your account.',
				}
			: {
					type: 'error',
					message: error?.message || 'Something went wrong. Please try again.',
				}

		if (error?.errors) {
			status.value.listItems = Object.values(error.errors).flatMap(
				(e: any) => e,
			)
		}

		loading.value = false
	}
</script>

<script lang="ts">
	interface Status {
		type: 'success' | 'info' | 'warning' | 'error'
		message: string
		listItems?: string[]
	}
</script>

<style lang="scss"></style>
