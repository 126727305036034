<template>
	<div
		class="nav-tabs"
		:style="{
			'--nav-tabs-bg-top': `${bgTop}px`,
			'--nav-tabs-bg-height': `${bgHeight}px`,
		}">
		<div class="nav-tabs__controls">
			<div class="nav-tabs__bg" role="presentation"></div>

			<v-nav-tabs-btn
				v-for="(menu, index) of items"
				:key="menu.id"
				ref="buttons"
				:active="activeIndex === index"
				:menu="menu"
				@click="activeIndex = index"
				@mouseover="activeIndex = index" />
		</div>

		<div class="nav-tabs__content">
			<v-nav-tabs-content
				v-for="(menu, index) of items"
				:key="menu.id"
				ref="content"
				:active="index === activeIndex"
				:after-active="index > activeIndex"
				:before-active="index < activeIndex"
				:menu="menu" />
		</div>
	</div>
</template>

<script lang="ts" setup>
	import NavTabsBtn from './nav-tabs-btn.vue'
	import NavTabsContent from './nav-tabs-content.vue'
	import type { Menu, ProvidedProps } from '../header.vue'

	defineProps<{
		items: Menu[]
	}>()

	const { state } = inject('header') as ProvidedProps

	const activeIndex = ref(0)
	const buttons = ref<InstanceType<typeof NavTabsBtn>[]>([])
	const content = ref<InstanceType<typeof NavTabsContent>[]>([])
	const bgTop = ref(0)
	const bgHeight = ref(0)

	const activeButton = computed(() => buttons.value[activeIndex.value])

	watch(activeButton, updateButtonBg, { immediate: true })
	watch(() => state.value.isMenuVisible, updateButtonBg, { immediate: true })

	function updateButtonBg() {
		nextTick(() => {
			bgTop.value = activeButton.value?.$el?.offsetTop ?? 0
			bgHeight.value = activeButton.value?.$el?.offsetHeight ?? 0
		})
	}
</script>

<style lang="scss">
	.nav-tabs {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		overflow: hidden;
		padding: 1.5rem;

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			translate: 0 var(--nav-tabs-bg-top, var(--nav-tabs-px));
			z-index: -1;
			transition: all 300ms ease;
			border-radius: 8px;
			background-color: $white;
			width: calc(100% - 3px);
			height: var(--nav-tabs-bg-height, 0);

			&:before {
				position: absolute;
				top: 50%;
				right: 0px;
				translate: 50% -50%;
				rotate: 45deg;
				clip-path: polygon(0 0, 100% 100%, 100% 0);
				border: 3px solid $gray-100;
				border-bottom: none;
				border-left: none;
				border-top-right-radius: 4px;
				background-color: $white;
				width: 19px;
				height: 19px;
				pointer-events: none;
				content: '';
			}
		}

		&__controls {
			display: grid;
			position: relative;
			grid-auto-rows: minmax(0, 1fr);
			z-index: 1;
		}

		&__content {
			display: grid;
			position: relative;
			z-index: 0;
			border-radius: 8px;
			background-color: $white;
			overflow: hidden;

			> * {
				grid-row: 1;
				grid-column: 1;
			}
		}
	}
</style>
