<template>
	<div v-if="value" v-html="value" class="copy"></div>
	<div v-else-if="slots.default" class="copy">
		<slot />
	</div>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		value?: string
	}>()

	const slots = useSlots()
</script>
<style lang="scss">
	@layer components {
		.copy {
			> * + * {
				margin-top: min(var(--copy-gutter, 1.2em), 5rem);
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				position: relative;

				&:hover > a[data-anchor] {
					opacity: 1;
					pointer-events: auto;
				}
			}

			h1 {
				font-weight: 700;
				font-size: $text-2xl;
				line-height: 1.1;
			}

			h2 {
				font-weight: 700;
				font-size: $text-xl;
				line-height: 1.1;
			}

			h3 {
				font-weight: 700;
				font-size: $text-lg;
				line-height: 1.1;
			}

			h4 {
				font-weight: 500;
				font-size: $text-md;
				line-height: 1.1;
			}

			h5,
			h6 {
				font-weight: 400;
				font-size: $text-md;
				line-height: 1.1;
			}

			a {
				color: $brand;
				text-decoration: none;
				transition: all 250ms ease;

				&:hover,
				&:focus {
					color: $brand-light;
				}

				&[data-anchor] {
					pointer-events: none;
					position: absolute;
					left: 0;
					transform: translateX(-100%);
					padding-right: 10px;
					opacity: 0;
				}
			}

			.media {
				position: relative;

				iframe {
					position: absolute;
					inset: 0;
				}
			}

			.image {
				position: relative;

				img {
					display: block;
					width: 100%;
					max-width: 100%;
					height: auto;
				}

				figcaption {
					font-size: $text-sm;
					margin-top: 10px;
				}
			}
		}
	}
</style>
