<template>
	<v-checkbox
		v-model="internalValue"
		class="listbox__checkbox"
		:label="option.label"
		role="option"
		size="sm"
		@change="onChange()">
		<template #label>
			<slot name="option" v-bind="{ option }">
				{{ option.label }}
				<v-badge v-if="option.badge" size="xs" :value="option.badge" />
			</slot>
		</template>
	</v-checkbox>
</template>

<script lang="ts" setup>
	import type { ListboxOption } from './listbox.vue'

	const props = defineProps<{
		checked?: boolean
		option: ListboxOption
	}>()

	const emit = defineEmits<{
		input: [any]
	}>()

	const internalValue = ref(props.checked ?? false)
	const checked = toRef(props, 'checked')

	watch(checked, (value) => (internalValue.value = value), {
		immediate: true,
	})

	function onChange() {
		emit('input', props.option.value)
	}
</script>

<style lang="scss">
	@layer components {
		.listbox__checkbox {
			@include var-pad('listbox-option', 10px, 5px);
			min-height: 36px;

			.label__value {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
			}
		}
	}
</style>
