import { isArray } from 'lodash-es'
import type { RouteLocationNormalized } from '#vue-router'

export function getRouteSlugs(route?: RouteLocationNormalized) {
	const { params } = route || useRoute()

	if (!params.slugs) {
		return []
	}

	return (isArray(params.slugs) ? params.slugs : [params.slugs]).filter(Boolean)
}
