<template>
	<a class="listbox__btn" href="#" role="button" @click.prevent="onClick">
		<slot name="option" v-bind="{ option }">
			{{ option.label }}

			<v-badge
				v-if="option.badge"
				color="light"
				size="sm"
				:value="option.badge" />
		</slot>
	</a>
</template>

<script lang="ts" setup>
	import type { ListboxOption } from './listbox.vue'

	const props = defineProps<{
		option: ListboxOption
	}>()

	const emit = defineEmits<{
		input: [any]
	}>()

	function onClick() {
		emit('input', props.option.value)
	}
</script>

<style lang="scss">
	@layer components {
		.listbox__btn {
			@include var-pad('listbox-btn', 10px, 5px);

			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			width: 100%;
			min-height: 36px;
		}
	}
</style>
