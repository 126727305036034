export default defineNuxtRouteMiddleware((to) => {
	if (!to.fullPath.includes('checkout')) return

	const { cart } = useCommerceStore()
	const localePath = useLocalePath()

	const page = to.path.split('/').pop()
	const hasProducts = !!cart?.lineItems?.length
	const hasCustomer = !!cart?.customer
	const hasBillingAddress = !!cart?.billingAddress
	const hasShippingAddress = !!cart?.shippingAddress

	const redirect = (path: string) => navigateTo(localePath(path))
	const hasParam = (param: string) => to.query[param] !== undefined

	if (page === 'checkout') {
		if (!hasProducts) return redirect('/shop')
		if (hasCustomer) return redirect('/shop/checkout/shipping')
	}

	if (page === 'shipping') {
		if (!hasProducts) return redirect('/shop')
		if (!hasCustomer) return redirect('/shop/checkout')
		if (!hasParam('edit') && hasBillingAddress && hasShippingAddress) return redirect('/shop/checkout/payment') // prettier-ignore
	}

	if (page === 'payment') {
		if (!hasProducts) return redirect('/shop')
		if (!hasCustomer) return redirect('/shop/checkout')
		if (!hasBillingAddress || !hasShippingAddress) return redirect('/shop/checkout/shipping') // prettier-ignore
	}
})
