import type { ObjectDirective } from '@vue/runtime-core'
import './ripple.directive.scss'

export const ripple = <ObjectDirective>{
	mounted: (el, binding) => new Ripple(el, binding),
}

class Ripple {
	readonly PADDING = 0

	readonly INITIAL_ORIGIN_SCALE = 0.1

	readonly SCALE_DURATION = 300

	readonly FADE_IN_DURATION = 75

	readonly FADE_OUT_DURATION = 150

	el: HTMLElement

	options?: {
		fadeInDuration?: number
		fadeOutDuration?: number
		scaleDuration?: number
		unbounded?: boolean
	}

	get fadeInDuration() {
		return this.options?.fadeInDuration ?? this.FADE_IN_DURATION
	}

	get fadeOutDuration() {
		return this.options?.fadeOutDuration ?? this.FADE_OUT_DURATION
	}

	get scaleDuration() {
		return this.options?.scaleDuration ?? this.SCALE_DURATION
	}

	constructor(el: HTMLElement, options?: any) {
		this.el = el
		this.options = options

		this.el.style.setProperty(
			'--ripple-fade-in-duration',
			`${this.fadeInDuration}ms`,
		)

		this.el.style.setProperty(
			'--ripple-fade-out-duration',
			`${this.fadeOutDuration}ms`,
		)

		this.el.style.setProperty(
			'--ripple-scale-duration',
			`${this.scaleDuration}ms`,
		)

		this.el.addEventListener('mousedown', (e) => this.createRipple(e))
	}

	createRipple({ clientX, clientY }: MouseEvent) {
		const rect = this.el.getBoundingClientRect()
		const width = rect.width
		const height = rect.height
		const hypotenuse = Math.sqrt(width * width + height * height)
		const maxDim = Math.max(height, width)

		const maxRadius = this.options?.unbounded
			? maxDim
			: hypotenuse + this.PADDING

		const initialSize = Math.floor(maxDim * this.INITIAL_ORIGIN_SCALE)
		const finalScale = maxRadius / initialSize

		let posX = clientX - rect.left
		let posY = clientY - rect.top

		if (this.options?.unbounded) {
			posX = width * 0.5
			posY = height * 0.5
		}

		const styleX = posX - initialSize * 0.5
		const styleY = posY - initialSize * 0.5

		const moveX = width * 0.5 - posX
		const moveY = height * 0.5 - posY

		const ripple = document.createElement('div')
		ripple.classList.add('ripple')
		ripple.style.top = styleY + 'px'
		ripple.style.left = styleX + 'px'
		ripple.style.width = ripple.style.height = initialSize + 'px'
		ripple.style.setProperty('--ripple-final-scale', `${finalScale}`)
		ripple.style.setProperty('--ripple-translate-end', `${moveX}px, ${moveY}px`)

		const container = this.el.shadowRoot || this.el
		container.append(ripple)

		setTimeout(() => {
			this.removeRipple(ripple)
		}, this.scaleDuration + 100)
	}

	removeRipple(ripple: HTMLElement) {
		ripple.classList.add('ripple--fade-out')

		setTimeout(() => {
			ripple.remove()
		}, this.fadeOutDuration + 100)
	}
}
