import { isArray } from 'lodash-es'
import type { FormieConfig } from '~~/server/api/plugins/forms/[slug]'

export function useFields(config: Ref<FormieConfig | null | undefined>) {
	return computed(() => {
		if (!config.value?.pages) return []

		const pages = isArray(config.value.pages)
			? config.value.pages
			: [config.value.pages]

		return pages.flatMap((p) => p.rows?.flatMap((r) => r.fields ?? []) ?? [])
	})
}
