<template>
	<v-input-label
		class="checkbox-wrapper"
		:hidden="labelHidden"
		:position="labelPosition ?? 'right'"
		:value="label">
		<template v-if="slots.label" #label="slotProps">
			<slot name="label" v-bind="slotProps" />
		</template>

		<span
			class="checkbox"
			:class="{
				[`checkbox--${props.size}`]: true,
			}">
			<input
				v-bind="attributes"
				v-model="internalValue"
				ref="inputEl"
				:disabled="disabled"
				:name="name"
				type="checkbox"
				@change="onChange" />

			<span class="checkbox__indicator" role="presentation">
				<svg height="30" viewBox="0 0 30 30" width="30">
					<polyline
						points="4 16.487 10.392 22.729 26 7.271"
						style="
							fill: none;
							stroke-width: 5;
							stroke-linejoin: round;
							stroke-miterlimit: 10;
							stroke-linecap: round;
						" />
				</svg>
			</span>
		</span>
	</v-input-label>
</template>

<script lang="ts" setup>
	import { isEqual } from 'lodash-es'
	import { isNullOrUndefined } from '@/utilities/is-null-or-undefined'

	const props = defineProps<{
		attributes?: Record<string, any>
		disabled?: boolean
		label: string
		labelHidden?: boolean
		labelPosition?: 'top' | 'right' | 'bottom' | 'left'
		modelValue?: any
		value?: any
		name?: string
		size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
	}>()

	const emit = defineEmits<{
		'change': [Event]
		'update:modelValue': [any]
	}>()

	const slots = useSlots()
	const inputEl = ref<HTMLInputElement>()
	const internalValue = ref(false)
	const modelValue = toRef(props, 'modelValue')

	watch(
		modelValue,
		(modelValue) => {
			internalValue.value = !isNullOrUndefined(props.value)
				? isEqual(modelValue, props.value)
				: Boolean(modelValue)
		},
		{ immediate: true },
	)

	function onChange(event: Event) {
		emit('change', event)

		if (isNullOrUndefined(props.value)) {
			emit('update:modelValue', internalValue.value)
		} else {
			emit('update:modelValue', internalValue.value ? props.value : undefined)
		}
	}

	defineExpose({
		value: internalValue.value,
	})
</script>

<style lang="scss">
	@layer components {
		.checkbox-wrapper {
			--label-size: minmax(0, 1fr);
			--label-input-size: auto;

			cursor: pointer;
		}

		.checkbox {
			display: block;
			position: relative;
			cursor: pointer;

			&--xs {
				--checkbox-size: 16px;
			}

			&--sm {
				--checkbox-size: 18px;
			}

			&--md {
				--checkbox-size: 20px;
			}

			&--lg {
				--checkbox-size: 22px;
			}

			&--xl {
				--checkbox-size: 24px;
			}

			input[type='checkbox'] {
				@include sr-only();

				&:focus + .checkbox__indicator {
					--input-border-color: #{$brand-light};
					--input-box-shadow-color: #{rgba($brand-light, 0.1)};
				}

				&:checked + .checkbox__indicator svg {
					opacity: 1;
					stroke-dashoffset: 0;
				}
			}

			&__indicator {
				display: block;
				position: relative;
				transition:
					border-color 250ms,
					box-shadow 250ms;
				border: var(--input-border-width, #{$input-border-width}) solid
					var(--input-border-color, #{$input-border-color});
				border-radius: var(--input-border-radius, #{$input-border-radius});
				background-color: var(--input-bg-color, #{$white});
				width: var(--checkbox-size, 20px);
				height: var(--checkbox-size, 20px);

				svg {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transition: stroke-dashoffset 250ms ease;
					width: 75%;
					height: 75%;
					stroke-dasharray: 40;
					stroke-dashoffset: 40;
					stroke: $brand;
					opacity: 0;
				}
			}
		}
	}
</style>
