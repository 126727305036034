import mitt from 'mitt'

export type EmitterEvents = {
	'cart:open': undefined
	'cart:close': undefined
	'countries:open': undefined
	'countries:close': undefined
	'mobile-menu:open': undefined
	'mobile-menu:close': undefined
	'login:open': undefined
	'login:close': undefined
	'register:open': undefined
	'register:close': undefined
}

export default defineNuxtPlugin((nuxtApp) => {
	const emitter = mitt<EmitterEvents>()

	return {
		provide: {
			emitter,
		},
	}
})
