<template>
	<form novalidate @submit.prevent="onSubmit">
		<v-alert v-if="status" class="mb-20" :type="status.type">
			<p>{{ status.message }}</p>
		</v-alert>

		<div v-if="status?.type !== 'success'" class="flex flex-wrap gap-20">
			<v-field class="flex-full" :vuelidate="vuelidate.loginName">
				<v-input
					v-model="formData.loginName"
					:disabled="loading"
					label="Email"
					required
					type="email" />
			</v-field>

			<v-field class="flex-full" :vuelidate="vuelidate.password">
				<v-input
					v-model="formData.password"
					:disabled="loading"
					label="Password"
					required
					type="password" />
			</v-field>

			<!-- <v-field class="flex-full" :vuelidate="vuelidate.rememberMe">
				<v-checkbox
					v-model="formData.rememberMe"
					:disabled="loading"
					label="Remember me?" />
			</v-field> -->

			<v-button
				class="flex-full"
				:color="vuelidate.$anyDirty ? 'success' : 'medium'"
				fill="solid"
				:loading="loading"
				shape="round"
				size="lg"
				type="submit"
				value="Login" />
		</div>

		<div v-else class="text-center">
			<nuxt-link
				:to="localePath('/account')"
				@click="$emitter.emit('login:close')">
				View your account.
			</nuxt-link>
		</div>
	</form>
</template>

<script lang="ts" setup>
	import useVuelidate from '@vuelidate/core'
	import { email, required } from '@vuelidate/validators'

	const props = defineProps<{
		redirect?: string
	}>()

	const emit = defineEmits<{
		'form:submit': [any]
	}>()

	const { $emitter } = useNuxtApp()
	const { login } = useUserStore()
	const localePath = useLocalePath()

	const status = ref<Status | null>(null)
	const loading = ref(false)

	const formData = ref<Record<string, any>>({
		loginName: undefined,
		password: undefined,
		rememberMe: true,
	})

	const vuelidate = useVuelidate<any>(
		{
			loginName: {
				$autoDirty: true,
				required,
				email,
			},
			password: {
				$autoDirty: true,
				required,
			},
			rememberMe: {
				$autoDirty: true,
			},
		},
		formData,
	)

	async function onSubmit() {
		const isValid = await vuelidate.value.$validate()

		if (!isValid) {
			status.value = {
				type: 'error',
				message: 'Please correct the errors below and try again.',
			}
			return
		}

		loading.value = true

		const { data, error, success } = await login({
			loginName: formData.value.loginName,
			password: formData.value.password,
			rememberMe: formData.value.rememberMe,
		})

		emit('form:submit', {
			data: data,
			success: success,
		})

		if (props.redirect && success) {
			navigateTo(localePath(props.redirect))
			return
		}

		status.value = success
			? {
					type: 'success',
					message: 'Login successful.',
				}
			: {
					type: 'error',
					message: error?.message || 'Something went wrong. Please try again.',
				}

		loading.value = false
	}
</script>

<script lang="ts">
	interface Status {
		type: 'success' | 'info' | 'warning' | 'error'
		message: string
	}
</script>

<style lang="scss"></style>
