<template>
	<div class="tabs">
		<slot />
	</div>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		activeHandle: string | number
	}>()

	const emit = defineEmits<{
		activeHandle: [string | number]
	}>()

	const activeHandle = toRef(props, 'activeHandle')

	provide<ProvidedProps>('tabs', {
		activeHandle: activeHandle,
	})
</script>

<script lang="ts">
	export interface ProvidedProps {
		activeHandle: Ref<string | number>
	}
</script>

<style lang="scss">
	@layer components {
		.tabs {
			display: grid;
			grid-template-columns: minmax(0, 1fr);
			grid-template-rows: auto;

			> * {
				grid-column: 1;
				grid-row: 1;
			}
		}
	}
</style>
