<template>
	<label
		class="input-label"
		:class="{
			'input-label--empty': empty,
			'input-label--focused': focused,
			'input-label--floating': position === 'float',
			[`input-label--${position}`]: position !== 'float',
			'input-label--required': required,
		}">
		<v-sr-only v-if="hidden" :value="value" />

		<span v-if="!hidden" class="input-label__inner">
			<span class="input-label__value">
				<slot :label="value" v-bind="slots.label" name="label">
					{{ value }}
				</slot>
			</span>
		</span>

		<span class="input-label__control">
			<slot />
		</span>
	</label>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		empty?: boolean
		focused?: boolean
		hidden?: boolean
		required?: boolean
		position?: 'top' | 'right' | 'bottom' | 'left' | 'float'
		value?: string
	}>()

	const slots = useSlots()
	const empty = ref(props.empty)
	const focused = ref(props.focused)

	watch(
		() => props.empty,
		(value) => (empty.value = value),
		{ immediate: true },
	)

	watch(
		() => props.focused,
		(value) => (focused.value = value),
		{ immediate: true },
	)
</script>

<style lang="scss">
	@layer components {
		.input-label {
			--label-gap: 1rem;
			--label-size: auto;
			--label-input-size: minmax(0, 1fr);

			display: flex;
			position: relative;
			color: var(--label-color, currentColor);

			&--top,
			&--right,
			&--bottom,
			&--left {
				display: grid;
			}

			&--top {
				grid-template-rows: var(--label-size) var(--label-input-size);
				grid-template-columns: 100%;
				grid-template-areas:
					'label'
					'input';

				.input-label__inner {
					margin-bottom: var(--label-gap);
				}
			}

			&--right {
				grid-template-rows: 100%;
				grid-template-columns: var(--label-input-size) var(--label-size);
				grid-template-areas: 'input label';
				align-items: center;

				.input-label__inner {
					margin-left: var(--label-gap);
				}
			}

			&--bottom {
				grid-template-rows: var(--label-input-size) var(--label-size);
				grid-template-columns: 100%;
				grid-template-areas:
					'input'
					'label';

				.input-label__inner {
					margin-top: var(--label-gap);
				}
			}

			&--left {
				grid-template-rows: 100%;
				grid-template-columns: var(--label-size) var(--label-input-size);
				grid-template-areas: 'label input';
				align-items: center;

				.input-label__inner {
					margin-right: var(--label-gap);
				}
			}

			&--floating {
				.input-label__inner {
					position: absolute;
					top: 0;
					left: calc(
						(var(--input-px, #{$input-px}) / 2) +
							var(--input-border-width, #{$input-border-width})
					);
					z-index: 1;
					transition: border-color 0.2s;
					border-top: var(--input-border-width, #{$input-border-width}) solid
						transparent;
					padding-right: calc(var(--input-px, #{$input-px}) / 2);
					padding-left: calc(var(--input-px, #{$input-px}) / 2);
					height: 100%;
					pointer-events: none;
					font-size: var(--input-fs, #{$text-sm});
				}

				.input-label__value {
					position: relative;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					transform-origin: left center;
					opacity: 0.5;
					transition:
						font-size 0.2s,
						top 0.2s,
						opacity 0.2s;
				}

				&.input-label--focused,
				&:not(.input-label--empty) {
					.input-label__inner {
						border-color: var(--input-bg-color, #{$white});
					}

					.input-label__value {
						top: calc(var(--input-border-width, #{$input-border-width}) * -1);
						opacity: 1;
						font-size: 1rem;
					}
				}

				input,
				select {
					&::placeholder {
						color: transparent;
					}
				}
			}

			&--required {
				.input-label__value:after {
					display: block;
					border-radius: 50%;
					background-color: #{$danger};
					width: 5px;
					height: 5px;
					content: '*';
					font-size: 0;
				}
			}

			&__inner {
				position: relative;
				grid-area: label;
				cursor: pointer;
				font-size: var(--input-fs, #{$input-fs});
				line-height: 1.2;
				user-select: none;
			}

			&__value {
				display: flex;
				align-items: center;
				gap: 5px;
			}

			&__control {
				position: relative;
				grid-area: input;
				flex: 1 1 auto;
			}
		}
	}
</style>
