<template>
	<v-dropdown ref="dropdownEl" class="dropdown-listbox">
		<v-listbox
			v-model="model"
			ref="listboxEl"
			class="select-options"
			:multiselect="multiselect"
			:options="options"
			:show-apply="showApply"
			:show-filter="showFilter"
			@apply="dropdownEl?.hide()">
			<template v-if="slots.option" #option="slotProps">
				<slot name="option" v-bind="slotProps" />
			</template>
		</v-listbox>
	</v-dropdown>
</template>

<script lang="ts" setup>
	import type {
		SelectOption,
		SelectOptionGroup,
	} from '../inputs/select/select.vue'
	import Dropdown from '@/components/common/dropdown/dropdown.vue'

	const props = defineProps<{
		modelValue?: any | any[]
		multiselect?: boolean
		options: Array<SelectOption | SelectOptionGroup>
		showApply?: boolean
		showFilter?: boolean
	}>()

	const emit = defineEmits<{
		'input': [any | any[]]
		'update:modelValue': [any | any[]]
	}>()

	const slots = useSlots()
	const dropdownEl = ref<InstanceType<typeof Dropdown>>()
	const internalValue = ref(props.modelValue)

	const model = computed({
		get: () => internalValue.value,
		set: (value) => {
			internalValue.value = value
			emit('input', internalValue.value)
			emit('update:modelValue', internalValue.value)
		},
	})

	watchEffect(() => (internalValue.value = props.modelValue))

	defineExpose({
		hide: () => dropdownEl.value?.hide(),
		show: (e: Event | Element) => dropdownEl.value?.show(e),
		toggle: (e: Event | Element) => dropdownEl.value?.toggle(e),
	})
</script>

<style lang="scss">
	@layer components {
		.dropdown-listbox {
			.listbox {
				border: none;
				border-radius: 0;
				max-height: 400px;
			}
		}
	}
</style>
