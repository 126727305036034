import type { ObjectDirective } from 'vue'
import { ripple } from '@/directives/ripple/ripple.directive'

export default defineNuxtPlugin((nuxtApp) => {
	const directives: Record<string, ObjectDirective> = {
		ripple,
	}

	for (const [name, directive] of Object.entries(directives)) {
		nuxtApp.vueApp.directive(name, directive)
	}

	return {}
})
