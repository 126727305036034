import {
	email,
	helpers,
	maxValue,
	minValue,
	required,
	sameAs,
} from '@vuelidate/validators'
import { get, startCase } from 'lodash-es'
import { isEmpty } from '@/utilities/is-empty'
import type {
	AgreeField,
	FormieField,
	NumberField,
} from '~~/server/api/plugins/forms/[slug]'

export function useRules(
	fields: Ref<FormieField[]>,
	state: Ref<Record<string, any>>,
) {
	const rules = ref<Record<string, any>>({})

	rules.value = getRules(fields, state)

	watch([fields, state], () => {
		rules.value = getRules(fields, state)
	})

	return rules
}

function getRules(fields: Ref<FormieField[]>, state: Ref<Record<string, any>>) {
	const rules: Record<string, any> = {}

	fields.value.forEach((field) => {
		if (!field.rows?.length || field.useMultipleFields === false) {
			rules[field.handle] = getFieldRules(field, state)
			return
		}

		rules[field.handle] = {}

		field.rows.forEach((row) => {
			row.fields.forEach((subfield) => {
				rules[field.handle][subfield.handle] = getFieldRules(subfield, state)
			})
		})
	})

	return rules
}

function getFieldRules(field: FormieField, state: Ref<Record<string, any>>) {
	const rules: Record<string, any> = {
		$autoDirty: true,
	}

	if (field.required) {
		rules.required = helpers.withMessage('This field is required.', required)
	}

	if (field.matchField) {
		const key = field.matchField?.match(/\{field:(.*?)\}/)?.pop() ?? null

		if (key) {
			rules.sameAs = helpers.withMessage(
				`This field must match the value of "${startCase(key)}".`,
				sameAs(computed(() => get(state.value, key))),
			)
		}
	}

	if (field.required && field.type === 'verbb\\formie\\fields\\Agree') {
		rules.isChecked = helpers.withMessage(
			'This field is required.',
			sameAs((field as AgreeField).checkedValue),
		)
	}

	if (field.type === 'verbb\\formie\\fields\\Email') {
		rules.email = helpers.withMessage(
			'This field must be a valid email address.',
			email,
		)
	}

	if (field.type === 'verbb\\formie\\fields\\Number') {
		const min = (field as NumberField).min
		const max = (field as NumberField).max

		if (!isEmpty(min)) {
			rules.min = helpers.withMessage(
				`This field must be greater than ${min}.`,
				minValue(min),
			)
		}

		if (!isEmpty(max)) {
			rules.max = helpers.withMessage(
				`This field must be less than ${max}.`,
				maxValue(max),
			)
		}
	}

	if (field.handle === 'password') {
		// rules.minLength = helpers.withMessage(
		// 	'This field must be at least 6 characters long.',
		// 	minLength(6),
		// )
		// rules.containsUppercase = helpers.withMessage(
		// 	'This field must contain at least one uppercase letter.',
		// 	(value: string) => /[A-Z]/.test(value),
		// )
		// rules.containsLowercase = helpers.withMessage(
		// 	'This field must contain at least one lowercase letter.',
		// 	(value: string) => /[a-z]/.test(value),
		// )
		// rules.containsNumber = helpers.withMessage(
		// 	'This field must contain at least one number.',
		// 	(value: string) => /[0-9]/.test(value),
		// )
		// rules.containsSpecial = helpers.withMessage(
		// 	'Password must contain at least one special character.',
		// 	(value: string) => /[#?!@$%^&*-]/.test(value),
		// )
	}

	return rules
}
