<template>
	<component
		:is="component"
		ref="controlEl"
		v-bind="componentAttrs"
		class="nav-control"
		:class="{
			'nav-control--disabled': props.disabled,
		}"
		:disabled="disabled">
		<template v-if="label"> {{ label }} </template>
		<slot v-else />
	</component>
</template>

<script lang="ts" setup>
	import type { ProvidedProps } from './nav.vue'

	const props = defineProps<{
		active?: boolean
		to?: string
		type?: string
		label?: string
		disabled?: boolean
	}>()

	const providedProps = inject<ProvidedProps>('nav')

	const controlEl = ref<HTMLElement | null>(null)

	const component = computed(() => {
		return props.to ? resolveComponent('NuxtLink') : 'button'
	})

	const componentAttrs = computed(() => {
		const attrs: Record<string, any> = {
			class: {
				'nav-control--active': props.active,
			},
		}

		if (props.to) {
			attrs.activeClass = 'nav-control--active'
			attrs.exactActiveClass = 'nav-control--active-exact'
			attrs.to = props.to
		} else {
			attrs.type = props.type ? props.type : 'button'
		}

		return attrs
	})

	watch(() => props.active, onActiveChange)

	onMounted(() => onActiveChange())

	function onActiveChange() {
		if (!props.active || !controlEl.value) return

		const offset = controlEl.value.offsetLeft
		const width = controlEl.value.offsetWidth

		providedProps?.updateIndicator(offset, width)
	}
</script>

<style lang="scss">
	@layer components {
		.nav-control {
			@include var-pad('nav-control', 0, 0);

			display: flex;
			align-items: center;
			justify-content: center;
			transition:
				color 250ms ease-in-out,
				opacity 250ms ease-in-out;
			color: $gray-700;
			font-weight: 700;
			font-size: 1.4rem;
			line-height: 20px;
			text-decoration: none;
			text-transform: capitalize;
			text-align: center;
			white-space: nowrap;
			padding: 10px 0px;

			&--active {
				color: $brand;
			}

			&--disabled {
				pointer-events: none;
			}

			&:hover:not(.nav-control--active) {
				opacity: 0.75;
			}
		}
	}
</style>
