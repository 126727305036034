<template>
	<v-field :instructions="field.instructions" :vuelidate="vuelidate">
		<v-textarea
			v-model="model"
			:attributes="attrs"
			:label="field.label"
			:name="name"
			:placeholder="field.placeholder"
			:rows="4" />
	</v-field>
</template>

<script lang="ts" setup>
	import type { MultiLineTextField } from '~~/server/api/plugins/forms/[slug]'
	import {
		useFieldAttrs,
		useFieldModel,
		useFieldValidation,
	} from '../composables/helpers'

	const props = defineProps<{
		field: MultiLineTextField
		stateKey: string
	}>()

	const { attrs, name } = useFieldAttrs(props.field)

	const vuelidate = useFieldValidation(props.stateKey)

	const model = useFieldModel(props.stateKey)
</script>
