import { defineStore } from 'pinia'
import type { TreeItem } from '~/components/common/tree/tree-item.vue'
import { getLink } from '~/utilities/get-link'
import type {
	Data,
	FooterLink,
	HeaderLink,
	HeaderMegaMenu,
	HeaderMenu,
} from '~~/server/api/global'

export const useGlobalStore = defineStore('Global', () => {
	const localePath = useLocalePath()

	const globalData = ref<Data | null>(null)

	const account = computed(() => globalData.value?.account)

	const banner = computed(() => globalData.value?.banner)

	const contact = computed(() => globalData.value?.contact)

	const docsNav = computed(() => {
		const categories = globalData.value?.docsNavCategories
		const docs = globalData.value?.docsNav

		if (!categories?.length || !docs?.length) return []

		const items: TreeItem[] = []

		for (const { id, title } of categories) {
			const children = docs
				?.filter(({ categories }) => categories.some((c) => c.id === id))
				.map(({ title, uri }) => ({
					title,
					link: localePath(`/${uri}`),
				}))

			if (!children?.length) continue

			items.push({
				title,
				children,
			})
		}

		return items
	})

	const footerContent = computed(() => globalData.value?.footerContent)

	const footerNav = computed(
		() => globalData.value?.footerNav?.map((item) => getFooterLink(item)) ?? [],
	)

	const headerNav = computed(
		() =>
			globalData.value?.headerNav?.map((item) => {
				switch (item.type) {
					case 'headerMegaMenu':
						return getHeaderMegaMenu(item)

					case 'headerMenu':
						return getHeaderMenu(item)

					case 'headerLink':
						return getHeaderLink(item)
				}
			}) ?? [],
	)

	const miscellaneous = computed(() => globalData.value?.miscellaneous)

	const newsletter = computed(() => globalData.value?.newsletter)

	const popup = computed(() => globalData.value?.popup)

	const shopNav = computed(() => {
		const categories = globalData.value?.shopNav

		const items: TreeItem[] = [
			{
				title: 'Shop All',
				link: localePath('/shop'),
			},
		]

		if (!categories?.length) return items

		for (const category of categories) {
			items.push({
				title: category.title,
				link: localePath(`/${category.uri}`),
				children: category.children?.map((child) => ({
					title: child.title,
					link: localePath(`/${child.uri}`),
				})),
			})
		}

		return items
	})

	async function init() {
		const { data } = await useApi('/api/global')
		globalData.value = data.value ?? null
	}

	return {
		init,
		account,
		banner,
		contact,
		docsNav,
		footerContent,
		footerNav,
		headerNav,
		miscellaneous,
		newsletter,
		popup,
		shopNav,
	}
})

export interface Link {
	id: string
	type: 'link'
	title: string
	subtitle?: string
	url: string
	highlight: boolean
}

export interface Menu {
	id: string
	type: 'menu'
	title: string
	subtitle?: string
	icon?: {
		inline: string
		url: string
	}
	children: Link[]
}

export interface MegaMenu {
	id: string
	type: 'mega-menu'
	title: string
	subtitle?: string
	children: Menu[]
}

function getHeaderMegaMenu(item: HeaderMegaMenu) {
	return <MegaMenu>{
		id: item.id,
		type: 'mega-menu',
		title: item.title,
		children: item.children.map((menu) => getHeaderMenu(menu)),
	}
}

function getHeaderMenu(item: HeaderMenu) {
	return <Menu>{
		id: item.id,
		type: 'menu',
		title: item.title,
		subtitle: item.subtitle,
		icon: item.icon,
		children: item.children.map((link) => getHeaderLink(link)),
	}
}

function getHeaderLink(item: HeaderLink) {
	const localePath = useLocalePath()
	const link = getLink(item.link)

	return <Link>{
		id: item.id,
		type: 'link',
		title: item.title,
		subtitle: item.subtitle,
		url: link?.url ?? localePath('/'),
		highlight: item.highlightLink,
	}
}

function getFooterLink(item: FooterLink) {
	const localePath = useLocalePath()
	const link = getLink(item.link)

	return <Link>{
		id: item.id,
		type: 'link',
		title: item.title,
		url: link?.url ?? localePath('/'),
	}
}
