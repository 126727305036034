import { defineStore } from 'pinia'
import { logger } from '@/utilities/logger'

export const useUserStore = defineStore('UserStore', () => {
	const cmsUrl = useRuntimeConfig().public.cmsUrl
	const { getCart } = useCommerceStore()

	// State
	// --------------------------------------------------

	const session = ref<Session | null>(null)

	const user = ref<User | null>(null)

	const isLoggedIn = computed(() => !!session.value && !session.value.isGuest)

	// Session
	// --------------------------------------------------

	async function getSession() {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/session-info`, {
				method: 'GET',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
					session.value = response.ok ? response._data : null
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
					session.value = null
				},
			})
		} catch (error) {
			logger.error('getSession', error)
		}

		return res
	}

	async function login(payload: LoginPayload) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/login`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: payload,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('login', error)
		}

		if (res.success) {
			await getSession()
			await Promise.all([getUser(), getCart()])
		}

		return res
	}

	async function logout() {
		type Response = {
			success: boolean
		}

		const res: Response = {
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/logout`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.success = response.ok
				},
				onResponseError: () => {
					res.success = false
				},
			})

			getSession()
			getCart()
		} catch (error) {
			logger.error('logout', error)
		}

		return res
	}

	// User
	// --------------------------------------------------

	async function getUser() {
		type Response = {
			data: User | null
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		if (!session.value || session.value.isGuest) {
			res.error = { message: 'User is not logged in' }
			res.success = false

			return res
		}

		try {
			await $fetch(`${cmsUrl}/api/users/${session.value.id}`, {
				method: 'GET',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
					user.value = response.ok ? response._data : null
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
					user.value = null
				},
			})
		} catch (error) {
			logger.error('getUser', error)
		}

		return res
	}

	async function register(payload: RegisterPayload) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/save-user`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: {
					fullName: payload.fullName,
					email: payload.email,
					fields: {
						phoneNumbers: [
							{
								name: 'Phone',
								number: payload.phone,
								isDefault: true,
							},
						],
					},
					password: payload.password,
				},
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('register', error)
		}

		return res
	}

	async function saveUser(payload: SaveUserPayload) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		if (!session.value || session.value.isGuest) {
			logger.error('saveUser', 'User is not logged in')

			res.error = { message: 'User is not logged in' }
			res.success = false

			return res
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/save-user`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: {
					userId: session.value.id,
					fullName: payload.fullName,
					email: payload.email,
					fields: {
						phoneNumbers: [
							{
								name: 'Phone',
								number: payload.phone,
								isDefault: true,
							},
						],
					},
					password: payload.password,
					resetPassword: payload.resetPassword,
				},
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('saveUser', error)
		}

		return res
	}

	async function requestPasswordReset(payload: RequestPasswordResetPayload) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/send-password-reset-email`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: payload,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('login', error)
		}

		return res
	}

	async function verifyEmailAddress(payload: VerifyEmailAddressPayload) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/verify-email`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: payload,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					console.log(response)

					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					console.log(response)

					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('login', error)
		}

		if (res.success) {
			await getSession()
		}

		return res
	}

	async function resetPassword(payload: ResetPasswordPayload) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/set-password`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: payload,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('login', error)
		}

		return res
	}

	// Addresses
	// --------------------------------------------------

	async function getAddresses() {
		type Response = {
			data: Address[]
			error: any
			success: boolean
		}

		const res: Response = {
			data: [],
			error: null,
			success: false,
		}

		if (!session.value || session.value.isGuest) {
			logger.error('getAddresses', 'User is not logged in')

			res.error = { message: 'User is not logged in' }
			res.success = false

			return res
		}

		try {
			await $fetch(`${cmsUrl}/api/users/${session.value.id}/addresses`, {
				method: 'GET',
				// cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data.data : []
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('getAddresses', error)
		}

		return res
	}

	async function saveAddress(payload: Address) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		if (!session.value || session.value.isGuest) {
			logger.error('saveAddress', 'User is not logged in')

			res.error = { message: 'User is not logged in' }
			res.success = false

			return res
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/save-address`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: {
					...payload,
					addressId: payload.id,
					userId: session.value.id,
				},
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data.model : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('saveAddress', error)
		}

		return res
	}

	async function deleteAddress(id: string | number) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		if (!session.value || session.value.isGuest) {
			logger.error('deleteAddress', 'User is not logged in')

			res.error = { message: 'User is not logged in' }
			res.success = false

			return res
		}

		try {
			await $fetch(`${cmsUrl}/actions/users/delete-address`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: {
					addressId: id,
				},
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error) {
			logger.error('deleteAddress', error)
		}

		return res
	}

	// Orders
	// --------------------------------------------------

	async function getOrder(orderId: string | number) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		if (!session?.value || session.value.isGuest) {
			logger.error('getOrder', 'User is not logged in')

			res.error = { message: 'User is not logged in' }
			res.success = false

			return res
		}

		try {
			await $fetch(
				`${cmsUrl}/api/users/${session.value.id}/orders/${orderId}`,
				{
					method: 'GET',
					// cache: 'no-cache',
					credentials: 'include',
					headers: {
						Accept: 'application/json',
					},
					onResponse: ({ response }) => {
						res.data = response.ok ? response._data : null
						res.error = response.ok ? null : response._data
						res.success = response.ok
					},
					onResponseError: ({ response }) => {
						res.success = false
						res.error = response._data
					},
				},
			)
		} catch (error: any) {
			logger.error('getOrder', error)
		}

		return res
	}

	async function getOrders() {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		if (!session.value || session.value.isGuest) {
			logger.error('getAddresses', 'User is not logged in')

			res.error = { message: 'User is not logged in' }
			res.success = false

			return res
		}

		try {
			await $fetch(`${cmsUrl}/api/users/${session.value.id}/orders`, {
				method: 'GET',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					Accept: 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error: any) {
			logger.error('getOrders', error)
		}

		return res
	}

	return {
		deleteAddress,
		getAddresses,
		getOrder,
		getOrders,
		getSession,
		getUser,
		isLoggedIn,
		login,
		logout,
		resetPassword,
		register,
		requestPasswordReset,
		saveAddress,
		saveUser,
		session,
		user,
		verifyEmailAddress,
	}
})

export interface Address {
	id: string | number
	title: string
	fullName?: string
	organization?: string
	addressLine1?: string
	addressLine2?: string
	addressLine3?: string
	dependentLocality?: string
	locality?: string
	administrativeArea?: string
	postalCode?: string
	countryCode?: string
}

export interface LoginPayload {
	loginName: string
	password: string
	rememberMe: 1 | 0
}

export interface RequestPasswordResetPayload {
	loginName: string
}

export interface RegisterPayload {
	email: string
	fullName: string
	password: string
	phone?: string
}

export interface SaveUserPayload {
	email: string
	fullName: string
	resetPassword?: string
	password: string
	phone?: string
}

export type Session =
	| {
			isGuest: true
	  }
	| {
			isGuest: false
			id: number
			uid: string
			email: string
			username: string
			timeout: number
	  }

export interface User {
	id: number
	fullName: string
	email: string
	username: string
	phoneNumbers: {
		name: string
		number: string
		isDefault: boolean
	}[]
}

export interface VerifyEmailAddressPayload {
	id: string
	code: string
}

export interface ResetPasswordPayload {
	id: string
	code: string
	newPassword: string
}
