export interface Site {
	id: number
	name: string
	shortName: string
	code: string
	path: string
	iso: string
	currency: string
}

export const SITES: Site[] = [
	{
		id: 1,
		name: 'New Zealand',
		shortName: 'NZ',
		code: 'nz',
		path: 'nz',
		iso: 'en-NZ',
		currency: 'NZD',
	},
	{
		id: 2,
		name: 'Australia',
		shortName: 'Aus',
		code: 'au',
		path: 'au',
		iso: 'en-AU',
		currency: 'AUD',
	},
	{
		id: 3,
		name: 'United Kingdom',
		shortName: 'UK',
		code: 'uk',
		path: 'uk',
		iso: 'en-GB',
		currency: 'GBP',
	},
	{
		id: 4,
		name: 'United States',
		shortName: 'USA',
		code: 'us',
		path: 'us',
		iso: 'en-US',
		currency: 'USD',
	},
]
