<template>
	<button
		aria-controls="mobile-menu"
		aria-haspopup="true"
		aria-label="Open navigation menu"
		class="hamburger"
		type="button"
		@click="$emitter.emit('mobile-menu:open')">
		<span aria-hidden="true"></span>
	</button>
</template>

<script lang="ts" setup>
	const { $emitter } = useNuxtApp()
</script>

<style lang="scss">
	.hamburger {
		position: relative;
		height: 100%;

		> span {
			display: block;
			position: relative;
			background-color: $brand;
			width: 35px;
			height: 2px;

			&::before,
			&::after {
				position: absolute;
				left: 0;
				transition: transform 300ms ease;
				will-change: transform;
				background-color: $brand;
				width: 100%;
				height: 100%;
				content: '';
			}

			&::before {
				top: -7px;
			}

			&::after {
				top: 7px;
			}
		}

		&:hover {
			> span {
				background-color: $brand;

				&::before {
					transform: translateY(-2px);
				}

				&::after {
					transform: translateY(2px);
				}
			}
		}
	}
</style>
