<template>
	<div class="bg-white relative container">
		<div
			class="align-items-center border-t border-gray-300 flex flex-wrap gap-20 min-h-70 justify-content-between lg:min-h-80">
			<div class="flex-auto md:flex-1/3">
				<nuxt-link
					:aria-label="$t('header.home')"
					class="text-brand"
					:to="localePath('/')">
					<icon class="h-50 w-auto sm:hidden" name="tepari:logo" />
					<icon class="h-50 w-auto lt-sm:hidden" name="tepari:logo-wide" />
				</nuxt-link>
			</div>

			<div
				v-if="contact?.phone"
				class="hidden flex-1/3 text-center md:align-items-center md:flex md:justify-content-center">
				<span class="font-bold mr-10 text-sm">
					{{ $t('contactBar.phoneLabel') }}:
				</span>

				<v-phone
					class="block font-bold text-brand text-md hover:text-brand-light"
					itemprop="telephone"
					:value="contact.phone" />
			</div>

			<div class="flex-auto md:flex-1/3">
				<v-button
					:is="nuxtLink"
					class="block ml-auto"
					color="success"
					fill="solid"
					shape="round"
					size="sm"
					:to="localePath('/contact')">
					{{ $t('contactBar.buttonLabel') }}
				</v-button>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	const { contact } = storeToRefs(useGlobalStore())

	const localePath = useLocalePath()

	const nuxtLink = resolveComponent('NuxtLink')
</script>

<style lang="scss"></style>
