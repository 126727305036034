<template>
	<form novalidate @submit.prevent="onSubmit">
		<v-alert v-if="status" class="mb-20" :type="status.type">
			<p>{{ status.message }}</p>
		</v-alert>

		<div v-if="status?.type !== 'success'" class="space-y-20">
			<v-field :vuelidate="vuelidate.newPassword">
				<v-input
					v-model="formData.newPassword"
					:disabled="loading"
					label="New Password"
					required
					type="password" />
			</v-field>

			<v-field :vuelidate="vuelidate.repeatPassword">
				<v-input
					v-model="formData.repeatPassword"
					:disabled="loading"
					label="Repeat Password"
					required
					type="password" />
			</v-field>

			<v-button
				class="w-full"
				:color="vuelidate.$anyDirty ? 'success' : 'medium'"
				fill="solid"
				:loading="loading"
				shape="round"
				size="lg"
				type="submit"
				value="Reset Password" />
		</div>
	</form>
</template>

<script lang="ts" setup>
	import useVuelidate from '@vuelidate/core'
	import { helpers, requiredIf, sameAs } from '@vuelidate/validators'

	const props = defineProps<{
		redirect?: string
	}>()

	const emit = defineEmits<{
		'form:submit': [any]
	}>()

	const { resetPassword } = useUserStore()

	const { getUrlParam } = useRouterUtils()

	const localePath = useLocalePath()

	const status = ref<Status | null>(null)

	const loading = ref(false)

	const formData = ref<Record<string, any>>({
		newPassword: '',
		repeatPassword: '',
	})

	const vuelidate = useVuelidate<any>(
		{
			newPassword: {
				$autoDirty: true,
				required: requiredIf(() => !!formData.value?.changePassword),
			},
			repeatPassword: {
				$autoDirty: true,
				required: requiredIf(() => !!formData.value?.changePassword),
				sameAs: helpers.withMessage(
					`This field must match "New Password".`,
					sameAs(computed(() => formData.value.newPassword)),
				),
			},
		},
		formData,
	)

	async function onSubmit() {
		const isValid = await vuelidate.value.$validate()
		const id = getUrlParam('id')?.toString()
		const code = getUrlParam('code')?.toString()

		if (!isValid) {
			status.value = {
				type: 'error',
				message: 'Please correct the errors below and try again.',
			}
			return
		}

		if (!id || !code) {
			status.value = {
				type: 'error',
				message: 'Invalid reset password link.',
			}
			return
		}

		loading.value = true

		const { data, error, success } = await resetPassword({
			id,
			code,
			newPassword: formData.value.newPassword,
		})

		emit('form:submit', {
			data: data,
			success: success,
		})

		if (props.redirect && success) {
			navigateTo(localePath(props.redirect))
			return
		}

		status.value = success
			? {
					type: 'success',
					message: 'Your password has been reset.',
				}
			: {
					type: 'error',
					message: error?.message || 'Something went wrong. Please try again.',
				}

		loading.value = false
	}
</script>

<script lang="ts">
	interface Status {
		type: 'success' | 'info' | 'warning' | 'error'
		message: string
	}
</script>

<style lang="scss"></style>
