<template>
	<div v-if="banner?.enableBanner && !hide" class="notification-banner">
		<p>
			{{ banner?.copy }}

			<nuxt-link
				v-if="link && !link.isEmpty"
				class="notification-banner__link"
				:to="link.isElement ? localePath(`/${link?.element?.uri}`) : link.url">
				{{ link.linkText ?? 'Read more.' }}
			</nuxt-link>
		</p>

		<button
			aria-title="close"
			class="notification-banner__close"
			type="button"
			@click="hide = true">
			<icon class="text-white" name="tepari:cross" size="16px" />
		</button>
	</div>
</template>

<script lang="ts" setup>
	const { banner } = storeToRefs(useGlobalStore())

	const localePath = useLocalePath()

	const hide = ref(false)

	const link = computed(() => banner.value?.link?.at(0) ?? null)
</script>

<style lang="scss">
	.notification-banner {
		position: relative;
		color: $white;
		background-color: $brand;
		line-height: 2rem;
		font-size: 1.1rem;
		padding: 5px 40px;
		text-align: center;
		font-weight: 500;

		&__link {
			text-decoration: underline;
			text-underline-offset: 3px;
		}

		&__close {
			position: absolute;
			right: 10px;
			top: 0;
			width: 30px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>
