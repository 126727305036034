<template>
	<div ref="layout" class="app">
		<nuxt-loading-indicator color="#008fd5" :height="4" />
		<vite-pwa-manifest />

		<div class="app__body">
			<div class="app__header">
				<v-notification-banner />
				<v-header />
			</div>

			<nuxt-layout class="app__content">
				<nuxt-page />
			</nuxt-layout>

			<v-contact-bar class="app__contact" />
		</div>

		<div ref="footer" class="app__footer">
			<v-footer />
		</div>

		<v-account-drawer />
		<v-cart-drawer />
		<v-country-picker />
		<v-mobile-menu />
		<v-popup-advert />
	</div>
</template>

<script lang="ts" setup>
	const { getCart } = useCommerceStore()
	const { getSession, getUser, verifyEmailAddress } = useUserStore()
	const { getUrlParam, setUrlParams } = useRouterUtils()
	const global = useGlobalStore()
	const i18nHead = useLocaleHead()

	const footer = ref<HTMLElement>(null!)
	const layout = ref<HTMLElement>(null!)

	await global.init()

	useHead({
		htmlAttrs: {
			lang: i18nHead.value.htmlAttrs?.lang ?? 'en',
		},
	})

	useResizeObserver(layout, () => {
		const footerHeight = footer.value.clientHeight
		const windowHeight = window.innerHeight

		if (footerHeight < windowHeight) {
			footer.value.classList.add('app__footer--sticky')
		} else {
			footer.value.classList.remove('app__footer--sticky')
		}
	})

	onMounted(async () => {
		const action = getUrlParam('action')?.toString()

		if (action === 'activate') {
			await activateUserAccount()
		}

		await getSession()

		await Promise.all([getUser(), getCart()])
	})

	async function activateUserAccount() {
		const id = getUrlParam('id')?.toString()
		const code = getUrlParam('code')?.toString()

		if (!id || !code) return

		await verifyEmailAddress({
			id,
			code,
		})

		setUrlParams({
			action: null,
			id: null,
			code: null,
		})
	}
</script>

<style lang="scss">
	.app {
		position: relative;
		height: 100%;

		&__body {
			position: relative;
			z-index: 1;
			display: grid;
			grid-template-rows: auto minmax(0, 1fr) auto;
			grid-template-columns: 100%;
			grid-template-areas: 'header' 'content' 'contact';
			box-shadow: 0px 4px 10px rgba($black, 0.15);
			background-color: $white;
			min-height: 100vh;
			min-height: 100dvh;
		}

		&__header {
			grid-area: header;
		}

		&__content {
			grid-area: content;
		}

		&__contact {
			grid-area: contact;
		}

		&__footer {
			position: relative;
			z-index: 0;

			&--sticky {
				position: sticky;
				bottom: 0;
				left: 0;
				width: 100%;
			}
		}
	}
</style>
