import { useSiteStore } from '@/stores/site'

export function formatCurrency(value?: string | number) {
	const { currentSite } = useSiteStore()
	const { currency, iso } = currentSite || {}

	return value?.toLocaleString(iso, {
		currency,
		style: 'currency',
	})
}
