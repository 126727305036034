export function getTwicPicsUrl(src?: string) {
	if (!src) return ''

	const map = {
		'/': 'public/',
		'https://i.ytimg.com/vi/': 'ytimg/',
		'https://storage.googleapis.com/cms.tepari.com/': 'cms/',
	}

	for (const [key, value] of Object.entries(map)) {
		if (src.startsWith(key)) {
			src = src.replace(key, value)
		}
	}

	return `https://f7gh3hsz.twic.pics/images/${src}`
}
