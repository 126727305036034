<template>
	<v-popover
		ref="popoverEl"
		class="dropdown"
		:placement="placement ?? 'bottom-start'"
		:strategy="strategy ?? 'fixed'">
		<template #default="{ isVisible }">
			<div v-show="isVisible" class="dropdown__content">
				<header v-if="slots.header" class="dropdown__header">
					<slot name="header" />
				</header>

				<div class="dropdown__body">
					<slot />
				</div>

				<footer v-if="slots.footer" class="dropdown__footer">
					<slot name="footer" />
				</footer>
			</div>
		</template>
	</v-popover>
</template>

<script lang="ts" setup>
	import type { Placement, Strategy } from '@floating-ui/vue'
	import Popover from '@/components/common/popover.vue'

	const props = defineProps<{
		placement?: Placement
		strategy?: Strategy
	}>()

	const slots = useSlots()

	const popoverEl = ref<InstanceType<typeof Popover>>()

	defineExpose({
		hide: () => popoverEl.value?.hide(),
		show: (e: Event | Element) => popoverEl.value?.show(e),
		toggle: (e: Event | Element) => popoverEl.value?.toggle(e),
	})
</script>

<style lang="scss">
	@layer components {
		.dropdown {
			&__content {
				box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.15);
				border: 1px solid $gray-300;
				border-radius: 5px;
				background-color: $white;
				overflow: hidden;

				&.v-enter-active,
				&.v-leave-active {
					transform-origin: top center;
					transition: 275ms ease;
					transition-property: opacity, transform;
				}

				&.v-enter-from,
				&.v-leave-to {
					transform: scaleY(0.9);
					opacity: 0;
				}
			}

			&__header,
			&__body,
			&__footer {
				overflow: hidden;

				&:first-child {
					border-top-right-radius: inherit;
					border-top-left-radius: inherit;
				}

				&:last-child {
					border-bottom-right-radius: inherit;
					border-bottom-left-radius: inherit;
				}
			}
		}
	}
</style>
