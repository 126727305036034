<template>
	<v-fieldset :label="label">
		<div class="radio-list">
			<v-radio
				v-for="option in options"
				:key="option.label"
				:attributes="attributes"
				:checked="isEqual(option.value, internalValue)"
				:disabled="option.disabled"
				:label="option.label"
				:name="name"
				:value="option.value"
				@change="onChange" />
		</div>
	</v-fieldset>
</template>

<script lang="ts" setup>
	import { isEqual } from 'lodash-es'

	const props = defineProps<{
		attributes?: Record<string, any>
		label?: string
		modelValue?: unknown
		name: string
		options: RadioOption[]
	}>()

	const emit = defineEmits<{
		'change': [Event]
		'update:modelValue': [unknown]
	}>()

	const internalValue = ref(props.modelValue)
	const modelValue = toRef(props, 'modelValue')

	watch(modelValue, (value) => (internalValue.value = value), {
		immediate: true,
	})

	function onChange(event: Event, value: unknown) {
		internalValue.value = value
		emit('change', event)
		emit('update:modelValue', internalValue.value)
	}
</script>

<script lang="ts">
	export interface RadioOption<T = any> {
		disabled?: boolean
		label: string
		value: T
	}
</script>

<style lang="scss">
	@layer components {
		.radio-list {
			display: grid;
			gap: 12px;
		}
	}
</style>
