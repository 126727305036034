<template>
	<nav
		ref="navEl"
		class="flex gap-20 relative sm:gap-40"
		:style="{
			'--nav-indicator-offset': indicatorPos.offset + 'px',
			'--nav-indicator-width': indicatorPos.width + 'px',
		}">
		<div ref="indicatorEl" aria-hidden="true" class="indicator"></div>
		<slot />
	</nav>
</template>

<script lang="ts" setup>
	const navEl = ref<HTMLElement | null>(null)
	const indicatorEl = ref<HTMLElement | null>(null)

	const indicatorPos = ref({
		offset: 0,
		width: 0,
	})

	useResizeObserver(navEl, () => {
		if (!navEl.value || !indicatorEl.value) return

		const activeControl = navEl.value.querySelector<HTMLElement>('.nav-control--active') // prettier-ignore

		if (!activeControl) return

		indicatorEl.value.style.transition = 'none'

		updateIndicator(activeControl.offsetLeft, activeControl.offsetWidth)

		indicatorEl.value.style.transition = ''
	})

	function updateIndicator(offset: number, width: number) {
		indicatorPos.value.offset = offset
		indicatorPos.value.width = width
	}

	provide<ProvidedProps>('nav', {
		updateIndicator,
	})
</script>

<script lang="ts">
	export interface ProvidedProps {
		updateIndicator: (offset: number, width: number) => void
	}
</script>

<style lang="scss" scoped>
	@layer components {
		.indicator {
			position: absolute;
			bottom: -1px;
			left: 0;
			transform: translateX(var(--nav-indicator-offset, 0));
			transition: all 0.3s ease-out;
			background-color: $brand;
			width: var(--nav-indicator-width, 0);
			height: var(--nav-indicator-height, 2px);
		}
	}
</style>
