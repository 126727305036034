import type { NitroFetchRequest } from 'nitropack'

export function useApi<T extends NitroFetchRequest = NitroFetchRequest>(
	request: T,
) {
	const { currentSite } = useSiteStore()
	const { getUrlParam } = useRouterUtils()

	const params: Record<string, string> = {
		'site-id': `${currentSite.id}`,
	}

	const livePreview = getUrlParam('x-craft-live-preview')?.toString()
	const preview = getUrlParam('x-craft-preview')?.toString()
	const token = getUrlParam('token')?.toString()

	if (livePreview) params['x-craft-live-preview'] = livePreview
	if (preview) params['x-craft-preview'] = preview
	if (token) params['token'] = token

	return useFetch(request, {
		params,
	})
}
