<template>
	<transition
		name="collapse"
		@after-enter="removeHeight()"
		@after-leave="removeHeight()"
		@before-enter="updateHeight()"
		@before-leave="updateHeight()">
		<div
			v-show="active"
			:id="`${id}-content`"
			ref="collapse"
			:aria-hidden="!active"
			:aria-labelledby="`${id}-title`"
			class="collapse-content"
			role="region">
			<slot v-bind="{ active, id }" />
		</div>
	</transition>
</template>

<script lang="ts" setup>
	import type { ProvidedProps } from './collapse.vue'

	const { active, id } = inject('collapse') as ProvidedProps

	const collapse = ref<HTMLElement | null>(null)

	function updateHeight() {
		if (!collapse.value) return

		const el = collapse.value
		const display = el.style.display

		el.style.removeProperty('--collapse-height')
		el.style.removeProperty('display')

		const height = el.offsetHeight

		el.style.setProperty('--collapse-height', `${height}px`)
		el.style.setProperty('display', display)
	}

	function removeHeight() {
		collapse.value?.style.removeProperty('--collapse-height')
	}
</script>

<style lang="scss">
	@layer components {
		.collapse-content {
			will-change: height;
			overflow: hidden;

			&.collapse-enter-active,
			&.collapse-leave-active {
				transition: 200ms ease;
				transition-property: height;
			}

			&.collapse-leave-to,
			&.collapse-enter-from {
				height: 0;
			}

			&.collapse-enter-to,
			&.collapse-leave-from {
				height: var(--collapse-height);
			}
		}
	}
</style>
