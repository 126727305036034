import { defineStore } from 'pinia'

export const useDialogStore = defineStore('Dialog', () => {
	const activeDrawers = ref<string[]>([])
	const activeModals = ref<string[]>([])

	const drawerCount = computed(() => activeDrawers.value.length)
	const modalCount = computed(() => activeModals.value.length)

	function addDialog(id: string, type: 'modal' | 'drawer') {
		const collection = type === 'modal' ? activeModals : activeDrawers
		collection.value.push(id)
	}

	function removeDialog(id: string, type: 'modal' | 'drawer') {
		const collection = type === 'modal' ? activeModals : activeDrawers
		const index = collection.value.indexOf(id)

		if (index > -1) {
			collection.value.splice(index, 1)
		}
	}

	function getDialogIndex(id: string, type: 'modal' | 'drawer') {
		const collection = type === 'modal' ? activeModals : activeDrawers
		return collection.value.indexOf(id)
	}

	return {
		addDialog,
		getDialogIndex,
		removeDialog,
		drawerCount,
		modalCount,
	}
})
