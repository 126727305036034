<template>
	<div ref="el" class="select-wrapper">
		<div class="select">
			<v-input
				:attributes="attributes"
				:disabled="disabled"
				:hidden="labelHidden"
				:label="label"
				:model-value="displayValue"
				:position="'float'"
				:readonly="true"
				:required="required"
				@click="onClick"
				@keydown.enter.prevent="onClick"
				@keydown.space.prevent="onClick" />

			<div class="select__overlay">
				<v-button
					v-if="showClear && displayValue"
					class="select__clear"
					color="primary"
					:disabled="disabled"
					fill="clear"
					icon="tepari:cross"
					shape="round"
					size="xs"
					type="button"
					@click="onClear" />

				<icon v-else class="select__icon" name="tepari:caret-down" />
			</div>
		</div>

		<v-dropdown-listbox
			v-model="model"
			ref="dropdownEl"
			:boundary="el"
			:multiselect="multiselect"
			:offset="{
				mainAxis: -1,
			}"
			:options="options"
			:show-apply="showApply"
			:show-filter="showFilter"
			strategy="absolute"
			@apply="dropdownEl?.hide()">
			<template v-if="slots.option" #option="slotProps">
				<slot name="option" v-bind="slotProps" />
			</template>
		</v-dropdown-listbox>
	</div>
</template>

<script lang="ts" setup>
	import { isArray, isEqual } from 'lodash-es'
	import type {
		ListboxOption,
		ListboxOptionGroup,
	} from '../listbox/listbox.vue'
	import Dropdown from '@/components/common/dropdown/dropdown.vue'

	const props = defineProps<{
		attributes?: Record<string, any>
		disabled?: boolean
		label: string
		labelHidden?: boolean
		modelValue?: any | any[]
		multiselect?: boolean
		options: Array<SelectOption | SelectOptionGroup>
		placeholder?: string
		required?: boolean
		showApply?: boolean
		showClear?: boolean
		showFilter?: boolean
	}>()

	const emit = defineEmits<{
		'clear': []
		'input': [any]
		'update:modelValue': [any]
	}>()

	const slots = useSlots()
	const dropdownEl = ref<InstanceType<typeof Dropdown>>()
	const el = ref<HTMLElement>()
	const internalValue = ref(props.modelValue)

	const optionValues = computed(() =>
		props.options.flatMap((item) => {
			if ('options' in item) return item.options
			else return item
		}),
	)

	const displayValue = computed(() => {
		if (!internalValue.value) return

		if (!props.multiselect) {
			const option = optionValues.value.find((item) => {
				return isEqual(item.value, internalValue.value)
			})

			return option?.label
		}

		const options = optionValues.value.filter((item) => {
			if (!isArray(internalValue.value)) return false
			return internalValue.value!.some((value) => isEqual(value, item.value))
		})

		return options.map((item) => item.label).join(', ')
	})

	const model = computed({
		get: () => internalValue.value,
		set: (value) => {
			internalValue.value = value
			emit('input', internalValue.value)
			emit('update:modelValue', internalValue.value)

			if (!props.multiselect) {
				dropdownEl.value?.hide()
			}
		},
	})

	watchEffect(() => (internalValue.value = props.modelValue))

	function onClick() {
		if (props.disabled) return
		if (!el.value) return

		dropdownEl.value?.toggle(el.value)
	}

	function onClear() {
		internalValue.value = props.multiselect ? [] : undefined
		emit('clear')
		emit('update:modelValue', internalValue.value)
	}

	defineExpose({
		clear: onClear,
		value: internalValue,
	})
</script>

<script lang="ts">
	export type SelectOption<T = any> = ListboxOption<T>
	export type SelectOptionGroup<T = any> = ListboxOptionGroup<T>
</script>

<style lang="scss">
	@layer components {
		.select-wrapper {
			position: relative;

			.dropdown {
				width: 100%;
			}
		}

		.select {
			position: relative;

			input {
				cursor: pointer;
				padding-right: 36px;
				user-select: none;
			}

			&__overlay {
				display: grid;
				position: absolute;
				top: 0;
				right: 0;
				place-items: center;
				min-width: 36px;
				height: 100%;
				pointer-events: none;

				> * {
					grid-row: 1;
					grid-column: 1;
				}
			}

			&__clear {
				pointer-events: auto;
			}

			&__icon {
				width: 12px;
				color: $brand;
			}
		}
	}
</style>
