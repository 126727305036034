<template>
	<a
		:id="`${id}-title`"
		:aria-controls="`${id}-content`"
		class="collapse-trigger"
		href="#"
		role="button"
		@click.prevent="onClick">
		<slot v-bind="{ active, id }" />
	</a>
</template>

<script lang="ts" setup>
	import type { ProvidedProps } from './collapse.vue'

	const { active, id } = inject('collapse') as ProvidedProps

	const emit = defineEmits<{
		toggle: [boolean]
	}>()

	function onClick() {
		active.value = !active.value
		emit('toggle', active.value)
	}
</script>

<style lang="scss">
	@layer components {
		.collapse-trigger {
			color: var(--collapse-trigger-color, currentColor);
			display: block;
			width: 100%;
		}
	}
</style>
