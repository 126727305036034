<template>
	<v-dialog ref="drawerEl" class="account-drawer" type="drawer">
		<template #header>
			<v-title
				class="h2 text-center"
				tag="p"
				:value="account?.accountTitle || 'Account'" />

			<v-copy class="text-center" :value="account?.accountCopy" />

			<v-nav class="mt-40">
				<v-nav-control
					:active="activeTab === 'login'"
					class="flex-grow-1"
					label="Login"
					@click="activeTab = 'login'" />

				<v-nav-control
					:active="activeTab === 'register'"
					class="flex-grow-1"
					label="Register"
					@click="activeTab = 'register'" />
			</v-nav>
		</template>

		<template #body>
			<v-tabs :active-handle="activeTab">
				<v-tab handle="login">
					<template v-if="resetPassword">
						<v-reset-password-form />

						<a
							class="block mt-20 text-center text-xs"
							href="#"
							role="button"
							@click.prevent="resetPassword = false">
							Back to login
						</a>
					</template>

					<template v-else-if="forgotPassword">
						<v-request-password-reset-form />

						<a
							class="block mt-20 text-center text-xs"
							href="#"
							role="button"
							@click.prevent="forgotPassword = false">
							Back to login
						</a>
					</template>

					<template v-else>
						<v-login-form />

						<a
							class="block mt-20 text-center text-xs"
							href="#"
							role="button"
							@click.prevent="forgotPassword = true">
							Forgot your password?
						</a>
					</template>
				</v-tab>

				<v-tab handle="register">
					<v-register-form />
				</v-tab>
			</v-tabs>
		</template>
	</v-dialog>
</template>

<script lang="ts" setup>
	import Dialog from '~/components/common/dialog/dialog.vue'

	const { account } = storeToRefs(useGlobalStore())

	const { $emitter } = useNuxtApp()

	const { getUrlParam } = useRouterUtils()

	const activeTab = ref('login')

	const drawerEl = ref<InstanceType<typeof Dialog>>()

	const resetPassword = ref(false)

	const forgotPassword = ref(false)

	$emitter.on('login:open', () => {
		activeTab.value = 'login'
		drawerEl.value?.open()
	})

	$emitter.on('login:close', () => {
		drawerEl.value?.close()
	})

	$emitter.on('register:open', () => {
		activeTab.value = 'register'
		drawerEl.value?.open()
	})

	$emitter.on('register:close', () => {
		drawerEl.value?.close()
	})

	onMounted(() => {
		const action = getUrlParam('action')?.toString()
		const userId = getUrlParam('id')?.toString()
		const code = getUrlParam('code')?.toString()

		if (action === 'reset-password' && userId && code) {
			activeTab.value = 'login'
			resetPassword.value = true
			drawerEl.value?.open()
		}
	})
</script>

<style lang="scss">
	.account-drawer {
		--dialog-header-pb: 0;
	}
</style>
