<template>
	<component
		:is="tag ?? 'h2'"
		v-if="value || slots.default"
		:id="slug"
		class="title">
		<a
			v-if="enableAnchor"
			:aria-label="`Link to ${value}`"
			class="title__anchor"
			:href="`#${slug}`"></a>

		<span v-if="value" v-html="value"></span>
		<slot v-else />
	</component>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		enableAnchor?: boolean
		slug?: string
		tag?: string
		value?: string
	}>()

	const slots = useSlots()
</script>

<style lang="scss">
	@layer components {
		.title {
			position: relative;
			scroll-margin-top: var(--title-scroll-margin-top, 50px);

			&__anchor {
				content: '#';
				position: absolute;
				top: 0;
				left: 0;
				transform: translateX(-100%);
				transition: opacity 0.2s;
				padding-right: 10px;
				opacity: 0;
				outline: none;
				transition: opacity 250ms ease;
				color: $brand;

				&:hover {
					text-decoration: none;
				}
			}

			&:hover &__anchor,
			&:focus-within &__anchor {
				opacity: 1;
			}

			strong {
				color: var(--title-accent-color, #{$gray-500});
			}
		}
	}
</style>
