<template>
	<v-field
		class="formie-field-agree"
		:instructions="field.instructions"
		:label="field.label"
		:vuelidate="vuelidate">
		<v-checkbox
			v-model="inputValue"
			:attributes="attrs"
			:label="field.label"
			:name="name" />

		<v-copy
			v-if="field.descriptionHtml"
			class="mt-5 pl-30"
			:value="field.descriptionHtml" />
	</v-field>
</template>

<script lang="ts" setup>
	import type { AgreeField } from '~~/server/api/plugins/forms/[slug]'
	import {
		useFieldAttrs,
		useFieldModel,
		useFieldValidation,
	} from '../composables/helpers'

	const props = defineProps<{
		field: AgreeField
		stateKey: string
	}>()

	const { attrs, name } = useFieldAttrs(props.field)

	const vuelidate = useFieldValidation(props.stateKey)

	const model = useFieldModel(props.stateKey)

	const inputValue = ref(model.value === props.field.checkedValue)

	watch(inputValue, () => {
		model.value = inputValue.value
			? props.field.checkedValue
			: props.field.uncheckedValue
	})
</script>

<style lang="scss" scoped>
	.formie-field-agree {
		--input-pl: 30px;
	}
</style>
