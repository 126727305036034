import { isArray, isPlainObject } from 'lodash-es'
import { isNullOrUndefined } from './is-null-or-undefined'
import { isString } from './is-string'

/**
 * Checks if a value is empty.
 * @param value The value to check.
 * @return Returns true if the value is empty, false otherwise.
 * @example
 * [] => true
 * {} => true
 * '' => true
 * null => true
 * undefined => true
 */
export function isEmpty(value: any) {
	if (isArray(value)) {
		return value.length === 0
	}

	if (isPlainObject(value)) {
		return Object.keys(value).length === 0
	}

	if (isString(value)) {
		return value.trim() === ''
	}

	return isNullOrUndefined(value)
}
