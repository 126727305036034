<template>
	<footer aria-label="Website footer" class="bg-brand py-60 text-white">
		<div class="flex flex-wrap gap-40 container">
			<div class="flex-full mr-auto max-w-600 lg:flex-2/4">
				<v-title class="h3" tag="p" :value="footerContent?.title" />

				<v-copy class="mt-20" :value="footerContent?.copy" />

				<div
					v-if="contact?.socialMedia?.length"
					class="align-items-center flex flex-wrap gap-20 mt-40 text-sm">
					<p class="flex-full md:flex-auto">{{ $t('social.title') }}:</p>

					<div class="flex flex-full flex-wrap gap-20 md:flex-fill">
						<v-social-link
							v-for="(item, index) in contact.socialMedia"
							:key="index"
							:icon="item.icon.inline"
							:platform="item.title"
							:url="item.url" />
					</div>
				</div>
			</div>

			<nav
				v-if="footerNav.length"
				aria-label="Footer Navigation"
				class="flex-full text-xs lg:flex-1/4">
				<p class="mb-20">{{ $t('footer.nav.title') }}:</p>

				<ul class="footer-nav">
					<li v-for="(link, i) of footerNav" :key="i">
						<nuxt-link :to="link.url">
							{{ link.title }}
						</nuxt-link>
					</li>
				</ul>
			</nav>

			<div class="flex-full lg:flex-1/4">
				<v-title class="h3" tag="p" :value="newsletter?.title" />

				<v-copy class="mt-10 text-sm" :value="newsletter?.copy" />

				<v-formie
					class="footer-newsletter mt-20 max-w-600"
					handle="newsletter" />
			</div>

			<p class="flex-full text-xs">
				Te Pari &copy; {{ format(Date.now(), 'yyyy') }}
			</p>
		</div>
	</footer>
</template>

<script lang="ts" setup>
	import { format } from 'date-fns'

	const { contact, footerContent, footerNav, newsletter } =
		storeToRefs(useGlobalStore())
</script>

<style lang="scss" scoped>
	.footer-nav {
		@include reset-list();

		li {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			transition: color 0.2s ease-in-out;
			color: $white;
			font-weight: 900;
			text-decoration: none;

			&:hover {
				color: $white;
			}
		}
	}

	.footer-newsletter {
		--alert-px: 10px;
		--alert-py: 9px;
		--form-gutter: 20px;
		--input-color: #{$white};
		--input-error-color: #{$white};
		--input-color: #{$white};
		--input-bg-color: #{rgba($white, 0.15)};
		--input-border-width: 0px;
	}
</style>
