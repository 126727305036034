export const appHead = {"meta":[{"charset":"utf-8"},{"content":"width=device-width, initial-scale=1","name":"viewport"},{"content":"#0f4767","name":"theme-color"}],"link":[{"href":"/icons/favicon.svg","rel":"icon","type":"image/svg+xml"},{"href":"/icons/apple-touch-icon.png","rel":"apple-touch-icon","type":"image/png"},{"href":"https://www.google.com","rel":"preconnect"},{"href":"https://www.googletagmanager.com","rel":"preconnect"},{"crossorigin":"anonymous","href":"https://www.gstatic.com","rel":"preconnect"},{"href":"https://js.stripe.com","rel":"preconnect"},{"href":"https://cms.tepari.com","rel":"preconnect"}],"style":[],"script":[{"async":true,"defer":true,"src":"https://www.google.com/recaptcha/enterprise.js?render=6Ld8D14qAAAAAPkbbLNaapnVbSNfYrkeLwtFn04z","tagPosition":"bodyClose"},{"innerHTML":"\n\t\t\t\t\t\twindow.dataLayer = window.dataLayer || [];\n\t\t\t\t\t\tfunction gtag(){\n\t\t\t\t\t\t\tdataLayer.push(arguments);\n\t\t\t\t\t\t}\n\t\t\t\t\t\tgtag('js', new Date());\n\t\t\t\t\t\tgtag('config', 'GTM-NVW7VQ95');\n\t\t\t\t\t","tagPosition":"bodyClose"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"