<template>
	<v-image
		v-if="img"
		:alt="img.alt ?? img.title"
		:height="img.height"
		:src="img.url"
		:width="img.width"
		v-bind="options" />
</template>

<script lang="ts" setup>
	import { isArray } from 'lodash-es'
	import type { ImageAsset } from '@/graphql/image'
	import type { MaybeArray } from '@/types/maybe-array'
	import type { ImageOptions } from './image.vue'

	const props = defineProps<{
		options?: CraftImageOptions
		value?: MaybeArray<ImageAsset>
	}>()

	const img = computed(() =>
		isArray(props.value) ? props.value[0] : props.value,
	)
</script>

<script lang="ts">
	export type CraftImageOptions = Omit<
		ImageOptions,
		'alt' | 'height' | 'src' | 'width'
	>
</script>

<style lang="scss"></style>
