<template>
	<v-dialog ref="drawerEl" sticky-footer type="drawer">
		<template #header>
			<div class="text-center">
				<p class="h2">Your Cart</p>
			</div>
		</template>

		<template #body>
			<ul v-if="cart?.lineItems?.length" class="line-items">
				<li v-for="item in cart.lineItems" :key="item.id">
					<v-cart-line-item :item="item" />
				</li>
			</ul>
			<div v-else>
				<p class="text-center">Your cart is empty.</p>
			</div>
		</template>

		<template #footer>
			<div
				class="align-items-center flex gap-20 justify-content-between text-md">
				<p>Total:</p>
				<v-counter class="font-bold" :value="cart?.totalPrice ?? 0">
					<template #default="{ count }">
						{{ formatCurrency(count) }}
					</template>
				</v-counter>
			</div>

			<v-button
				class="mt-20 w-full"
				color="success"
				:disabled="!cart?.lineItems?.length"
				fill="solid"
				icon="tepari:lock"
				shape="round"
				size="xl"
				type="button"
				value="Secure checkout"
				@click="onCheckout" />
		</template>
	</v-dialog>
</template>

<script lang="ts" setup>
	import Dialog from '~/components/common/dialog/dialog.vue'
	import { formatCurrency } from '@/utilities/format-currency'

	const { $emitter } = useNuxtApp()
	const { cart } = storeToRefs(useCommerceStore())
	const localePath = useLocalePath()

	const drawerEl = ref<InstanceType<typeof Dialog>>()

	$emitter.on('cart:open', () => drawerEl.value?.open())
	$emitter.on('cart:close', () => drawerEl.value?.close())

	function onCheckout() {
		navigateTo(localePath('/shop/checkout'))
		drawerEl.value?.close()
	}
</script>

<style lang="scss" scoped>
	.line-items {
		list-style: none;
		padding-left: 0;

		li {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
</style>
