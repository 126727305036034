<template>
	<form class="formie-form" novalidate @submit.prevent="onSubmit">
		<template v-if="config">
			<div v-if="config.settings?.displayFormTitle" class="text-center">
				<h2 class="h1">
					{{ config.title }}
				</h2>

				<hr />
			</div>

			<!-- <v-stepper
				v-if="
					(config.settings?.displayProgress || config.settings?.displayTabs) &&
					steps.length > 1
				"
				:active-index="pageIndex"
				:steps="steps" /> -->

			<v-alert v-if="status" class="formie-form__status" :type="status.type">
				<v-copy :value="status.message" />

				<ul v-if="status.errors?.length">
					<li v-for="error in status.errors" :key="error">
						{{ error }}
					</li>
				</ul>
			</v-alert>

			<template v-if="status?.type !== 'success'">
				<div class="formie-form__pages">
					<transition-group>
						<v-formie-page
							v-for="(page, i) in config.pages"
							v-show="i === pageIndex"
							:key="i"
							:active-index="pageIndex"
							:hide-controls
							:loading
							:page="page"
							:page-index="i"
							:skewer-controls
							:total-pages="config.pages.length"
							@next="onNext"
							@prev="onPrev" />
					</transition-group>
				</div>

				<button v-if="hideControls" hidden type="submit">Submit</button>
			</template>
		</template>

		<div
			v-else
			class="align-items-center flex h-100 justify-content-center text-brand">
			<v-spinner />
		</div>
	</form>
</template>

<script lang="ts" setup>
	import useVuelidate from '@vuelidate/core'
	import type {
		FormieConfig,
		FormieField,
	} from '~~/server/api/plugins/forms/[slug]'
	import { useFields } from './composables/fields'
	import { useRules } from './composables/rules'
	import { useState } from './composables/state'

	defineExpose({
		next: onNext,
		prev: onPrev,
		submit: onSubmit,
		loading: computed(() => loading.value),
	})

	const props = defineProps<{
		handle: string
		lazy?: boolean
		hideControls?: boolean
		skewerControls?: boolean
	}>()

	const runtimeConfig = useRuntimeConfig()

	const { data } = await useApi(`/api/plugins/forms/${props.handle}`)

	const { user } = storeToRefs(useUserStore())

	const { currentSite } = storeToRefs(useSiteStore())

	const config = computed(() => data.value?.config)

	const loading = ref(false)

	const pageIndex = ref(0)

	const status = ref<{
		type: 'success' | 'info' | 'warning' | 'error'
		message?: string
		errors?: string[]
	} | null>(null)

	const fields = useFields(config)

	const state = useState(fields)

	const rules = useRules(fields, state)

	const vuelidate = useVuelidate(rules, state)

	watchEffect(() => {
		const userValue = user.value
		const stateValue = state.value

		if (!userValue || !stateValue) return

		const values: Record<string, any> = {}

		Object.entries(stateValue).forEach(([key, value]) => {
			if (value) return

			switch (key) {
				case 'email':
					values[key] = userValue.email
					break

				case 'fullName':
					values[key] = userValue.fullName
					break

				case 'phone':
					values[key] = userValue.phoneNumbers?.[0]?.number
					break

				default:
					values[key] = value
					break
			}
		})

		state.value = values
	})

	function onNext() {
		if (!config.value?.pages?.length) return
		if (pageIndex.value === config.value.pages.length - 1) return
		pageIndex.value++
	}

	function onPrev() {
		if (pageIndex.value === 0) return
		pageIndex.value--
	}

	async function onSubmit() {
		try {
			if (!config.value) {
				throw new Error('Formie: Missing `config`.')
			}

			loading.value = true

			status.value = null

			const body: Record<string, any> = {
				handle: props.handle,
				siteId: currentSite.value.id,
				fields: state.value,
			}

			const isValid = await vuelidate.value.$validate()

			if (!isValid) {
				status.value = {
					type: 'error',
					message: 'Please correct the errors below.',
				}

				return
			}

			config.value.captchas.forEach(async ({ handle }) => {
				switch (handle) {
					case 'recaptchaCaptcha':
						body['g-recaptcha-response'] = await getReCaptchaToken()
						break
				}
			})

			const res = await $fetch<FormieResponse>(
				`${runtimeConfig.public.cmsUrl}/actions/formie/submissions/submit`,
				{
					method: 'POST',
					body,
				},
			)

			status.value = res.success
				? {
						type: 'success',
						message: config.value.settings?.successMessageHtml,
					}
				: {
						type: 'error',
						message: config.value.settings?.errorMessageHtml,
					}
		} catch (error) {
			console.error(error)

			status.value = {
				type: 'error',
				message: 'An error occurred. Please try again.',
			}
		} finally {
			loading.value = false
		}
	}

	async function getReCaptchaToken() {
		await new Promise((resolve) =>
			grecaptcha.enterprise.ready(() => resolve(true)),
		)

		return await grecaptcha.enterprise.execute(
			runtimeConfig.public.googleRecaptchaClientKey,
			{
				action: 'FORM_SUBMIT',
			},
		)
	}

	provide<ProvidedProps>('formie', {
		config,
		fields,
		state,
		v$: vuelidate,
	})
</script>

<script lang="ts">
	interface FormieResponse {
		success: boolean
		submissionId: number
		nextPageId: string | number | null
		nextPageIndex: string | number | null
		isFinalPage: boolean
		totalPages: number
		redirectUrl: string
		submitActionMessage: string
		events: unknown[]
	}

	export interface ProvidedProps {
		config: Ref<FormieConfig | null | undefined>
		fields: Ref<FormieField[]>
		state: Ref<Record<string, any>>
		v$: Ref<any>
	}
</script>

<style lang="scss">
	.formie-form {
		display: grid;
		grid-auto-columns: minmax(0, 1fr);
		gap: var(--form-gutter, 24px);
		container-name: form;
		container-type: inline-size;

		&__status {
			--copy-gutter: 5px;
		}

		&__pages {
			display: grid;
			align-items: start;

			> * {
				grid-row: 1;
				grid-column: 1;
			}
		}
	}

	.formie-page {
		transition: all 150ms;

		&.formie-page--before-active {
			transform: translateX(-100px);
			opacity: 0;
		}

		&.formie-page--after-active {
			transform: translateX(100px);
			opacity: 0;
		}
	}
</style>
