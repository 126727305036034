<template>
	<p class="form-instructions">
		<slot />
	</p>
</template>

<style lang="scss">
	@layer components {
		.form-instructions {
			padding-right: 16px;
			padding-left: 16px;
			font-size: $text-2xs;
		}
	}
</style>
