<template>
	<v-dialog ref="popupEl" class="popup-advert" type="modal">
		<div class="border-2 border-white flex">
			<v-thumbnail v-if="popup?.image" class="w-300" ratio="1:1">
				<v-craft-image ratio="1:1" :value="popup?.image" />
			</v-thumbnail>

			<div class="flex flex-fill flex-col px-20 py-40">
				<v-title class="h3 text-brand" :value="popup?.title" />

				<v-copy class="mt-20" :value="popup?.copy" />

				<p v-if="link && !link.isEmpty" class="mt-auto">
					<v-button
						:is="nuxtLink"
						color="primary"
						fill="outline"
						shape="round"
						size="sm"
						:to="link.isElement ? localePath(`/${link.element.uri}`) : link.url"
						value="Read more" />
				</p>
			</div>
		</div>
	</v-dialog>
</template>

<script lang="ts" setup>
	import type Dialog from './dialog/dialog.vue'

	const { popup } = storeToRefs(useGlobalStore())

	const localePath = useLocalePath()

	const nuxtLink = resolveComponent('NuxtLink')

	const popupEl = ref<InstanceType<typeof Dialog>>()

	const link = computed(() => popup.value?.link?.[0])

	onMounted(() => {
		if (!popup.value?.enablePopup) return

		setTimeout(() => {
			popupEl.value?.open()
		}, 2000)
	})
</script>

<style lang="scss">
	.popup-advert {
		--dialog-body-px: 0;
		--dialog-body-py: 0;
	}
</style>
