import Address from './formie-address.vue'
import Agree from './formie-agree.vue'
import Dropdown from './formie-dropdown.vue'
import Email from './formie-email.vue'
import FileUpload from './formie-file-upload.vue'
import MultiLineText from './formie-multi-line-text.vue'
import Number from './formie-number.vue'
import Password from './formie-password.vue'
import Phone from './formie-phone.vue'
import Radio from './formie-radio.vue'
import SingleLineText from './formie-single-line-text.vue'

export const FORMIE_INPUTS: Record<string, any> = {
	'verbb\\formie\\fields\\subfields\\AddressAutoComplete': Address,
	'verbb\\formie\\fields\\subfields\\Address1': SingleLineText,
	'verbb\\formie\\fields\\subfields\\Address2': SingleLineText,
	'verbb\\formie\\fields\\subfields\\Address3': SingleLineText,
	'verbb\\formie\\fields\\subfields\\AddressCity': SingleLineText,
	'verbb\\formie\\fields\\subfields\\AddressZip': SingleLineText,
	'verbb\\formie\\fields\\subfields\\AddressState': SingleLineText,
	'verbb\\formie\\fields\\subfields\\AddressCountry': Dropdown,
	'verbb\\formie\\fields\\Agree': Agree,
	'verbb\\formie\\fields\\Dropdown': Dropdown,
	'verbb\\formie\\fields\\Email': Email,
	'verbb\\formie\\fields\\FileUpload': FileUpload,
	'verbb\\formie\\fields\\MultiLineText': MultiLineText,
	'verbb\\formie\\fields\\Name': SingleLineText,
	'verbb\\formie\\fields\\subfields\\NamePrefix': Dropdown,
	'verbb\\formie\\fields\\subfields\\NameFirst': SingleLineText,
	'verbb\\formie\\fields\\subfields\\NameMiddle': SingleLineText,
	'verbb\\formie\\fields\\subfields\\NameLast': SingleLineText,
	'verbb\\formie\\fields\\Number': Number,
	'verbb\\formie\\fields\\Password': Password,
	'verbb\\formie\\fields\\Phone': Phone,
	'verbb\\formie\\fields\\Radio': Radio,
	'verbb\\formie\\fields\\SingleLineText': SingleLineText,
}
