import { useScriptStripe, useScriptGoogleTagManager, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleTagManager = useScriptGoogleTagManager({"id":"GTM-NVW7VQ95","scriptOptions":{"mode":"client","tagPosition":"bodyClose","tagPriority":"low","trigger":"onNuxtReady"}})
    const stripe = useScriptStripe({"key":"pk_test_51PLvvjKa60Qz5QP7PkeDKGjBsg8ENBwxFkR4Me7qHm01IVxYIZtAjSn570eqFucak2jqbzGE6TNaYCgAQAE0Lo8r00J0q7Tggl","scriptOptions":{"mode":"client","tagPosition":"bodyClose","tagPriority":"low","trigger":"onNuxtReady"}})
    return { provide: { $scripts: { googleTagManager, stripe } } }
  }
})