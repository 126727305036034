<template>
	<v-field :instructions="field.instructions" :vuelidate="vuelidate">
		<v-select
			v-model="model"
			:attributes="attrs"
			:label="field.label"
			:name="name"
			:options="options" />
	</v-field>
</template>

<script lang="ts" setup>
	import type {
		SelectOption,
		SelectOptionGroup,
	} from '@/components/common/inputs/select/select.vue'
	import type { DropdownField } from '~~/server/api/plugins/forms/[slug]'
	import {
		useFieldAttrs,
		useFieldModel,
		useFieldValidation,
	} from '../composables/helpers'

	const props = defineProps<{
		field: DropdownField
		stateKey: string
	}>()

	const { attrs, name } = useFieldAttrs(props.field)

	const vuelidate = useFieldValidation(props.stateKey)

	const model = useFieldModel(props.stateKey)

	const options = computed(() => {
		if (!props.field.options) {
			return []
		}

		const hasOptGroups = props.field.options.some(
			({ isOptgroup }) => isOptgroup,
		)

		if (!hasOptGroups) {
			return props.field.options.map<SelectOption<any>>((option) => ({
				label: option.label,
				value: option.value,
			}))
		}

		const options: SelectOptionGroup<any>[] = []

		let currentGroup: SelectOptionGroup<any> | null = null

		props.field.options.forEach((option) => {
			if (option.isOptgroup) {
				currentGroup = {
					label: option.label,
					options: [],
				}

				options.push(currentGroup)
			} else if (currentGroup) {
				currentGroup.options?.push({
					label: option.label,
					value: option.value,
				})
			}
		})

		return options
	})
</script>

<style lang="scss"></style>
