import { defineStore } from 'pinia'
import type { LineItem } from '@/types/line-item'
import { logger } from '@/utilities/logger'

export interface UpdateCartPayload {
	/**
	 * Billing address attributes. (See Addresses).
	 */
	billingAddress?: Record<string, any>

	/**
	 * ID of an existing address to use as the billing address.
	 */
	billingAddressId?: string | number

	/**
	 * Set to true to use shipping address for billing address. (Will ignore billing address ID and fields.)
	 */
	billingAddressSameAsShipping?: boolean

	/**
	 * When passed, clears all cart notices.
	 */
	clearNotices?: boolean

	/**
	 * When passed, empties all line items from cart.
	 */
	clearLineItems?: boolean

	/**
	 * Coupon code for a discount that should be applied to the cart.
	 */
	couponCode?: string

	/**
	 * Email address to be associated with the cart.
	 */
	email?: string

	/**
	 * Estimated billing address attributes. (See Addresses).
	 */
	estimatedBillingAddress?: unknown[]

	/**
	 * Set to true to use shipping address for estimated billing address.
	 */
	estimatedBillingAddressSameAsShipping?: boolean

	/**
	 * Estimated shipping address attributes. (See Addresses).
	 */
	estimatedShippingAddress?: unknown[]

	/**
	 * Optional array of custom fields to be submitted to the cart.
	 */
	fields?: Record<string, any>

	/**
	 * Optionally set to true to force saving the cart.
	 */
	forceSave?: boolean

	/**
	 * The payment gateway ID to be used when the cart is completed.
	 */
	gatewayId?: string | number

	/**
	 * Array of one or more of the cart’s line items to update.
	 */
	lineItems?: Record<
		number,
		{
			options?: unknown[]
			note?: string
			qty?: number
			remove?: 1 | 0
		}
	>

	/**
	 * Optional order number for specific, existing cart.
	 */
	number?: string

	/**
	 * ISO code of a configured payment currency to be used for the cart.
	 */
	paymentCurrency?: string

	/**
	 * The ID for a payment source that should be used when the cart is completed.
	 */
	paymentSourceId?: string | number

	/**
	 * Single purchasable ID to be added to the cart. If provided, will also use optional note, options[], and qty parameters.
	 */
	purchasableId?: string | number

	/**
	 * Array of one or more purchasables to be added to the cart.
	 */
	purchasables?: {
		id: string | number
		options?: unknown[]
		note?: string
		qty: number
	}[]

	/**
	 * Whether to create a user account for the customer when the cart is completed and turned into an order.
	 */
	registerUserOnOrderComplete?: boolean

	/**
	 * Whether to save the billing address to the customer’s address book when the cart is completed and turned into an order. 4.3.0+
	 */
	saveBillingAddressOnOrderComplete?: boolean

	/**
	 * Whether to save the shipping address to the customer’s address book when the cart is completed and turned into an order. 4.3.0+
	 */
	saveShippingAddressOnOrderComplete?: boolean

	/**
	 * Whether to save both the shipping and billing address to the customer’s address book when the cart is completed and turned into an order. Unlike saveBillingAddressOnOrderComplete and saveShippingAddressOnOrderComplete, this is not stored on the order itself—it just allows customers to set both at the same time. 4.3.0+
	 */
	saveAddressesOnOrderComplete?: boolean

	/**
	 * Shipping address attributes. (See Addresses).
	 */
	shippingAddress?: Record<string, any>

	/**
	 * ID of an existing address to use as the shipping address.
	 */
	shippingAddressId?: string | number

	/**
	 * Set to true to use billing address for shipping address and ignore shippingAddress and shippingAddressId.
	 */
	shippingAddressSameAsBilling?: boolean

	/**
	 * The handle of a shipping method to be set for the cart.
	 */
	shippingMethodHandle?: string
}

export interface Cart {
	number: string
	reference?: any
	couponCode?: any
	isCompleted: boolean
	dateOrdered?: any
	datePaid?: any
	dateAuthorized?: any
	currency: string
	gatewayId: number
	lastIp: string
	message?: any
	returnUrl?: any
	cancelUrl?: any
	orderStatusId?: any
	orderLanguage: string
	storeId: number
	orderSiteId: number
	origin: string
	orderCompletedEmail?: any
	billingAddressId: number
	shippingAddressId: number
	makePrimaryShippingAddress: boolean
	makePrimaryBillingAddress: boolean
	shippingSameAsBilling: boolean
	billingSameAsShipping: boolean
	estimatedBillingAddressId?: any
	estimatedShippingAddressId?: any
	sourceBillingAddressId?: any
	sourceShippingAddressId?: any
	estimatedBillingSameAsShipping: boolean
	shippingMethodHandle?: any
	shippingMethodName?: any
	registerUserOnOrderComplete: boolean
	saveBillingAddressOnOrderComplete: boolean
	saveShippingAddressOnOrderComplete: boolean
	paymentSourceId?: any
	storedTotalPrice: number
	storedTotal?: any
	storedTotalPaid: number
	storedItemTotal: number
	storedItemSubtotal: number
	storedTotalShippingCost: number
	storedTotalDiscount: number
	storedTotalTax: number
	storedTotalTaxIncluded: number
	storedTotalQty: number
	suppressEmails: boolean
	eagerLoadInfo?: any
	id: number
	tempId?: any
	draftId?: any
	revisionId?: any
	isProvisionalDraft: boolean
	uid: string
	siteSettingsId: number
	fieldLayoutId: number
	enabled: boolean
	archived: boolean
	siteId: 1
	title?: any
	slug?: any
	uri?: any
	dateCreated: {
		date: string
		time: string
	}
	dateUpdated: {
		date: string
		time: string
	}
	dateLastMerged?: any
	dateDeleted?: any
	deletedWithOwner?: any
	trashed: boolean
	isNewForSite: boolean
	forceSave: boolean
	canonicalId: number
	cpEditUrl: string
	isDraft: boolean
	isRevision: boolean
	isUnpublishedDraft: boolean
	ref?: any
	status: string
	structureId?: any
	url?: any
	adjustmentSubtotal: number
	adjustmentsTotal: number
	customer?: {
		photoId?: any
		active: boolean
		pending: boolean
		locked: boolean
		suspended: boolean
		admin: boolean
		username: string
		email: string
		password?: any
		lastLoginDate: string
		invalidLoginCount?: any
		lastInvalidLoginDate?: any
		lockoutDate?: any
		hasDashboard: boolean
		passwordResetRequired: boolean
		lastPasswordChangeDate?: any
		unverifiedEmail?: any
		newPassword?: any
		currentPassword?: any
		verificationCodeIssuedDate?: any
		verificationCode?: any
		lastLoginAttemptIp?: any
		authError?: any
		inheritorOnDelete?: any
		eagerLoadInfo?: any
		id: number
		tempId?: any
		draftId?: any
		revisionId?: any
		isProvisionalDraft: boolean
		uid: string
		siteSettingsId: number
		fieldLayoutId: number
		enabled: boolean
		archived: boolean
		siteId: 1
		title?: any
		slug?: any
		uri?: any
		dateCreated: string
		dateUpdated: string
		dateLastMerged?: any
		dateDeleted?: any
		deletedWithOwner?: any
		trashed: boolean
		isNewForSite: boolean
		forceSave: boolean
		fullName: string
		firstName: string
		lastName?: any
		canonicalId: number
		cpEditUrl: string
		isDraft: boolean
		isRevision: boolean
		isUnpublishedDraft: boolean
		ref: string
		status: string
		structureId?: any
		url?: any
		cooldownEndTime?: any
		friendlyName: string
		isCredentialed: boolean
		isCurrent: boolean
		name: string
		preferredLanguage?: any
		remainingCooldownTime?: any
		jobTitle?: any
		phoneNumbers: [
			{
				col1: string
				col2: string
				col3: boolean
			},
		]
		branches: []
		teams: []
	}
	customerId: number
	paymentCurrency: string
	paymentAmount: number
	isPaid: boolean
	itemSubtotal: number
	itemTotal: number
	lineItems: LineItem[]
	orderAdjustments: CartOrderAdjustment[]
	outstandingBalance: number
	paidStatus: string
	recalculationMode: string
	shortNumber: string
	totalPaid: number
	total: number
	totalPrice: number
	totalQty: number
	totalPromotionalAmount: number
	totalWeight: number
	adjustmentSubtotalAsCurrency: string
	adjustmentsTotalAsCurrency: string
	itemSubtotalAsCurrency: string
	itemTotalAsCurrency: string
	outstandingBalanceAsCurrency: string
	paymentAmountAsCurrency: string
	totalPaidAsCurrency: string
	totalAsCurrency: string
	totalPriceAsCurrency: string
	totalPromotionalAmountAsCurrency: string
	totalTaxAsCurrency: string
	totalTaxIncludedAsCurrency: string
	totalShippingCostAsCurrency: string
	totalDiscountAsCurrency: string
	storedTotalAsCurrency: string
	storedTotalPriceAsCurrency: string
	storedTotalPaidAsCurrency: string
	storedItemTotalAsCurrency: string
	storedItemSubtotalAsCurrency: string
	storedTotalShippingCostAsCurrency: string
	storedTotalDiscountAsCurrency: string
	storedTotalTaxAsCurrency: string
	storedTotalTaxIncludedAsCurrency: string
	email: string
	paidStatusHtml: string
	customerLinkHtml: string
	orderStatusHtml: string
	totalTax: number
	totalTaxIncluded: number
	totalShippingCost: number
	totalDiscount: number
	totalSaleAmount: number
	totalSaleAmountAsCurrency: string
	availableShippingMethodOptions: {
		'NZ Weight ': {
			price: number
			matchesOrder: boolean
			shippingMethod: {
				id: number
				name: string
				handle: string
				enabled: boolean
				dateCreated: string
				dateUpdated: string
				storeId: number
			}
			id: number
			name: string
			handle: string
			enabled: boolean
			dateCreated: string
			dateUpdated: string
			storeId: number
			priceAsCurrency: string
		}
	}
	billingAddress?: CartAddress
	notices: []
	shippingAddress?: CartAddress
}

export interface CartAddress {
	countryCode: string
	administrativeArea?: any
	locality: string
	dependentLocality: string
	postalCode: string
	sortingCode?: any
	addressLine1: string
	addressLine2?: any
	addressLine3?: any
	organization?: any
	organizationTaxId?: any
	latitude?: any
	longitude?: any
	eagerLoadInfo?: any
	id: number
	tempId?: any
	draftId?: any
	revisionId?: any
	isProvisionalDraft: boolean
	uid: string
	siteSettingsId: number
	fieldLayoutId: number
	enabled: boolean
	archived: boolean
	siteId: number
	title: string
	slug?: any
	uri?: any
	dateCreated: string
	dateUpdated: string
	dateLastMerged?: any
	dateDeleted?: any
	deletedWithOwner?: any
	trashed: boolean
	isNewForSite: boolean
	forceSave: boolean
	fullName: string
	firstName: string
	lastName: string
	fieldId?: any
	sortOrder?: any
	saveOwnership: boolean
	canonicalId: number
	cpEditUrl?: any
	isDraft: boolean
	isRevision: boolean
	isUnpublishedDraft: boolean
	ref?: any
	status: string
	structureId?: any
	url?: any
	primaryOwnerId: number
	ownerId: number
}

export interface CartOrderAdjustment {
	id: number
	name: string
	description: string
	type: string
	amount: number
	included: boolean
	orderId: number
	lineItemId?: any
	isEstimated: boolean
	sourceSnapshot: {
		id: number
		name: string
		code: string
		rate: number
		include: boolean
		removeIncluded: boolean
		removeVatIncluded: boolean
		isVat: boolean
		taxable: string
		taxCategoryId?: any
		taxZoneId: number
		dateCreated?: any
		dateUpdated?: any
		storeId: number
	}
	amountAsCurrency: string
}

export interface PaymentIntent {
	cart: Cart
	redirect: string
	redirectData: {
		client_secret: string
		payment_intent: string
	}
	transactionId: string
	transactionHash: string
	modelName: string
	modelClass: string
	paymentForm: {
		paymentFormType: string
		customer?: any
		paymentMethodId?: any
		savePaymentSource: false
	}
}

export const useCommerceStore = defineStore('CommerceStore', () => {
	const cmsUrl = useRuntimeConfig().public.cmsUrl

	// State
	// --------------------------------------------------

	const cart = ref<Cart | null>(null)

	// Cart
	// --------------------------------------------------

	async function getCart() {
		type Response = {
			data: Cart | null
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/commerce/cart/get-cart`, {
				method: 'GET',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					Accept: 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
					cart.value = response.ok ? (response._data?.cart ?? null) : null
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
					cart.value = null
				},
			})
		} catch (error: any) {
			logger.error('getCart', error)
		}

		return res
	}

	async function updateCart(payload: UpdateCartPayload) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/commerce/cart/update-cart`, {
				method: 'POST',
				body: payload,
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok

					if (response.ok) {
						cart.value = response._data?.cart ?? null
					}
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error: any) {
			logger.error('updateCart', error)
		}

		return res
	}

	async function forgetCart() {
		try {
			await $fetch(`${cmsUrl}/actions/commerce/cart/forget-cart`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
			})

			cart.value = null

			await getCart()
		} catch (error: any) {
			logger.error('forgetCart', error)
		}
	}

	function addItems(items: UpdateCartPayload['purchasables']) {
		const body: UpdateCartPayload = {
			purchasables: items,
		}

		return updateCart(body)
	}

	function updateItems(items: UpdateCartPayload['lineItems']) {
		const body: UpdateCartPayload = {
			lineItems: items,
		}

		return updateCart(body)
	}

	// Payment
	// --------------------------------------------------

	async function getPaymentIntent() {
		type Response = {
			data: PaymentIntent | null
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(`${cmsUrl}/actions/commerce/payments/pay`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				body: {
					gatewayId: 2,
				},
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				onResponse: ({ response }) => {
					res.data = response.ok ? response._data : null
					res.error = response.ok ? null : response._data
					res.success = response.ok
				},
				onResponseError: ({ response }) => {
					res.success = false
					res.error = response._data
				},
			})
		} catch (error: any) {
			logger.error('getPaymentIntent', error)
		}

		return res
	}

	async function completePayment(hash: string) {
		type Response = {
			data: any
			error: any
			success: boolean
		}

		const res: Response = {
			data: null,
			error: null,
			success: false,
		}

		try {
			await $fetch(
				`${cmsUrl}/actions/commerce/payments/complete-payment?commerceTransactionHash=${hash}`,
				{
					method: 'GET',
					cache: 'no-cache',
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					onResponse: ({ response }) => {
						res.data = response.ok ? response._data : null
						res.error = response.ok ? null : response._data
						res.success = response.ok
					},
					onResponseError: ({ response }) => {
						res.success = false
						res.error = response._data
					},
				},
			)
		} catch (error: any) {
			logger.error('completePayment', error)
		}

		return res
	}

	return {
		addItems,
		cart,
		completePayment,
		forgetCart,
		getCart,
		getPaymentIntent,
		updateCart,
		updateItems,
	}
})
