<template>
	<dialog
		:id="id"
		ref="dialog"
		class="dialog"
		:class="{
			[`dialog--animating-${animating}`]: animating,
			[`dialog--${state}`]: state,
			[`dialog--${type}`]: type,
			'dialog--sticky-header': stickyHeader,
			'dialog--sticky-footer': stickyFooter,
		}"
		@cancel.prevent="close()"
		@click="onBackdropClick($event)">
		<transition
			@after-enter="onAfterEnter"
			@after-leave="onAfterLeave"
			@before-enter="onBeforeEnter"
			@before-leave="onBeforeLeave">
			<div v-if="isActive" class="dialog__content">
				<v-button
					class="dialog__close"
					color="primary"
					fill="solid"
					icon="tepari:cross"
					shape="round"
					@click="close()" />

				<header v-if="slots.header" class="dialog__header">
					<slot name="header" />
				</header>

				<div class="dialog__body">
					<slot />
					<slot name="body" />
				</div>

				<footer v-if="slots.footer" class="dialog__footer">
					<slot name="footer" />
				</footer>
			</div>
		</transition>
	</dialog>
</template>

<script lang="ts" setup>
	import { useDialogStore } from './dialog.store'

	const props = defineProps<{
		id?: string
		type: 'modal' | 'drawer'
		stickyHeader?: boolean
		stickyFooter?: boolean
	}>()

	const emit = defineEmits<{
		'dialog:hidden': []
		'dialog:visible': []
	}>()

	const dialogStore = useDialogStore()
	const id = props.id ?? (useId() as string)
	const slots = useSlots()

	const dialog = ref<HTMLDialogElement | null>(null)
	const isActive = ref(false)
	const animating = ref<'in' | 'out' | null>(null)
	const state = ref<'visible' | 'hidden'>('hidden')

	function open() {
		isActive.value = true
	}

	function close() {
		isActive.value = false
	}

	function toggle() {
		if (isActive.value) {
			close()
		} else {
			open()
		}
	}

	function onBackdropClick(e: MouseEvent) {
		const target = e.target

		if (target instanceof HTMLDialogElement) {
			close()
		}
	}

	function onBeforeEnter() {
		dialogStore.addDialog(id, props.type)
		dialog.value?.showModal()

		animating.value = 'in'
		state.value = 'visible'

		emit('dialog:visible')
	}

	function onBeforeLeave() {
		animating.value = 'out'
	}

	function onAfterEnter() {
		animating.value = null
	}

	function onAfterLeave() {
		dialogStore.removeDialog(id, props.type)
		dialog.value?.close()

		animating.value = null
		state.value = 'hidden'

		emit('dialog:hidden')
	}

	defineExpose<ExposedProps>({
		close,
		id,
		isActive,
		open,
		toggle,
	})

	provide<ProvidedProps>('dialog', {
		close,
		id,
		isActive,
		open,
		toggle,
	})
</script>

<script lang="ts">
	export interface ExposedProps {
		id: string
		isActive: Ref<boolean>
		open: () => void
		close: () => void
		toggle: () => void
	}

	export interface ProvidedProps {
		id: string
		isActive: Ref<boolean>
		open: () => void
		close: () => void
		toggle: () => void
	}
</script>

<style lang="scss">
	@layer components {
		.dialog {
			width: 100%;
			height: 100%;
			overflow: auto;

			&::backdrop {
				opacity: 0;
				backdrop-filter: blur(2px);
				transition: opacity 250ms ease;
				background-color: rgba(#798fa3, 0.4);
			}

			&--visible {
				&::backdrop,
				&.dialog--animating-in::backdrop {
					opacity: 1;
				}

				&.dialog--animating-out::backdrop {
					opacity: 0;
				}
			}

			&--sticky-header {
				.dialog__header {
					position: sticky;
					z-index: 1;
					background-color: $white;
					width: 100%;
					top: 0;
					left: 0;
				}
			}

			&--sticky-footer {
				.dialog__footer {
					position: sticky;
					z-index: 1;
					background-color: $white;
					width: 100%;
					top: 0;
					left: 0;
				}
			}

			&--drawer {
				padding-left: var(--dialog-drawer-offset, 20px);

				.dialog__header,
				.dialog__footer {
					min-height: var(--header-height);
				}

				.dialog__content {
					margin-left: auto;
					max-width: var(--dialog-width, 500px);
					min-height: 100%;

					&.v-enter-active,
					&.v-leave-active {
						transition: transform 250ms ease;
					}

					&.v-enter-from,
					&.v-leave-to {
						transform: translateX(100%);
					}
				}

				.dialog__close {
					top: 20px;
					left: 0px;
					transform: translate(-50%, 0%);
				}
			}

			&--modal {
				padding: 40px 20px;

				&.dialog--visible {
					display: flex;
					flex-direction: column;
				}

				.dialog__content {
					margin: auto;
					width: var(--dialog-width, 700px);
					max-width: 100%;

					&.v-enter-active,
					&.v-leave-active {
						transition: 250ms ease-in-out;
						transition-property: opacity, transform;
					}

					&.v-enter-from,
					&.v-leave-to {
						opacity: 0;
						transform: scale(0.9);
					}
				}

				.dialog__close {
					top: 0px;
					right: 0px;
					transform: translate(50%, -50%);
				}
			}

			&__content {
				display: flex;
				flex-direction: column;
				position: relative;
				box-shadow: 0px 0px 8px rgba($black, 0.2);
				background-color: $white;
			}

			&__close {
				position: absolute;
				z-index: 100;
				border: 1px solid $white;
			}

			&__header {
				@include var-pad('dialog-header', 4rem, 2rem);

				flex: 0 0 auto;
				border-bottom: 1px solid $gray-300;
			}

			&__body {
				@include var-pad('dialog-body', 4rem, 4rem);

				flex: 1 1 auto;
			}

			&__footer {
				@include var-pad('dialog-footer', 4rem, 2rem);

				flex: 0 0 auto;
				border-top: 1px solid $gray-300;
			}
		}
	}
</style>
