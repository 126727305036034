<template>
	<div :id="id" class="collapse" :class="{ 'collapse--active': active }">
		<slot />
	</div>
</template>

<script lang="ts" setup>
	const emit = defineEmits<{
		open: []
		close: []
	}>()

	const id = useId()

	const active = ref(false)

	watchEffect(() => {
		if (active.value) {
			emit('open')
		} else {
			emit('close')
		}
	})

	provide('collapse', {
		id,
		active,
	})

	defineExpose({
		open: () => (active.value = true),
		close: () => (active.value = false),
	})
</script>

<script lang="ts">
	export interface ProvidedProps {
		id: string
		active: Ref<boolean>
	}
</script>

<style lang="scss">
	@layer components {
		.collapse {
			border-bottom-width: var(--collapse-border-width, 1px);
			border-bottom-style: var(--collapse-border-style, solid);
			border-bottom-color: var(--collapse-border-color, #{$gray-700});
		}
	}
</style>
