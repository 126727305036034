import { isEmpty } from '@/utilities/is-empty'
import type {
	AgreeField,
	FormieField,
} from '~~/server/api/plugins/forms/[slug]'

export function useState(fields: Ref<FormieField[]>) {
	const state = ref<Record<string, any>>({})

	state.value = getState(fields)

	watch(fields, () => {
		state.value = getState(fields)
	})

	return state
}

function getState(fields: Ref<FormieField[]>) {
	const state: Record<string, any> = {}

	fields.value.forEach((field) => {
		state[field.handle] = getFieldState(field)
	})

	return state
}

function getFieldState(field: FormieField) {
	if (!field.rows?.length || field.useMultipleFields === false) {
		return getDefaultValue(field)
	}

	const state: Record<string, any> = {}

	field.rows!.forEach((row) => {
		row.fields.forEach((field) => {
			state[field.handle] = getFieldState(field)
		})
	})

	return state
}

function getDefaultValue(field: FormieField) {
	switch (field.type) {
		case 'verbb\\formie\\fields\\Agree':
			return (field as AgreeField).defaultValue === '1'
				? (field as AgreeField).checkedValue
				: (field as AgreeField).uncheckedValue

		case 'verbb\\formie\\fields\\FileUpload':
			return {
				fileData: '',
				filename: '',
			}

		default:
			return !isEmpty(field.defaultValue) ? field.defaultValue : ''
	}
}
