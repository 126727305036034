<template>
	<v-input-label
		class="textarea-wrapper"
		:empty="!internalValue"
		:focused="isFocused"
		:hidden="labelHidden"
		:position="labelPosition ?? 'float'"
		:required="required"
		:value="label">
		<textarea
			v-model="internalValue"
			ref="inputEl"
			class="textarea"
			:style="{
				'--input-rows': rows,
			}"
			v-bind="attributes"
			@blur="onBlur"
			@focus="onFocus"
			@input="onInput" />
	</v-input-label>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		attributes?: Record<string, any>
		disabled?: boolean
		label: string
		labelHidden?: boolean
		labelPosition?: 'top' | 'right' | 'bottom' | 'left' | 'float'
		modelValue?: string
		name?: string
		placeholder?: string
		readonly?: boolean
		required?: boolean
		rows?: number
	}>()

	const emit = defineEmits<{
		'blur': [Event]
		'focus': [Event]
		'input': [Event]
		'update:modelValue': [string]
	}>()

	const { textarea: inputEl, input: internalValue } = useTextareaAutosize()
	const modelValue = toRef(props, 'modelValue')
	const isFocused = ref(false)

	watch(modelValue, (value) => (internalValue.value = value ?? ''), {
		immediate: true,
	})

	function onBlur(event: Event) {
		isFocused.value = false
		emit('blur', event)
	}

	function onFocus(event: Event) {
		isFocused.value = true
		emit('focus', event)
	}

	function onInput(event: Event) {
		emit('input', event)
		emit('update:modelValue', internalValue.value)
	}

	defineExpose({
		blur: onBlur,
		focus: onFocus,
		inputEl,
		value: internalValue,
	})
</script>

<style lang="scss">
	@layer components {
		.textarea-wrapper {
			&.label--floating {
				.label__inner {
					max-height: 36px;
				}
			}
		}

		.textarea {
			resize: none;
			min-height: calc(
				(var(--input-py, $input-py) * 2) +
					(var(--input-border-width, $input-border-width) * 2) +
					(var(--input-lh, $input-lh) * var(--input-rows, 1))
			);

			@include input();
		}
	}
</style>
