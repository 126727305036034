<template>
	<div class="formie-row">
		<div v-for="field in fields" :key="field.id" class="flex-fill">
			<v-formie-field
				v-if="isSingleField(field)"
				:field="field"
				:state-key="getStateKey(field)" />

			<v-formie-row
				v-for="(row, rowIndex) in field.rows"
				v-else
				:key="rowIndex"
				:class="{
					'mb-20': rowIndex < field.rows!.length - 1,
				}"
				:fields="row.fields"
				:handle="field.handle" />
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type { FormieField } from '~~/server/api/plugins/forms/[slug]'

	const props = defineProps<{
		fields: FormieField[]
		handle?: string
	}>()

	function getStateKey(field: FormieField) {
		return props.handle ? `${props.handle}.${field.handle}` : field.handle
	}

	function isSingleField(field: FormieField) {
		return !field.rows?.length || field.useMultipleFields === false
	}
</script>

<style lang="scss" scoped>
	.formie-row {
		display: flex;
		flex-wrap: wrap;
		gap: var(--form-gutter, 24px);
	}
</style>
