import { defineStore } from 'pinia'
import { SITES, type Site } from '@/constants/sites'

export const useSiteStore = defineStore('Site', () => {
	const route = useRoute()

	const router = useRouter()

	const currentSite = computed(() => {
		const path = route.path.split('/')[1]
		const site = SITES.find((site) => site.path === path)
		return site ?? (SITES[0] as Site)
	})

	function switchSite(path: string) {
		const site = SITES.find((site) => site.path === path)

		if (!site) {
			throw new Error(`Site: Unable to switch to site using path: ${path}`)
		}

		router.push({
			path: `/${site.path}`,
		})
	}

	return {
		currentSite,
		sites: SITES,
		switchSite,
	}
})
