<template>
	<div ref="el" class="flex">
		<span v-if="prefix"> {{ prefix }} </span>

		<slot v-bind="{ count: displayCount }"> {{ displayCount }} </slot>

		<span v-if="suffix"> {{ suffix }} </span>
	</div>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		duration?: number
		prefix?: string
		suffix?: string
		trigger?: 'load' | 'visible'
		value: number
	}>()

	const { $gsap } = useNuxtApp()
	const counter = { val: 0 }

	const el = ref<HTMLElement>()
	const finalCount = toRef(props, 'value')
	const displayCount = ref(props.value)

	let tween: gsap.core.Tween | null = null

	onMounted(() => {
		if (props.trigger === 'visible') {
			useIntersectionObserver(el, ([entry], observer) => {
				if (!entry?.isIntersecting) return

				animate()
				watch(finalCount, animate)

				observer.unobserve(entry.target)
				observer.disconnect()
			})
		} else {
			animate()
			watch(finalCount, animate)
		}
	})

	function animate() {
		tween?.kill()

		tween = $gsap.to(counter, {
			duration: props.duration ?? 0.5,
			ease: 'power1.out',
			onUpdate: () => {
				displayCount.value = counter.val
			},
			roundProps: 'val',
			val: finalCount.value,
		})
	}
</script>

<style lang="scss"></style>
