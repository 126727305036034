import { isArray } from 'lodash-es'
import type { HyperLink } from '~/graphql/hyperlink'
import type { MaybeArray } from '~/types/maybe-array'

export interface Link {
	url: string
	text: string
	target: string
}

export function getLink(items?: MaybeArray<HyperLink>) {
	const localePath = useLocalePath()
	const link = isArray(items) ? items[0] : items

	if (!link || link.isEmpty) return null

	let url: string | null = null

	if (!link.isElement) {
		url = link.url
	}

	if (link.isElement && link.element.uri) {
		url = localePath(`/${link.element.uri}`)
	}

	return url
		? {
				url,
				text: link.linkText ?? 'Read More',
				target: link.target,
			}
		: null
}
