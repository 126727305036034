<template>
	<header
		id="page-header"
		ref="header"
		aria-label="Website header"
		class="header container"
		:style="{
			'--header-menu-left': `${state.menuLeft}px`,
			'--header-menu-left-offset': `${state.menuOffset}px`,
			'--header-menu-width': `${state.menuWidth}px`,
			'--header-menu-height': `${state.menuHeight}px`,
		}"
		@mouseleave="onMouseLeave"
		@mousemove="onMouseMove">
		<div class="header__content">
			<nuxt-link
				:aria-label="$t('header.home')"
				class="mr-auto my-auto text-brand"
				:to="localePath('/')">
				<icon class="h-50 w-auto sm:hidden" name="tepari:logo" />
				<icon class="h-50 w-auto lt-sm:hidden" name="tepari:logo-wide" />
			</nuxt-link>

			<v-header-nav aria-label="Website navigation" class="hidden lg:block">
				<template v-for="item of headerNav" :key="item.id">
					<v-header-link
						v-if="item.type === 'mega-menu' || item.type === 'menu'"
						:active="isChildActive(item.children)"
						class="px-20"
						:data-menu-id="item.id"
						:label="item.title"
						@mouseenter="onBtnEnter($event, item.id)" />

					<v-header-link
						v-else
						class="px-20"
						:label="item.title"
						:to="item.url" />
				</template>
			</v-header-nav>

			<v-header-nav aria-label="Website controls">
				<v-header-link
					:aria-label="$t('header.locale')"
					class="px-10 uppercase"
					icon="tepari:country"
					:label="currentSite.code"
					@click="$emitter.emit('countries:open')" />

				<v-header-link
					:aria-label="$t('header.cart')"
					class="cart-link px-10"
					:class="{
						'cart-link--active': !!cart?.lineItems?.length,
					}"
					icon="tepari:cart"
					@click="$emitter.emit('cart:open')" />

				<v-header-link
					:aria-label="$t('header.account')"
					class="px-10"
					icon="tepari:user"
					@click="onAccountClick()" />

				<v-header-link
					:aria-label="$t('header.search')"
					class="hidden px-10 lg:block"
					icon="tepari:search"
					:to="localePath('/search')" />
			</v-header-nav>

			<v-hamburger class="lg:hidden" />
		</div>

		<transition @before-enter="updateMenuState">
			<div v-show="state.isMenuVisible" class="header-menu">
				<div class="header-menu__body">
					<div
						v-for="item of headerNav"
						:id="`header-menu-${item.id}`"
						:key="item.id"
						class="header-menu__content"
						:class="{
							'header-menu__content--active': item.id === state.menuId,
							'header-menu__content--wide': item.type === 'mega-menu',
						}">
						<template v-if="item.type === 'mega-menu'">
							<v-nav-tabs :items="item.children" />

							<div class="bg-brand flex">
								<div class="flex-1/2">
									<nuxt-link
										class="flex flex-col h-full justify-content-center px-20 text-white"
										:to="localePath('/integrated-solutions')">
										<span class="block subtitle opacity-50 pl-20">
											Experience
										</span>

										<div class="flex">
											<icon name="tepari:caret-right" size="20" />

											<span class="block font-medium lh-tight text-md">
												The Te Pari Solution
											</span>
										</div>
									</nuxt-link>
								</div>

								<div class="flex-1/2">
									<a
										v-if="miscellaneous?.handbook?.[0]?.url"
										class="align-items-center flex gap-10 h-full pr-20"
										:href="miscellaneous?.handbook?.[0]?.url"
										style="background-color: #2da7df"
										target="_blank">
										<img
											alt="Handbook"
											height="80"
											src="/images/handbook.jpg"
											width="120" />

										<span
											class="flex-fill font-bold lh-tight text-white text-xs">
											Download The <br />
											Te Pari Handbook
										</span>

										<icon
											class="opacity-50 text-white"
											name="tepari:download"
											size="18" />
									</a>
								</div>
							</div>
						</template>

						<div v-else-if="item.type === 'menu'" class="p-15">
							<v-nav-link
								v-for="(link, i) in item.children"
								:key="link.id"
								:class="{
									'mt-10': i > 0,
								}"
								:highlight="link.highlight"
								:subtitle="link.subtitle"
								:title="link.title"
								:to="link.url" />
						</div>
					</div>
				</div>
			</div>
		</transition>
	</header>
</template>

<script lang="ts" setup>
	const { $emitter } = useNuxtApp()
	const { cart } = storeToRefs(useCommerceStore())
	const { currentSite } = storeToRefs(useSiteStore())
	const { headerNav, miscellaneous } = storeToRefs(useGlobalStore())
	const { session } = storeToRefs(useUserStore())
	const localePath = useLocalePath()
	const router = useRouter()

	const state = ref({
		isMenuVisible: false,
		menuId: '',
		menuLeft: 0,
		menuWidth: 0,
		menuHeight: 0,
		menuOffset: 0,
	})

	const currentPath = computed(() => router.currentRoute.value.fullPath)

	router.beforeEach(() => {
		state.value.isMenuVisible = false
	})

	function onMouseMove(e: MouseEvent) {
		const target = e.target as HTMLElement | null

		if (!target) return

		const isHeaderButton = !!target.closest('.header__link[data-menu-id]')
		const inMenu = !!target.closest('.header-menu')

		state.value.isMenuVisible = isHeaderButton || inMenu
	}

	function onMouseLeave() {
		state.value.isMenuVisible = false
	}

	function onAccountClick() {
		if (!session.value) return

		if (session.value.isGuest) $emitter.emit('login:open')
		else navigateTo(localePath('/account'))
	}

	function onBtnEnter(e: MouseEvent, id: string) {
		const buttonEl = e.target as HTMLElement | null

		if (!buttonEl) return

		const { left, width } = buttonEl.getBoundingClientRect()

		state.value.menuId = id
		state.value.menuLeft = left + width / 2

		updateMenuState()
	}

	function isChildActive(items: Link[] | Menu[]) {
		const getUrls = (items: Link[] | Menu[]) => {
			const urls: string[] = []

			items.forEach((item) => {
				if ('children' in item) {
					urls.push(...getUrls(item.children))
				} else {
					urls.push(item.url)
				}
			})

			return urls
		}

		const urls = getUrls(items)

		return urls.some((url) => {
			const hasParams = ['category', 'categories', 'tag', 'tags'].some(
				(p) => url.includes(p) || currentPath.value.includes(p),
			)

			return hasParams
				? currentPath.value === url
				: currentPath.value.startsWith(url)
		})
	}

	function updateMenuState() {
		const id = state.value.menuId
		const menuEl = document.querySelector<HTMLElement>(`#header-menu-${id}`)

		if (!menuEl) return

		const isWide = menuEl.classList.contains('header-menu__content--wide')

		nextTick(() => {
			state.value.menuWidth = menuEl.offsetWidth + 6
			state.value.menuHeight = menuEl.offsetHeight + 6
			state.value.menuOffset = isWide ? menuEl.offsetWidth / 4 : 0
		})
	}

	provide<ProvidedProps>('header', {
		state,
	})
</script>

<script lang="ts">
	export interface ProvidedProps {
		state: Ref<{
			isMenuVisible: boolean
			menuId: string | null
			menuLeft: number
			menuWidth: number
			menuHeight: number
			menuOffset: number
		}>
	}
</script>

<style lang="scss">
	@layer components {
		.header {
			position: relative;
			background-color: $white;

			&__content {
				display: flex;
				gap: 0 20px;
				border-bottom: 1px solid $gray-300;
				height: var(--header-height);

				@include media-breakpoint-up('lg') {
					gap: 0 40px;
				}
			}
		}

		.header-menu {
			position: absolute;
			top: calc(var(--header-height) - 3px);
			left: 0;
			width: var(--header-menu-width);
			height: var(--header-menu-height);
			z-index: map-get($z-index, 'header');
			transition-duration: 300ms;
			transition-property: height, opacity, transform, width;
			will-change: height, opacity, transform, width;
			filter: drop-shadow(0px 0px 8px rgba($black, 0.2));

			// prettier-ignore
			transform: translateX(
				clamp(
					0px,
					calc((var(--header-menu-left) - var(--header-menu-width) / 2) + var(--header-menu-left-offset)),
					calc(100vw - var(--header-menu-width))
				)
			);

			&.v-enter-active,
			&.v-leave-active {
				transition-property: opacity;
				will-change: opacity;
			}

			&.v-enter-from,
			&.v-leave-to {
				opacity: 0;
				pointer-events: none;

				// Disable transitions for children
				// while the parent is transitioning
				&:before,
				.header-menu__body,
				.header-menu__content,
				.nav-tabs__bg {
					transition-duration: 0ms !important;
				}
			}

			&:before {
				position: absolute;
				top: 0;
				left: calc(50% - var(--header-menu-left-offset));
				transform: translate(-50%, calc(-50% + 3px)) rotate(45deg);
				clip-path: polygon(0 0, 0% 100%, 100% 0);
				transition-duration: 300ms;
				transition-property: left, transform;
				will-change: left, transform;
				border: 3px solid $white;
				border-right: none;
				border-bottom: none;
				border-top-left-radius: 4px;
				background-color: $gray-100;
				width: 20px;
				height: 20px;
				pointer-events: none;
				content: '';
			}

			&__body {
				display: grid;
				align-items: start;
				transition: all 300ms ease;
				border: 3px solid $white;
				border-radius: 8px;
				background-color: $gray-100;
				width: 100%;
				max-width: 100%;
				height: 100%;
				overflow: hidden;

				> .header-menu__content {
					grid-row: 1;
					grid-column: 1;
					translate: -150px;
				}

				> .header-menu__content--active {
					translate: 0;

					~ .header-menu__content {
						translate: 150px;
					}
				}
			}

			&__content {
				opacity: 0;
				pointer-events: none;
				visibility: hidden;
				transition: all 300ms ease;
				padding: 0;
				width: var(--header-menu-size, 320px);
				max-width: 100vw;
				overflow: hidden;

				&--wide {
					--header-menu-size: 700px;
				}

				&--active {
					opacity: 1;
					pointer-events: auto;
					visibility: visible;
				}
			}
		}

		.cart-link {
			position: relative;
			transition: color 300ms ease;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 35%;
				right: 6px;
				width: 6px;
				height: 6px;
				border-radius: 3px;
				opacity: 0;
				transition:
					scale 300ms ease,
					opacity 300ms ease;
				transform: scale(0);
				background-color: $brand;
			}

			&.cart-link--active {
				color: $brand;

				&:after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}
</style>
