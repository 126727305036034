<template>
	<v-input-label
		class="input-wrapper"
		:empty="!internalValue"
		:focused="isFocused"
		:hidden="labelHidden"
		:position="labelPosition ?? 'float'"
		:required="required"
		:value="label">
		<input
			v-model="internalValue"
			ref="inputEl"
			:autocomplete="autocomplete"
			class="input"
			:disabled="disabled"
			:name="name"
			:placeholder="placeholder"
			:readonly="readonly"
			:required="required"
			:type="type ?? 'text'"
			v-bind="attributes"
			@blur="onBlur"
			@focus="onFocus"
			@input="onInput" />
	</v-input-label>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		attributes?: Record<string, unknown>
		autocomplete?: 'on' | 'off'
		disabled?: boolean
		label: string
		labelHidden?: boolean
		labelPosition?: 'top' | 'right' | 'bottom' | 'left' | 'float'
		modelValue?: string | number
		name?: string
		placeholder?: string
		required?: boolean
		readonly?: boolean
		type?: 'text' | 'number' | 'email' | 'tel' | 'password'
	}>()

	const emit = defineEmits<{
		'blur': [Event]
		'clear': []
		'focus': [Event]
		'input': [Event]
		'update:modelValue': [string | number | undefined]
	}>()

	const inputEl = ref<HTMLInputElement>()
	const internalValue = ref(props.modelValue ?? '')
	const isFocused = ref(false)
	const modelValue = toRef(props, 'modelValue')

	watch(modelValue, (value) => (internalValue.value = value ?? ''), {
		immediate: true,
	})

	function onBlur(event: Event) {
		if (props.disabled || props.readonly) return

		isFocused.value = false
		emit('blur', event)
	}

	function onClear() {
		internalValue.value = ''
		emit('clear')
		emit('update:modelValue', internalValue.value)
	}

	function onFocus(event: Event) {
		if (props.disabled || props.readonly) return

		isFocused.value = true
		emit('focus', event)
	}

	function onInput(event: Event) {
		internalValue.value = (event.target as HTMLInputElement).value
		emit('input', event)
		emit('update:modelValue', internalValue.value)
	}

	defineExpose({
		blur: onBlur,
		clear: onClear,
		focus: onFocus,
		inputEl,
		value: internalValue,
	})
</script>

<style lang="scss">
	@layer components {
		.input {
			height: 36px;
			@include input();
		}
	}
</style>
