<template>
	<v-dialog
		id="mobile-menu"
		ref="dialogEl"
		class="mobile-menu"
		:class="{
			'mobile-menu--active': activeMenuId,
		}"
		type="drawer">
		<template #header>
			<div class="mobile-title pr-60 pl-20 py-10">
				<transition>
					<button
						v-if="activeMenu"
						class="mobile-title__btn"
						type="button"
						@click="activeMenuId = null">
						<icon name="tepari:arrow-left" size="24" />
						{{ activeMenu.title }}
					</button>

					<div v-else class="mobile-title__logo">
						<icon name="tepari:logo-wide" />
					</div>
				</transition>
			</div>
		</template>

		<template #body>
			<transition>
				<nav v-show="!activeMenuId" class="mobile-nav mobile-nav--primary">
					<div class="px-20 py-20 space-y-40">
						<template v-for="item in headerNav" :key="item.id">
							<a
								v-if="item.type === 'mega-menu' || item.type === 'menu'"
								class="align-items-center flex justify-content-between"
								href="#"
								role="button"
								@click.prevent="activeMenuId = item.id">
								<div>
									<span class="block font-medium text-brand">
										{{ item.title }}
									</span>

									<span
										v-if="item.subtitle"
										class="block text-gray-500 text-xs">
										{{ item.subtitle }}
									</span>
								</div>

								<icon
									class="text-brand"
									name="tepari:chevron-right"
									size="20" />
							</a>

							<nuxt-link v-else class="block" :to="item.url">
								<span class="block font-medium text-brand">
									{{ item.title }}
								</span>

								<span v-if="item.subtitle" class="block text-gray-500 text-xs">
									{{ item.subtitle }}
								</span>
							</nuxt-link>
						</template>
					</div>
				</nav>
			</transition>

			<transition v-for="menu in menus" :key="menu.id">
				<nav
					v-show="menu.id === activeMenuId"
					class="mobile-nav mobile-nav--secondary">
					<template v-if="menu.type === 'mega-menu'">
						<div class="flex flex-col h-full">
							<div class="flex-fill px-10 py-20 space-y-20">
								<v-collapse
									v-for="item in menu.children"
									:key="item.id"
									class="mobile-mega-menu border-0 rounded-10">
									<v-collapse-trigger class="flex gap-10 p-10">
										<figure
											v-if="item.icon?.inline"
											v-html="item.icon.inline"
											class="flex-auto h-30 text-brand w-30" />

										<p class="flex-fill">
											<span class="block font-medium text-brand">
												{{ item.title }}
											</span>

											<span
												v-if="item.subtitle"
												class="block text-gray-500 text-xs">
												{{ item.subtitle }}
											</span>
										</p>

										<icon
											class="flex-auto text-brand"
											name="tepari:chevron-down"
											size="20" />
									</v-collapse-trigger>

									<v-collapse-content class="pr-10 pl-50 py-10 space-y-10">
										<nuxt-link
											v-for="link in item.children"
											:key="link.id"
											class="align-items-center bg-gray-100 rounded-5 block flex p-10"
											:to="link.url">
											<div class="flex-full">
												<span class="block font-medium text-brand text-sm">
													{{ link.title }}
												</span>

												<span
													v-if="link.subtitle"
													class="block text-gray-500 text-xs">
													{{ link.subtitle }}
												</span>
											</div>

											<icon
												class="flex-auto text-brand"
												name="tepari:arrow-right"
												size="20" />
										</nuxt-link>
									</v-collapse-content>
								</v-collapse>
							</div>

							<div class="bg-brand px-20 py-20 space-y-20">
								<nuxt-link
									class="block text-white"
									:to="localePath('/integrated-solutions')">
									<span class="block subtitle opacity-50 pl-20">
										Experience
									</span>

									<div class="flex">
										<icon name="tepari:caret-right" size="20" />

										<span class="block font-medium lh-tight text-md">
											The Te Pari solution
										</span>
									</div>
								</nuxt-link>

								<a
									v-if="miscellaneous?.handbook?.[0]?.url"
									class="align-items-center rounded-10 block flex gap-10 overflow-hidden pr-20"
									:href="miscellaneous?.handbook?.[0]?.url"
									style="background-color: #2da7df"
									target="_blank">
									<img
										alt="Handbook"
										height="80"
										src="/images/handbook.jpg"
										width="120" />

									<span class="flex-fill font-bold lh-tight text-white text-xs">
										Download the <br />
										Te Pari Handbook
									</span>

									<icon
										class="opacity-50 text-white"
										name="tepari:download"
										size="18" />
								</a>
							</div>
						</div>
					</template>

					<template v-if="menu.type === 'menu'">
						<div class="px-20 py-20 space-y-40">
							<nuxt-link
								v-for="item in menu.children"
								:key="item.id"
								class="block"
								:to="item.url">
								<span class="block font-medium text-brand">
									{{ item.title }}
								</span>

								<span v-if="item.subtitle" class="block text-gray-500 text-xs">
									{{ item.subtitle }}
								</span>
							</nuxt-link>
						</div>
					</template>
				</nav>
			</transition>
		</template>
	</v-dialog>
</template>

<script lang="ts" setup>
	import type Dialog from '~/components/common/dialog/dialog.vue'

	const { $emitter } = useNuxtApp()

	const { miscellaneous, headerNav } = storeToRefs(useGlobalStore())

	const localePath = useLocalePath()

	const router = useRouter()

	const activeMenuId = ref<string | null>(null)

	const dialogEl = ref<InstanceType<typeof Dialog>>()

	const menus = computed(() => {
		const types = ['mega-menu', 'menu']

		if (!headerNav.value?.length) return []

		return headerNav.value.filter(({ type }) => types.includes(type)) as Array<
			MegaMenu | Menu
		>
	})

	const activeMenu = computed(
		() => menus.value.find(({ id }) => id === activeMenuId.value) ?? null,
	)

	router.beforeEach(() => {
		if (!dialogEl.value?.isActive) return

		dialogEl.value?.close()
		activeMenuId.value = null
	})

	$emitter.on('mobile-menu:open', () => dialogEl.value?.open())

	$emitter.on('mobile-menu:close', () => dialogEl.value?.close())
</script>

<style lang="scss">
	@layer components {
		.mobile-menu {
			padding-left: 0;

			&--active {
				.dialog__content {
					background-color: $gray-100;
				}
			}

			.dialog__close {
				top: 35px;
				right: 15px;
				transform: translateY(-50%);
				left: auto;
			}

			.dialog__content {
				transition: background-color 300ms ease;
				will-change: background-color;
			}

			.dialog__header {
				display: flex;
				align-items: center;
				padding: 0;
				overflow: hidden;
				background-color: $white;
			}

			.dialog__body {
				display: grid;
				grid-template-rows: 100%;
				grid-template-columns: 100%;
				overflow: hidden;
				padding: 0;

				> * {
					grid-row: 1;
					grid-column: 1;
				}
			}
		}

		.mobile-title {
			display: grid;
			align-items: center;
			width: 100%;

			> * {
				grid-row: 1;
				grid-column: 1;
			}

			&__btn,
			&__logo {
				transition: 300ms ease;
				transition-property: opacity, transform;
				will-change: opacity, transform;
			}

			&__btn {
				display: flex;
				align-items: center;
				gap: 10px;
				color: $brand;
				font-size: 2rem;

				&.v-leave-to,
				&.v-enter-from {
					transform: translateX(100px);
					opacity: 0;
				}
			}

			&__logo {
				color: $brand;

				> svg {
					display: block;
					width: auto;
					height: 50px;
				}

				&.v-leave-to,
				&.v-enter-from {
					transform: translateX(-100px);
					opacity: 0;
				}
			}
		}

		.mobile-nav {
			transition: 300ms ease;
			transition-property: opacity, transform;
			will-change: opacity, transform;

			&--primary {
				&.v-leave-to,
				&.v-enter-from {
					transform: translateX(-100px);
					opacity: 0;
				}
			}

			&--secondary {
				&.v-leave-to,
				&.v-enter-from {
					transform: translateX(100px);
					opacity: 0;
				}
			}
		}

		.mobile-mega-menu {
			transition: background-color 300ms ease;

			&.collapse--active {
				background-color: $white;

				.collapse-trigger > svg {
					transform: rotate(-180deg);
				}
			}

			.collapse-trigger > svg {
				transition: 300ms ease;
				transition-property: transform;
				will-change: transform;
			}
		}
	}
</style>
