<template>
	<div
		ref="popoverEl"
		class="popover"
		:class="{
			'popover--animating': isAnimating,
			'popover--hidden': !isVisible,
			'popover--visible': isVisible,
		}"
		:style="styles">
		<transition
			@after-enter="onAfterEnter"
			@after-leave="onAfterLeave"
			@before-enter="onBeforeEnter"
			@before-leave="onBeforeLeave">
			<slot v-bind="{ isVisible }" />
		</transition>
	</div>
</template>

<script lang="ts" setup>
	import type { OffsetOptions, Placement, Strategy } from '@floating-ui/vue'

	const props = defineProps<{
		boundary?: HTMLElement
		offset?: OffsetOptions
		placement?: Placement
		strategy?: Strategy
	}>()

	const emit = defineEmits<{
		'popover:after-enter': []
		'popover:after-leave': []
		'popover:before-enter': []
		'popover:before-leave': []
		'popover:click-inside': [MouseEvent]
		'popover:click-outside': [MouseEvent]
	}>()

	const {
		$floatingAutoUpdate,
		$floatingBoundary,
		$floatingFlip,
		$floatingOffset,
		$floatingShift,
		$useFloating,
	} = useNuxtApp()

	const popoverEl = ref<HTMLElement>()
	const targetEl = ref<HTMLElement>()
	const isVisible = ref(false)
	const isAnimating = ref(false)
	const body = ref(document?.body)

	const { floatingStyles: styles } = $useFloating(targetEl, popoverEl, {
		middleware: [
			$floatingOffset(props.offset),
			$floatingBoundary(props.boundary),
			$floatingFlip(),
			$floatingShift(),
		],
		placement: props.placement,
		strategy: props.strategy,
		whileElementsMounted(...args) {
			return $floatingAutoUpdate(...args, {
				animationFrame: true,
			})
		},
	})

	useEventListener(body, 'click', onClick)

	function onAfterEnter() {
		emit('popover:after-enter')
		isAnimating.value = false
	}

	function onAfterLeave() {
		emit('popover:after-leave')
		isAnimating.value = false

		if (popoverEl.value) {
			popoverEl.value.style.display = 'none'
		}
	}

	function onBeforeEnter() {
		emit('popover:before-enter')
		isAnimating.value = true

		if (popoverEl.value) {
			popoverEl.value.style.display = 'block'
		}
	}

	function onBeforeLeave() {
		emit('popover:before-leave')
		isAnimating.value = true
	}

	function onClick(e: MouseEvent) {
		if (!isVisible.value) return

		const popover = popoverEl.value
		const trigger = targetEl.value
		const target = e.target as HTMLElement | null

		if (!popover || !trigger || !target) return

		if (trigger.contains(target) || popover.contains(target)) {
			emit('popover:click-inside', e)
		} else {
			emit('popover:click-outside', e)
			hide()
		}
	}

	function hide() {
		if (!isVisible.value) return

		targetEl.value = undefined
		isVisible.value = false
	}

	function show(e: Event | Element) {
		if (isVisible.value) return

		const target = e instanceof Event ? e.target : e

		if (!(target instanceof HTMLElement)) return

		targetEl.value = target
		isVisible.value = true
	}

	function toggle(e: Event | Element) {
		isVisible.value ? hide() : show(e)
	}

	defineExpose({
		hide,
		isVisible,
		show,
		toggle,
	})
</script>

<style lang="scss">
	@layer components {
		.popover {
			position: absolute;
			top: 0;
			left: 0;
			width: fit-content;
			max-width: 100%;
			z-index: map-get($z-index, 'popover');
			outline: none;
			border: none;
			background-color: transparent;
			padding: 0;
		}
	}
</style>
