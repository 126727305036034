<template>
	<div v-show="isActive">
		<slot />
	</div>
</template>

<script lang="ts" setup>
	import type { ProvidedProps } from './tabs.vue'

	const props = defineProps<{
		handle: string | number
	}>()

	const tabProps = inject<ProvidedProps>('tabs')

	if (!tabProps) {
		throw new Error('Tab component must be used inside a Tabs component')
	}

	const isActive = computed(
		() => tabProps && tabProps.activeHandle.value === props.handle,
	)
</script>

<style lang="scss"></style>
