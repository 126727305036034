<template>
	<v-dialog ref="dialogEl" class="country-dialog" type="modal">
		<template #header>
			<p class="h3">Choose Locale</p>
		</template>

		<div class="flex flex-col gap-10">
			<template v-for="(option, i) in options" :key="i">
				<a
					v-if="currentSite.code !== option.code"
					class="country-option"
					:href="`/${option.code}`">
					<icon :name="option.icon" size="40" />
					<span>{{ option.name }}</span>
				</a>
			</template>
		</div>
	</v-dialog>
</template>

<script lang="ts" setup>
	import type Dialog from './dialog/dialog.vue'

	const { $emitter } = useNuxtApp()

	const { currentSite } = storeToRefs(useSiteStore())

	const dialogEl = ref<InstanceType<typeof Dialog>>()

	const options = [
		{
			code: 'nz',
			icon: 'tepari:flag-nz',
			name: 'New Zealand',
		},
		{
			code: 'au',
			icon: 'tepari:flag-au',
			name: 'Australia',
		},
		{
			code: 'uk',
			icon: 'tepari:flag-uk',
			name: 'United Kingdom',
		},
		{
			code: 'us',
			icon: 'tepari:flag-us',
			name: 'United States',
		},
	]

	$emitter.on('countries:open', () => dialogEl.value?.open())

	$emitter.on('countries:close', () => dialogEl.value?.close())
</script>

<style lang="scss" scoped>
	.country-dialog {
		--dialog-width: 300px;
		--dialog-header-px: 20px;
		--dialog-header-py: 20px;
		--dialog-body-px: 20px;
		--dialog-body-py: 20px;
	}

	.country-option {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
		font-weight: 700;
		padding: 10px;
		transition: background-color 0.3s;
		border-radius: 10px;

		&:hover {
			background-color: $gray-100;
		}

		> svg {
			flex: 0 0 40px;
		}
	}
</style>
