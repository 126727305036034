<template>
	<div class="bg-gray-100 border border-gray-300 rounded-5 flex gap-10 p-10">
		<v-thumbnail
			is="a"
			class="border border-gray-300 rounded-5 overflow-hidden w-100"
			href="#"
			ratio="1:1"
			role="button"
			@click.prevent="goToProduct()">
			<v-craft-image
				v-if="img"
				class="h-full w-full"
				ratio="none"
				:value="img" />
		</v-thumbnail>

		<div class="flex flex-fill flex-col gap-10 justify-content-between">
			<div class="flex gap-20">
				<nuxt-link
					class="block flex-fill"
					:to="localePath(`/${item.snapshot.product.uri}`)"
					@click.prevent="goToProduct()">
					<p class="font-bold text-brand">
						{{ item.snapshot.product.title }}
					</p>

					<p
						v-if="item.snapshot.title !== item.snapshot.product.title"
						class="font-black ls-widest text-gray-500 text-2xs uppercase">
						{{ item.snapshot.title }}
					</p>
				</nuxt-link>

				<div v-if="item.price" class="flex-auto">
					<p class="font-bold">
						{{ formatCurrency(item.price) }}
					</p>
				</div>
			</div>

			<div class="flex gap-20 justify-content-between">
				<div class="flex-auto">
					<v-quantity
						v-model="qty"
						class="qty w-100"
						:disabled="loading"
						@update:model-value="onQtyChange($event)" />
				</div>

				<div class="flex-auto">
					<v-button
						color="white"
						:disabled="loading"
						fill="solid"
						icon="tepari:trash"
						shape="round"
						size="sm"
						type="button"
						@click="onRemoveFromCart()" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type { LineItem } from '@/types/line-item'
	import { formatCurrency } from '@/utilities/format-currency'

	const props = defineProps<{
		item: LineItem
	}>()

	const { $emitter } = useNuxtApp()
	const { updateItems } = useCommerceStore()
	const localePath = useLocalePath()

	const { data } = await useFetch(
		`/api/shop/line-item/${props.item.snapshot.product.id}`,
	)

	const loading = ref(false)

	const qty = ref(props.item?.qty || 1)

	const img = computed(() => {
		const product = data.value?.product

		const variant = product?.variants?.find(
			(v) => Number(v.id) === Number(props.item.snapshot.id),
		)

		return variant?.images?.[0] ?? product?.images?.[0] ?? null
	})

	async function onQtyChange(qty: number) {
		loading.value = true

		const items: UpdateCartPayload['lineItems'] = {}

		items[props.item.id] = {
			qty,
		}

		await updateItems(items)

		loading.value = false
	}

	async function onRemoveFromCart() {
		loading.value = true

		const item: any = {}

		item[props.item.id] = {
			qty: 0,
			remove: 1,
		}

		await updateItems(item)

		loading.value = false
	}

	function goToProduct() {
		$emitter.emit('cart:close')
		navigateTo(localePath(`/${props.item.snapshot.product.uri}`))
	}
</script>

<style lang="scss" scoped>
	.qty {
		--qty-height: 28px;
	}
</style>
