<template>
	<component
		:is="to ? nuxtLink : 'button'"
		class="nav-link"
		:class="{
			'nav-link--active': active,
			'nav-link--highlight': highlight,
		}"
		v-bind="{
			to: to,
			type: !to ? 'button' : undefined,
		}">
		<span v-if="title" class="nav-link__title">
			{{ title }}
		</span>

		<span v-if="subtitle" class="nav-link__subtitle">
			{{ subtitle }}
		</span>

		<icon v-if="iconName" class="nav-link__icon" :name="iconName" />
	</component>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		to?: string
		title?: string
		subtitle?: string
		iconName?: string
		highlight?: boolean
	}>()

	const nuxtLink = resolveComponent('NuxtLink')

	const router = useRouter()

	const to = toRef(props, 'to')

	const currentPath = computed(() => router.currentRoute.value.fullPath)

	const active = computed(() => {
		if (!to.value || !currentPath.value) return false

		const hasParams = ['category', 'categories', 'tag', 'tags'].some(
			(p) => to.value!.includes(p) || currentPath.value!.includes(p),
		)

		return hasParams
			? currentPath.value === to.value
			: currentPath.value.startsWith(to.value)
	})
</script>

<style lang="scss">
	@layer components {
		.nav-link {
			--nav-link-color: #{$gray-700};
			--nav-link-color-hover: #{$gray-700};
			--nav-link-color-active: #{$gray-700};

			--nav-link-bg-color: transparent;
			--nav-link-bg-color-hover: #{rgba($white, 0.75)};
			--nav-link-bg-color-active: #{rgba($white, 0.75)};

			--nav-link-highlight-bg-color: #{rgba($brand, 0.1)};
			--nav-link-highlight-bg-color-hover: #{rgba($brand, 0.2)};
			--nav-link-highlight-bg-color-active: #{rgba($white, 0.75)};

			padding: 15px;
			display: grid;
			position: relative;
			grid-template-columns: minmax(0, 1fr) auto;
			grid-template-areas:
				'title icon'
				'subtitle icon';
			align-items: center;
			gap: 0 5px;
			transition: 300ms ease;
			transition-property: color, background-color;
			will-change: color, background-color;
			border-radius: var(--nav-link-border-radius, #{5px});
			background-color: var(--nav-link-bg-color);
			width: 100%;
			color: var(--nav-link-color);

			&.nav-link--active {
				background-color: var(--nav-link-bg-color-active);
				color: var(--nav-link-color-active);
			}

			&:not(.nav-link--active) {
				&:hover,
				&:focus-visible {
					background-color: var(--nav-link-bg-color-hover,);
					color: var(--nav-link-color-hover);
				}
			}

			&.nav-link--highlight {
				background-color: var(--nav-link-highlight-bg-color);
				color: var(--nav-highlight-link-color);

				&:hover,
				&:focus-visible {
					background-color: var(--nav-link-highlight-bg-color-hover,);
					color: var(--nav-link-highlight-color-hover);
				}

				&.nav-link--active {
					background-color: var(--nav-link-highlight-bg-color-active);
					color: var(--nav-link-highlight-color-active);
				}
			}

			&__title {
				display: block;
				grid-area: title;
				color: var(--nav-link-title-color, #{$brand});
				font-style: var(--nav-link-title-style, normal);
				font-weight: 500;
				font-size: var(--nav-link-title-size, #{$text-sm});
				line-height: 2rem;
			}

			&__subtitle {
				display: block;
				grid-area: subtitle;
				color: var(--nav-link-subtitle-color, $gray-700);
				font-size: var(--nav-link-subtitle-size, #{$text-2xs});
			}

			&__icon {
				grid-area: icon;
				transform: translateX(var(--nav-link-icon-offset, -5px));
				opacity: var(--nav-link-icon-opacity, 0);
				transition: 300ms ease;
				transition-property: color, opacity, transform;
				will-change: color, opacity, transform;
				width: 16px;
				height: 16px;
				color: var(--nav-link-icon-color, #{$brand});
			}
		}
	}
</style>
